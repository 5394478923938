import React from "react";
import { CAvatar } from "@coreui/react";
import { ChatMessage } from "./ChatMessage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import aiImage from "../assets/images/OIG.png";
import FlightCard from "./FlightCard";
import WeatherCard from "./WeatherComponents/WeatherCard";
import TravelPlacesCard from "./PlacesCard";
import NewsCard from "./NewsCard";
const ItineraryChat = ({ data, index }) => {
  return (
    <div>
      <ChatMessage
        key={index}
        text={data.question}
        isUser={true}
        isError={false}
      />
      {data.answer == "" ? (
        <div
          style={{ marginLeft: "10px" }}
          className="d-flex justify-content-start position-relative gap-2 align-items-start"
        >
          <CAvatar
            style={{
              width: "35px",
              height: "35px",
            }}
            className="font-md mt-2 border avatar"
            size="sm"
            src={aiImage}
          >
            {}
          </CAvatar>
          <div
            className={`chat-message other mt-2 align-items-center `}
            style={{ width: "75%" }}
          >
            <SkeletonTheme baseColor="#d9edf9" highlightColor="#2F8FC7">
              <h4 style={{ width: "100%" }}>
                <Skeleton count={1} width={"50%"} />
              </h4>
              <p style={{ width: "100%" }}>
                <Skeleton height={12} width={"75%"} count={2} />
              </p>
            </SkeletonTheme>
          </div>
        </div>
      ) : data.answer.chat ? (
        <ChatMessage
          key={index}
          isUser={false}
          isError={false}
          text={data.answer.chat}
        />
      ) : data.answer.flight ? (
        <>
          <div
            style={{ marginLeft: "10px" }}
            className="d-flex justify-content-start position-relative gap-2 align-items-start"
          >
            <CAvatar
              style={{
                width: "35px",
                height: "35px",
              }}
              className="font-md mt-2 border avatar"
              size="sm"
              src={aiImage}
            >
              {}
            </CAvatar>
            <div
              className={`chat-message other mt-2 align-items-center d-flex gap-2 font-md p-2`}
              style={{
                marginLeft: "0px",
                maxWidth: "85%",
                backdropFilter: "blur(10.5px)",
                background: "white",
                border: "none",
              }}
            >
              <div>
                <FlightCard data={data.answer.flight} date={data.answer.date} />
                <WeatherCard city={data.answer.flight.arrival_City} />
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: "10px" }}
            className="d-flex justify-content-start position-relative gap-2 align-items-start"
          >
            <CAvatar
              style={{
                display: "block",
                boxShadow: "none",
                marginRight: "10px",
              }}
              size="sm"
            ></CAvatar>
            <div
              className={`chat-message other mt-2 align-items-center d-flex gap-2 font-md text-white`}
              style={{
                marginLeft: "0px",
                maxWidth: "85%",
                backdropFilter: "blur(10.5px)",
                background:
                  "linear-gradient(135deg, rgba(247,126,52,1) 0%,rgba(218,124,226,1) 100%)",
                border: "none",
              }}
            >
              <div>{data.answer.ques}</div>
            </div>
          </div>
        </>
      ) : data.answer.places ? (
        <div>
          {data.answer.places.length > 0 ? (
            <ChatMessage
              key={index}
              isUser={false}
              isError={false}
              text={
                <div>
                  <b className="mb-2">Places</b>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        data.answer.places.length > 1
                          ? "repeat(2,1fr)"
                          : "repeat(1,1fr)",
                      gap: "10px",
                    }}
                    className="mt-1"
                  >
                    {data.answer.places.map((place, index) => (
                      <TravelPlacesCard
                        key={index}
                        placeName={place.title}
                        placeImage={place.thumbnailUrl}
                        placeDescription={place.address}
                        placeInfo={""}
                      />
                    ))}
                  </div>
                </div>
              }
            />
          ) : null}{" "}
          {data.answer.news.length > 0 ? (
            <ChatMessage
              key={index}
              isUser={false}
              isError={false}
              subChat={data.answer.places.length == 0 ? false : true}
              text={
                <div>
                  <b>News</b>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        data.answer.news.length > 1
                          ? "repeat(2,1fr)"
                          : "repeat(1,1fr)",
                      gap: "10px",
                    }}
                    className="mt-1"
                  >
                    {data.answer.news.map((news, ind) => {
                      return <NewsCard data={news} />;
                    })}
                  </div>
                </div>
              }
            />
          ) : null}
        </div>
      ) : data.answer == "no answer found" ? (
        <ChatMessage
          key={index}
          text={
            "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
          }
          isUser={false}
          isError={true}
        />
      ) : null}
    </div>
  );
};

export default ItineraryChat;
