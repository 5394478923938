import { CSidebar, CButton, CTooltip, CCol } from "@coreui/react";
import React from "react";
import { PiChatsLight } from "react-icons/pi";
import { FaFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
} from "@coreui/icons";

const RPGSidebar = ({
  generatedData,
  setGeneratedData,
  visible,
  setVisible,
  setInputVal,
}) => {
  const navigate = useNavigate();

  return (
    <CSidebar
      className="guide-sidebar"
      visible={visible}
      position="fixed"
      placement={"start"}
    >
      <div style={{ height: "50px" }}></div>
      <div className="d-flex justify-content-between " style={{ top: "10px" }}>
        <CButton
          className="sticky logout-button mb-2 font-md"
          variant="outline"
          shape="rounded-pill"
          style={{ width: "80%" }}
          disabled={generatedData.length < 1}
          onClick={() => {
            setGeneratedData([]);
            setInputVal("");
          }}
        >
          <PiChatsLight size={"1.5em"} /> Clear Chat {generatedData.length}/20
        </CButton>
        <CButton
          color="info"
          className="p-0 mb-2"
          style={{
            width: "35px",
            background: "coral",
            border: "1px solid coral",
            color: "white",
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-light" />
        </CButton>
      </div>
      <CCol style={{ height: "60vh", overflow: "scroll" }}>
        {generatedData?.map((el, i) => {
          return (
            <CTooltip className="font-sm" content={el.question}>
              <CCol
                onClick={() => {
                  // console.log(el);
                  // setCurrent(el);
                  // setImgData(el.img);
                  // setStrucData(el.answer);
                  // setUnStrucData(el.result);
                  // setInputVal(el.question);
                }}
                key={i}
                style={{
                  color: "#0B57D0",
                }}
                className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 zoom hover"
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <FaFilePdf
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      color: "#ff2c21",
                    }}
                    size={"1.3em"}
                  />
                  {el.question}
                </div>
              </CCol>
            </CTooltip>
          );
        })}
      </CCol>
    </CSidebar>
  );
};

export default RPGSidebar;
