import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
  cilPlus,
} from "@coreui/icons";
import {
  AiFillCaretRight,
  AiOutlineCaretDown,
  AiOutlineDelete,
} from "react-icons/ai";
import { CHeaderDivider, CTooltip } from "@coreui/react";

const QuestionCard = ({ el, index, deleteVisible, handleDeleteChat }) => {
  const [vis, setVis] = useState(false);

  return (
    <div className="w-100">
      <div className="d-flex gap-1 align-items-center">
        {vis ? (
          <AiOutlineCaretDown
            size={"1.2em"}
            onClick={() => {
              setVis(false);
            }}
          />
        ) : (
          <AiFillCaretRight
            size={"1.2em"}
            onClick={() => {
              setVis(true);
            }}
          />
        )}
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "90%",
            textAlign: "left",
          }}
        >
          {el[0].question}
        </div>
        {deleteVisible === index ? (
                  <AiOutlineDelete
                    size={"1.2em"}
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDeleteChat(el);
                    }}
                  />
                ) : null}
      </div>
      <div style={{ display: vis ? "block" : "none" }}>
        <CHeaderDivider
          className="w-100  mt-1"
          style={{ border: "1px solid rgba(186, 211, 255,0.8)" }}
        />
        {el.map((q) => {
          return (
            <CTooltip content={q.question} className="font-sm">
              <div
                className="d-flex gap-1 pt-1"
                style={{ paddingLeft: "10px" }}
              >
                <CIcon icon={cilCommentSquare} size="sm" width={12} />
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "90%",
                    textAlign: "left",
                  }}
                >
                  {q.question}
                </div>

              </div>
            </CTooltip>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionCard;
