import {
  CAlert,
  CCard,
  CCardText,
  CCloseButton,
  CCol,
  CCollapse,
  CImage,
  CLink,
  CModal,
  CSpinner,
} from "@coreui/react";
import axios from "axios";
import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import CIcon from "@coreui/icons-react";
import { cilChevronBottom } from "@coreui/icons";
const PDFCard = (props) => {
  const [imagesArr, setImagesArr] = useState();
  const [loading, setLoading] = useState(false);
  const [vis, setVis] = useState(false);
  const [viewVis, setViewVis] = useState(false);
  const [viewPort, setViewPort] = useState();
  const text = props.title.replace(/[^\u000A\u0020-\u007E]/g, " ");
  const getImages = async (img_search_data) => {
    setLoading(true);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/maruti-get-images",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        img_search_data: img_search_data,
      },
    };

    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("Image res", response);
        setImagesArr(response.data.img_result_data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <CCol
      style={{
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
      className="border gap-2 p-2 rounded bg-white m-1 position-relative"
    >
      <div className="d-flex align-items-center gap-1">
        <BsFillFileEarmarkPdfFill style={{ color: "#EA4436", width: "20px" }} />
        <CLink
          href={props.signed_url + `#page=${props.pg}`}
          target="_blank"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="font-md"
        >
          {props.url.split("/")[props.url.split("/").length - 1]}
        </CLink>
      </div>
      <div className="font-sm text-medium-emphasis my-1 hover ">{text}</div>
      <div
        className="text-success position-absolute"
        style={{ bottom: 2, right: 10 }}
      >
        Page-{props.pg}
      </div>
      {vis ? (
        <CCardText
          className="p-0 m-0 hover"
          style={{ color: "#eb8f17" }}
          onClick={() => {
            setVis(false);
          }}
        >
          Hide Related Images
        </CCardText>
      ) : (
        <CCardText
          className="p-0 m-0 hover"
          style={{ color: "#eb8f17" }}
          onClick={() => {
            getImages([
              {
                File_Name:
                  props.url.split("/")[props.url.split("/").length - 1],
                Page_Number: props.pg,
              },
            ]);

            setVis(true);
          }}
        >
          Show Related Images
        </CCardText>
      )}
      <div className="d-flex w-100 position-relative justify-content-between">
        <CCollapse
          className={`d-flex m-0 gap-2 ${viewVis ? "w-50" : "w100"}`}
          visible={vis}
          style={{ flexWrap: "wrap" }}
        >
          {loading ? (
            <div
              style={{ height: "100px", width: "150px" }}
              className="d-flex justify-content-center align-items-center border rounded-2"
            >
              <CSpinner size="sm" />
            </div>
          ) : imagesArr?.length > 0 ? (
            <>
              {imagesArr?.map((pd) => {
                return (
                  <CImage
                    onClick={() => {
                      setViewPort(`data:image/png;base64,${pd}`);
                      setViewVis(true);
                    }}
                    style={{ height: "100px", width: "150px" }}
                    src={`data:image/png;base64,${pd}`}
                    className="border rounded-2 my-1"
                  />
                );
              })}
            </>
          ) : imagesArr?.length == 0 ? (
            <>
              {" "}
              <CAlert
                visible={vis && imagesArr?.length == 0}
                color="light"
                style={{ width: "max-content" }}
                className="p-2 m-0"
              >
                No Images Available{" "}
              </CAlert>
            </>
          ) : null}
        </CCollapse>
        <div
          style={{
            position: "sticky",
            top: "0px",
            right: "0px",
            marginLeft: "20px",
            padding: "10px",
            paddingTop: "40px",
            maxWidth: "420px",
            marginBottom: "10px",
          }}
          className={`${viewVis ? "rounded-3 border" : "d-none"}`}
        >
          {viewVis ? (
            <CCloseButton
              onClick={() => {
                setViewVis(false);
                setViewPort();
              }}
              style={{ position: "absolute", right: 10, top: 10 }}
            />
          ) : null}
          <CImage
            className={`${viewVis ? "resize opened" : "resize"}`}
            src={viewPort}
          />
        </div>
      </div>
    </CCol>
  );
};

export default PDFCard;
