import React, { useEffect, useState } from "react";
import {
  CNav,
  CNavItem,
  CTabContent,
  CTabPane,
  CNavLink,
  CCol,
  CTooltip,
  CBadge,
} from "@coreui/react";
import Citations from "./Citations";
import { BsBook, BsGraphUp } from "react-icons/bs";
import ChartTypesJSW from "./ChartTypesJSW";
import { CButton } from "@coreui/react";

import { ReactComponent as ReplyIcon } from "../assets/images/chatreply.svg";

import {
  FaThumbsUp,
  FaRegThumbsUp,
  FaThumbsDown,
  FaRegThumbsDown,
} from "react-icons/fa";
// import ReplyChatJSW from "./ReplyChatJSW";
const PDTabs = (props) => {
  const [activeKey, setActiveKey] = useState(1);
  const [followVis, setFollowVis] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  useEffect(() => {
    if (props.el.summary?.includes("Answer Not Found")) {
      setActiveKey(1);
    }
  }, []);
  const convertToBold = (text) => {
    if (text.includes("**")) {
      const parts = text.split("**");
      return parts.map((part, index) => {
        return index % 2 === 0 ? (
          <span key={index}>{part}</span>
        ) : (
          <span key={index} style={{ fontWeight: "500" }}>
            {part}
          </span>
        );
      });
    } else {
      return text;
    }
  };

  return (
    <>
      {/* <CNav
        variant="underline"
        className="mb-2 font-md p-1 rounded chat-message-jsw bg-white"
        style={{
          position: "relative",
        }}
      >
        <CNavItem className="hover">
          <CNavLink
            className="nav-link mx-1"
            onClick={() => {
              setActiveKey(0);
            }}
            active={activeKey == 0}
            role="tab"
            disabled={props.el.summary
              ?.toLowerCase()
              .includes("answer not found")}
          >
            <BsBook /> Text
          </CNavLink>
        </CNavItem>
        <CNavItem className="hover">
          <CNavLink
            className="nav-link mx-1 py-1 d-flex align-items-center gap-2 px-1"
            onClick={() => {
              setActiveKey(1);
            }}
            active={activeKey == 1}
            role="tab"
            disabled={
              props.el.structured.toLowerCase().includes("answer not found") ||
              props.el.structured.includes(
                "Sorry, I'm Unable to explain the result."
              )
            }
            style={{ color: "rgb(23,70,158)" }}
          >
            <BsGraphUp color="rgb(246,123,36)" style={{ fontWeight: "900" }} />{" "}
            Structured
          </CNavLink>
        </CNavItem>
      </CNav> */}

      <CTabContent>
        <CTabPane role="tabpanprops.el" visible={activeKey == 0}>
          {props.el.summary
            ?.toLowerCase()
            .includes("answer not found") ? null : (
            <>
              <div
                className={`chat-message other m-0 mt-1 align-items-center `}
              >
                {props.el.summary.split("\n").map((str) => {
                  return <p className="p-0 m-0">{str}</p>;
                })}
              </div>

              {/* <div className={`chat-message other mt-2 align-items-center `}>
                <Citations el={props.el} />
              </div> */}
            </>
          )}
        </CTabPane>
        <CTabPane role="tabpanprops.el" visible={activeKey == 1}>
          {props.el.structured.toLowerCase().includes("answer not found") ||
          props.el.structured == "" ||
          props.el.structured == undefined ||
          props.el.structured.includes(
            "Sorry, I'm Unable to explain the result."
          ) ? null : (
            <>
              <div
                className={`chat-message other m-0 mt-1 position-relative pb-3`}
                style={{ color: "rgb(23,70,158)" }}
              >
                <div className={`align-items-center  `}>
                  {props.el.structured.split("\n").map((str) => {
                    return <p className="p-0 m-0">{convertToBold(str)}</p>;
                  })}
                </div>

                <CCol
                  className="d-flex justify-content-end"
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      borderRadius: "30px",
                      maxWidth: "fit-content",
                      justifySelf: "end",
                    }}
                    className="like  d-flex align-items-center justify-content-center px-2"
                  >
                    <div
                      className="position-relative p-1 me-1"
                      style={{
                        height: "1.8rem",
                        width: "1.8rem",
                      }}
                    >
                      <CTooltip content="Follow up" className="font-sm">
                        <ReplyIcon
                          onClick={() => {
                            setFollowVis(true);
                          }}
                          // size={"1.2rem"}
                          className="hover"
                        />
                      </CTooltip>
                      {badgeCount > 0 ? (
                        <CBadge
                          color="info"
                          position="top-end"
                          shape="rounded-pill"
                          className=""
                        >
                          {badgeCount}
                        </CBadge>
                      ) : null}
                    </div>
                    <CButton
                      className={`hover p-0 like d-flex align-items-center justify-content-center`}
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        border: "none",
                      }}
                      // color="light"
                      variant="ghost"
                      onClick={() => {
                        props.setLiked(true);
                        props.sendFeedback(1, props.el.created_at);
                      }}
                      disabled={props.liked}
                    >
                      {props.liked ? (
                        <FaThumbsUp />
                      ) : (
                        <FaRegThumbsUp className="like" />
                      )}
                    </CButton>

                    <CButton
                      className={`hover p-0 like d-flex align-items-center justify-content-center`}
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        border: "none",
                      }}
                      // color="light"
                      variant="ghost"
                      onClick={() => {
                        props.setDisLiked(true);
                        props.sendFeedback(0, props.el.created_at);
                      }}
                      disabled={props.disLiked}
                    >
                      {props.disLiked ? (
                        <FaThumbsDown />
                      ) : (
                        <FaRegThumbsDown className="like" />
                      )}
                    </CButton>
                  </div>
                </CCol>

                {/* <ReplyChatJSW
                  followVis={followVis}
                  el={props.el}
                  index={props.i}
                  setFollowVis={setFollowVis}
                  setBadgeCount={setBadgeCount}
                /> */}
              </div>
              <div className={`chat-message other align-items-center m-0 mt-2`}>
                <ChartTypesJSW data={props.el.results} />
              </div>
            </>
          )}
        </CTabPane>
      </CTabContent>
    </>
  );
};

export default PDTabs;
