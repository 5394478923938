import { CSidebar, CButton, CTooltip, CCol, CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { PiChatsLight } from "react-icons/pi";
import { useNavigate } from "react-router";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
  cilPlus,
  cilDelete,
} from "@coreui/icons";
import { v1 as uuidv1 } from "uuid";
import uuidTime from "uuid-time";
import QuestionCard from "./QuestionCard";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";

const JSWSidebar = ({
  generatedData,
  setGeneratedData,
  sidebarVis,
  setSidebarVis,
  setuuid,
  setConversationHistory,
  conversationHistory,
  feedbackFlag,
  loading2,
  clearData,
}) => {
  const [convo, setConvo] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const sortedArrays = {};
    for (const obj of conversationHistory) {
      if (!sortedArrays[obj.UUID]) {
        sortedArrays[obj.UUID] = [];
      }
      sortedArrays[obj.UUID].push(obj);
    }
    const result = Object.values(sortedArrays).sort((a, b) => {
      const uuidA = uuidTime.v1(a[0].UUID);
      const uuidB = uuidTime.v1(b[0].UUID);
      return -uuidA - uuidB;
    });
    setConvo(result);
  }, [conversationHistory.length]);
  const handleDelete = async (UUID, username) => {
    setLoading(true);
    sessionStorage.removeItem("conversationHistory");
    setConvo([]);
    setGeneratedData([]);
    setConversationHistory([]);
    setLoading(false);
  };

  const handleDeleteChat = (el) => {
    let data = sessionStorage.getItem("conversationHistory");
    if (data) {
      let tempData = JSON.parse(data);
      let tempConversion = tempData.conversion;
      const filteredData = tempConversion.filter(
        (item) => item.UUID != el[0].UUID
      );
      let conversionData = { conversion: filteredData };
      sessionStorage.setItem(
        "conversationHistory",
        JSON.stringify(conversionData)
      );
      console.log("filteredData", filteredData);
      if (filteredData.length === 0) {
        sessionStorage.removeItem("conversationHistory");
        setuuid(uuidv1({ msecs: new Date().getTime(), clockseq: 0x1234 }));
        clearData();
      } else {
        setConversationHistory([...filteredData]);
      }
    }

    setGeneratedData([]);
  };
  return (
    <CSidebar
      className="prod-sidebar-jsw"
      visible={sidebarVis}
      position="fixed"
      placement={"start"}
      style={{ zIndex: 12 }}
    >
      <div style={{ height: "50px" }}></div>
      <div className="d-flex justify-content-between " style={{ top: "10px" }}>
        <CButton
          className="sticky logout-button-jsw mb-2 font-md d-flex align-items-center justify-content-center gap-1"
          shape="rounded-pill"
          style={{ width: "80%" }}
          disabled={loading2}
          onClick={() => {
            setuuid(uuidv1({ msecs: new Date().getTime(), clockseq: 0x1234 }));
            setGeneratedData([]);
          }}
        >
          <CIcon icon={cilPlus} /> New Chat
        </CButton>
        <CButton
          className="p-0 mb-2 hamburger-button"
          style={{
            width: "35px",
          }}
          variant="outline"
          onClick={() => {
            setSidebarVis(!sidebarVis);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="hamburger-icon" />
        </CButton>
      </div>
      <div
        style={{ height: "85vh", overflow: "scroll" }}
        className=" px-0 scrollbar-jsw"
      >
        {loading2 ? (
          <div
            style={{ color: "#086eb5" }}
            className="mt-2 d-flex align-items-center gap-1"
          >
            Loading Conversations... <CSpinner size="sm" />
          </div>
        ) : (
          <>
            {convo?.map((el, i) => {
              return (
                <CCol
                  disabled={feedbackFlag}
                  onClick={() => {
                    if (!feedbackFlag) {
                      let sorted = el.sort((a, b) => {
                        let dateA = new Date(a.created_at);
                        let dateB = new Date(b.created_at);
                        return dateA.getTime() - dateB.getTime();
                      });
                      setGeneratedData(
                        sorted.map((k) => {
                          k.structured = k.summary;
                          k.dataset = k.results;
                          return k;
                        })
                      );
                      setuuid(el[0].UUID);
                    }
                  }}
                  key={i}
                  style={{
                    color: "#0B57D0",
                    border: "1px solid rgba(186, 211, 255,0.8)",
                  }}
                  className="p-1 font-md rounded  conv-hover my-2 mx-1"
                  onMouseEnter={() => setDeleteVisible(i)}
                  onMouseLeave={() => setDeleteVisible(false)}
                >
                  <QuestionCard
                    el={el}
                    index={i}
                    deleteVisible={deleteVisible}
                    handleDeleteChat={handleDeleteChat}
                  />
                </CCol>
              );
            })}
          </>
        )}
      </div>
      <div className="d-flex justify-content-between " style={{ top: "10px" }}>
        <CButton
          variant="outline"
          className="sticky delete-button mb-2 font-md d-flex align-items-center gap-2 justify-content-center"
          color="danger"
          shape="rounded-pill"
          style={{ width: "100%" }}
          disabled={conversationHistory.length == 0}
          onClick={() => {
            handleDelete();
          }}
        >
          {loading ? (
            <CSpinner size="sm" />
          ) : (
            <AiFillDelete style={{ fontSize: "1rem" }} />
          )}{" "}
          Delete All Chats
        </CButton>
      </div>
    </CSidebar>
  );
};

export default JSWSidebar;
