import React, { useState, useRef, useEffect } from "react";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CImage,
  CRow,
} from "@coreui/react";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilSend } from "@coreui/icons";
import PolicySidebar from "../components/PolicySidebar";
import ProdChat from "../components/PolicyChat";
import { v1 as uuidv1 } from "uuid";
import alLogo from "../assets/logos/logo-min.png";
import atgLogo from "../assets/logos/Atgeir-New-Logo_Dark.svg";
import { IoIosChatbubbles } from "react-icons/io";
import { Link } from "react-router-dom";
import { Navbar } from "../../../components/Navbar";
const PolicySearch = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([
    {
      question: undefined,
      summary: [
        "Hello, this is the Deceased Management Assistant.",
        "We understand this may be a difficult time for you. Is there anything we can assist you with today regarding a deceased account holder?",
      ],
    },
  ]);
  const [feedbackFlag, setFeedbackFlag] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const messageContainerRef = useRef(null);
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  //ask question function call
  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    const currentTime = getCurrentTime();
    setGeneratedData([
      ...generatedData,
      { question: inputPrompt, summary: "", qtime: currentTime },
    ]);
    setInputVal("");
    setLoading(true);
    getUnstructured(inputPrompt, currentTime);
  };
  const getUnstructured = async (inputPrompt, questionTime) => {
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/sbi-bot"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/int-banking-asst",
      headers: {
        "Content-Type": "application/json",
        // "X-API-Key": "AIzaSyDvGa7WBnpgzSD7YwLZ9tC5m9qgKd3kJnM",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("response", response);
        const currentTime = getCurrentTime();
        setLoading(false);
        setGeneratedData([
          ...generatedData,
          {
            question: inputPrompt,
            summary: [response.data.Result[0].response],
            qtime: questionTime,
            atime: currentTime,
          },
        ]);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      const currentTime = getCurrentTime();
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: ["Answer Not Found"],
          qtime: questionTime,
          atime: currentTime,
        },
      ]);
    }
  };
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;
    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    // Combine all parts into the desired format
    const currentTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    return currentTime;
  }

  // Example usage

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <PolicySidebar />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container">
            <div
              style={{
                overflowY: "scroll",
                height: "100%",
                borderRadius: "10px",
              }}
            >
              {generatedData.length == 0 ? (
                <div
                  style={{ width: "max-content", maxWidth: "100%" }}
                  className={`chat-message other mt-2 align-items-center chat-animation chat-transition-bot`}
                ></div>
              ) : (
                <>
                  {generatedData?.map((el, i) => {
                    return (
                      <div ref={messageContainerRef} className="pb-3">
                        <ProdChat
                          el={el}
                          i={i}
                          setFeedbackFlag={setFeedbackFlag}
                          setConversationHistory={setConversationHistory}
                          conversationHistory={conversationHistory}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => handlePrompt(e)}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setGeneratedData([{ question: undefined }]);
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder={
                    feedbackFlag && !loading
                      ? "Please give mandatory feedback for all the questions"
                      : "Ask a question"
                  }
                  disabled={loading}
                />
                <CButton
                  type="submit"
                  disabled={inputVal == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </CCol>
      </CRow>

      {/* <div
        className="mb-3 mx-2 d-flex justify-content-between position-relative"
        style={{ paddingRight: "370px" }}
      >
        <div className="position-absolute">
          <CImage width={140} src={atgLogo} />
         <CImage width={90} src={alLogo} />
        </div>
        <h4
          style={{ color: "#0b2e50", fontWeight: 500 }}
          className="p-0 m-0 w-100 text-center homepage-title"
        >
          Deceased Management Assistant
        </h4>
        <div>
          <CImage width={90} style={{ marginRight: "20px" }} src={AtgLogo} />
          <CImage width={90} src={googleLogo} />
        </div>
      </div>  */}
    </div>
  );
};

export default PolicySearch;
