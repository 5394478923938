import {
  CImage,
  CCloseButton,
  CButton,
  CFormCheck,
  CSpinner,
} from "@coreui/react";
import React, { useRef, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import VideoRecogPlayer from "./VideoRecogPlayer";
import { Checkbox } from "@mui/material";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";

const VideoRecogCard = (props) => {
  const [videoArr, setVideoArr] = useState([]);
  const [vis, setVis] = useState(false);
  const [viewPort, setViewPort] = useState();
  const [userPrompt, setUserPrompt] = useState("Write a Story");
  const [selectedCards, setSelectedCards] = useState([]);
  useEffect(() => {
    const sortedArrays = {};
    if (props.video != "Error") {
      for (const obj of props.video) {
        if (!sortedArrays[obj.gcs_uri]) {
          sortedArrays[obj.gcs_uri] = [];
        }
        sortedArrays[obj.gcs_uri].push(obj);
      }
      setVideoArr(Object.values(sortedArrays));
    }
  }, []);
  const toggleSelection = (cardId) => {
    if (selectedCards.includes(cardId)) {
      // Card is selected, remove it from the array
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
    } else {
      // Card is not selected, add it to the array
      setSelectedCards([...selectedCards, cardId]);
    }
  };
  return (
    <div className="fadein">
      {props.video != "Error" ? (
        <div
          className="mb-1 p-2 w-100"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gap: "10px",
          }}
        >
          {videoArr?.map((vid, index) => {
            return (
              <>
                <VideoRecogPlayer
                  vid={vid}
                  toggleSelection={toggleSelection}
                  id={index}
                />
              </>
            );
          })}
        </div>
      ) : null}
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          props.handleGeneratePrompt(
            userPrompt,
            props.question,
            videoArr,
            selectedCards
          );
        }}
      >
        <div
          className={`chat-message other mt-2 align-items-start font-md flex-column`}
          style={{ width: "max-content", maxWidth: "85%", marginLeft: "0px" }}
        >
          <div>
            Select videos and Choose what content you want to generate with
            those videos
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <label>
            <input
              type="radio"
              name="exampleRadios"
              className="card-input-element"
              onChange={() => {
                setUserPrompt("Write a Story");
              }}
              checked={userPrompt === "Write a Story"}
              disabled={selectedCards.length == 0}
            />
            <div
              className={`chat-message other mt-2 align-items-start font-md flex-column card-input`}
              style={{
                marginLeft: "0px",
                color: "#19a353",
                fontWeight: "normal",
              }}
            >
              Write a Story
            </div>
          </label>
          <label>
            <input
              type="radio"
              name="exampleRadios"
              className="card-input-element"
              onChange={() => {
                setUserPrompt("Generate an Advertisement");
              }}
              checked={userPrompt === "Generate an Advertisement"}
              disabled={selectedCards.length == 0}
            />
            <div
              className={`chat-message other mt-2 align-items-start font-md flex-column card-input`}
              style={{
                marginLeft: "0px",
                color: "#19a353",
                fontWeight: "normal",
              }}
            >
              Generate an Advertisement
            </div>
          </label>
          <label>
            <input
              type="radio"
              name="exampleRadios"
              className="card-input-element"
              onChange={() => {
                setUserPrompt("Describe the Content");
              }}
              checked={userPrompt === "Describe the Content"}
              disabled={selectedCards.length == 0}
            />
            <div
              className={`chat-message other mt-2 align-items-start font-md flex-column card-input`}
              style={{
                marginLeft: "0px",
                color: "#19a353",
                fontWeight: "normal",
              }}
            >
              Describe the Content
            </div>
          </label>
          <button
            disabled={selectedCards.length < 1 || props.loading}
            className="button-34 mt-2"
            type="submit"
          >
            Generate{" "}
            {props.loading ? (
              <CSpinner size="sm" style={{ color: "white" }} />
            ) : (
              <CIcon
                icon={cilSearch}
                className="hover text-light p-0"
                size="md"  
                // style={{ color: "#086EB5" }}
              />
            )}
          </button>
        </div>
      </form>
      {/* {props.img != "Error" ? (
        <div
          style={{
            display: "flex",
            backgroundColor: "rgba(255,255,255,0.6)",
            backdropFilter: "blur(10px)",
            padding: 5,
            borderRadius: 16,
            justifyContent: "space-evenly",
            width: "max-content",
            maxWidth: "100%",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
          className="ms-2"
        >
          <div
            style={{
              display: "flex",
              gap: vis ? "10px" : "10px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {props.img != "Error" &&
              props.img?.map((el) => {
                return (
                  <div
                    style={{
                      height: vis ? "210px" : "250px",
                      width: vis ? "280px" : "330px",
                      padding: "10px",
                    }}
                    className={`rounded-3 image-box d-flex flex-column ${
                      el.gcs_signed_uri == viewPort?.url ? "image-selected" : ""
                    }`}
                  >
                    <CImage
                      alt={el.path.split("/")[el.path.split("/").length - 1]}
                      onClick={() => {
                        setViewPort({
                          url: el.gcs_signed_uri,
                          name: el.path.split("/")[
                            el.path.split("/").length - 1
                          ],
                        });
                        setVis(true);
                      }}
                      className="image-design rounded-3 "
                      style={{
                        height: "90%",
                        width: "100%",
                      }}
                      src={el.gcs_signed_uri}
                    />
                    <div
                      className="font-lg"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.path.split("/")[el.path.split("/").length - 1]}
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              position: "sticky",
              top: "100px",
              right: "0px",
              marginLeft: "20px",
              padding: "10px",
              paddingTop: 15,
              maxWidth: "420px",
              background: "transparent",
              animationDuration: "1000ms",
            }}
            className={`${vis ? "rounded-3 fadein" : "d-none fadeout"}`}
          >
            {vis ? (
              <CCloseButton
                onClick={() => {
                  setVis(false);
                  setViewPort();
                }}
                style={{
                  position: "absolute",
                  right: 20,
                  top: 20,
                  // border: "1px solid black",
                  borderRadius: "50%",
                  padding: "6px",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  color: "white",
                }}
                className="text-light"
              />
            ) : null}
            <CImage
              className={`${vis ? "resize opened" : "resize"}`}
              src={viewPort?.url}
            />
            <div
              className="font-lg mt-2 mx-2"
              style={{
                wordBreak: "break-word",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {viewPort?.name}
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default VideoRecogCard;
