import { cilNotes } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCollapse,
  CFormInput,
  CSpinner,
} from "@coreui/react";
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { ghcolors } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { LuCopy, LuCopyCheck } from "react-icons/lu";

export const CodeViewWhite = ({ codeText }) => {
  const [showMore, setShowMore] = useState(false);
  const [copy, setCopy] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleCopy = () => {
    setCopy(true);
    navigator.clipboard.writeText(codeText);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  return (
    <>
      <CCard
        className="text-center mt-0 mb-2"
        style={{
          maxWidth: "100%",
          fontSize: "14px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          fontWeight: "500",
          borderRadius: "5px",
        }}
      >
        <CCardHeader
          className="bg-white border-bottom p-0 m-0 px-2 py-1"
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: visible ? "0px" : "5px",
            borderBottomRightRadius: visible ? "0px" : "5px",
            cursor: "pointer",
          }}
          onClick={() => setVisible(!visible)}
        >
          <div
            className={`d-flex align-items-center cursor-pointer justify-content-between`}
          >
            <div
              className="text-dark fw-500"
              style={{ letterSpacing: "0.5px", fontSize: "12px" }}
            >
              {"Query Text"}
            </div>
            <div>
              {visible ? (
                <FaAngleUp size={"1rem"} />
              ) : (
                <FaAngleDown size={"1rem"} />
              )}
            </div>
          </div>
        </CCardHeader>
        <CCollapse visible={visible}>
          <CCardBody
            className={`p-0 pt-3 ps-2 position-relative`}
          >
            <CCardText
              style={{ width: "100%" }}
              className="p-0 m-0 visible-scroll "
            >
              <SyntaxHighlighter
                id="scrollbar-new"
                className="visible-scroll"
                customStyle={{
                  border: "none",
                  margin: "0px",
                  padding: "0px",
                  paddingRight: "5px",
                  paddingBottom: "15px" ,
                  maxHeight: showMore ? null : "300px", // Adjust the height
                  width: "100%",
                  // Add scroll when content exceeds the height
                }}
                language="sql"
                style={ghcolors}
                showLineNumbers
              >
                {codeText}
              </SyntaxHighlighter>
            </CCardText>
            {visible && (
              <div
                onClick={() => {
                  setVisible(true);
                  handleCopy();
                }}
                className="d-flex align-items-center "
                style={{
                  position: "absolute",
                  right: "7px",
                  top: "5px",
                  cursor: "pointer",
                }}
              >
                {copy ? (
                  <div className="d-flex align-items-center gap-1">
                    <LuCopyCheck size={"1rem"} />
                    <span style={{ fontSize: "12px" }}>Copied!</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <LuCopy size={"1rem"} />
                  </div>
                )}
              </div>
            )}
          </CCardBody>
        </CCollapse>
      </CCard>
    </>
  );
};
