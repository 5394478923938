import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CFormTextarea,
  CImage,
  CFormLabel,
  CRow,
  CAlert,
  CCard,
  CHeaderDivider,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import aiImage from "../../assets/images/ai_image.png";
import { cilSend } from "@coreui/icons";
import { FcDataSheet, FcSearch, FcPanorama, FcUpload } from "react-icons/fc";
import { ChatMessage } from "../../components/ChatMessage";
import axios from "axios";
import VideolUrlSidebar from "./VideoSidebar";
import VideoCard from "./VideoCard";
import { QnAMessage } from "../../components/QnAMessage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IoIosChatbubbles } from "react-icons/io";
import CommonSidebar from "../../components/CommonSidebar";
const VideolPrompt = () => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const [promptList, setPromptList] = useState([]);
  const messageContainerRef = useRef(null);
  const [urlGeneratedData, setUrlGeneratedData] = useState([]);
  const [blobName, setBlobName] = useState();
  const [inputUrl, setInputUrl] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [urlVal, setUrlVal] = useState([]);
  const [urlError, setUrlError] = useState(false);
  const [visible, setVisible] = useState(true);

  // useState([{
  //   question:"Tell about website",
  //   answer:'The website is about web development. It provides tutorials, exercises, quizzes, and certifications for HTML, CSS, Bootstrap, Data Analytics, and more.'
  // }]);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  const handleDelete = async () => {
    setUrlGeneratedData([]);
    // setInputUrl("")
  };
  const handleSubmit = async (question) => {
    // console.log("question", question, inputUrl)
    if (question.trim() == "") {
      return;
    }
    setUrlGeneratedData([
      ...urlGeneratedData,
      {
        question: question,
        url: inputUrl,
        answer: [],
      },
    ]);
    setLoading(true);
    let data = {
      question: question,
      url: inputUrl,
    };
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/videofunctionmaruti-5",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data,
    };
    try {
      // console.log("Config", config);
      let response = await axios(config);

      const respoArray = response.data.data;
      console.log(Array.isArray(respoArray)); // true
      let isValid = Array.isArray(respoArray);

      if (isValid) {
        // setGeneratedData([
        //   ...generatedData,
        //   response.data.result,
        // ]);
        setLoading(false);
        setUrlGeneratedData([
          ...urlGeneratedData,
          {
            question: question,
            answer: response.data.data[0],
            url: inputUrl,
          },
        ]);
      } else {
        setLoading(false);

        setUrlGeneratedData([
          ...urlGeneratedData,
          {
            question: question,
            answer: "Error",
            url: inputUrl,
          },
        ]);
      }
    } catch (err) {
      console.log("Response Error", err);
      setLoading(false);
      setUrlGeneratedData([
        ...urlGeneratedData,
        {
          question: question,
          answer: "Error",
          url: inputUrl,
        },
      ]);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [urlGeneratedData]);

  const handleUrlData = (url) => {
    setInputUrl(url);
  };

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={urlGeneratedData}
            setGeneratedData={setUrlGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"Ask Questions from YouTube Video"}
            CustomComponent={
              <VideolUrlSidebar
                urlList={urlList}
                setUrlList={setUrlList}
                inputUrl={inputUrl}
                setInputUrl={setInputUrl}
                urlVal={urlVal}
                setUrlVal={setUrlVal}
                urlError={urlError}
                setUrlError={setUrlError}
                handleUrlData={handleUrlData}
              />
            }
            CComponentTitle={"YouTube URLs Upload"}
          />
        </CCol>
        <CCol sm={9} className="ps-1" style={{ position: "relative" }}>
          <div className="chat-container" ref={messageContainerRef}>
            {urlGeneratedData.length == 0 ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcUpload size={"1.5rem"} /> Upload Youtube URLs
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Please provide YouTube Video URLs in order to address the
                      question adequately.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcDataSheet size={"1.5rem"} /> Index Data
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Generate information from the submitted YouTube URLs.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 left-to-right"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcSearch size={"1.5rem"} /> Ask Questions{" "}
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask questions to get the insights from the Video data.
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {urlGeneratedData?.map((data, index) => {
              return (
                <div>
                  <ChatMessage
                    key={index}
                    text={data.question}
                    isUser={true}
                    isError={true}
                  />

                  {data.answer.length == 0 ? (
                    <div
                      style={{ marginLeft: "20px", marginRight: "10px" }}
                      className={`chat-message other align-items-center`}
                    >
                      <SkeletonTheme
                        baseColor="#f4d9ff"
                        highlightColor="#3EB7EF"
                      >
                        <h4 style={{ width: "100%" }}>
                          <Skeleton count={1} width={"40%"} />
                        </h4>
                        <p style={{ width: "100%" }}>
                          <Skeleton height={12} width={"90%"} count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ) : data.answer == "Error" ? (
                    <div name={0}>
                      <QnAMessage
                        key={0}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : (
                    <VideoCard videoData={data} />
                  )}
                </div>
              );
            })}
            <CForm
              className="pt-3 new-input-form"
              onSubmit={(e) => {
                e.preventDefault();
                setPromptList([e.target[0].value]);
                setPrompt("");
                handleSubmit(e.target[0].value);
              }}
            >
              <div
                className="rolling-chat"
                onClick={() => {
                  setUrlGeneratedData([]);
                  setUrlList([]);
                  setGeneratedData([]);
                  setPromptList([]);
                  handleDelete();
                }}
              >
                <IoIosChatbubbles size={"2rem"} />
                <span className="font-lg" style={{ fontWeight: 600 }}>
                  New Chat
                </span>
              </div>
              <CCard
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "80%",
                  padding: "2px",
                  borderRadius: "50px",
                }}
              >
                <CCol
                  className="d-flex align-items-center w-100 inputfocus m-auto "
                  style={{
                    height: "45px",
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "50px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  <CFormInput
                    className="border-0 inputfocus form-control font-md"
                    style={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      color: "#086EB5",
                      borderRadius: "50px",

                      height: "40px",
                    }}
                    disabled={inputUrl.length == 0 ? true : false}
                    onChange={(e) => setPrompt(e.target.value)}
                    value={prompt}
                    placeholder="Ask me something"
                  />
                  <CButton
                    type="submit"
                    disabled={prompt == "" || loading}
                    variant="ghost"
                    size="sm"
                    className="p-0 border-0 hover"
                  >
                    {loading ? (
                      <CSpinner
                        size="sm"
                        className="m-2 "
                        style={{ color: "#086EB5" }}
                      />
                    ) : (
                      <CIcon
                        icon={cilSend}
                        className="m-2 hover text-success"
                        size="lg"
                      />
                    )}
                  </CButton>
                </CCol>
              </CCard>
            </CForm>
          </div>
        </CCol>
      </CRow>
    </div>
  );
};

export default VideolPrompt;
