import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CToast,
  CToaster,
  CInputGroup,
} from "@coreui/react";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilInfo, cilSend, cilWarning } from "@coreui/icons";

import { v1 as uuidv1 } from "uuid";
import { FcSearch, FcBullish, FcDataSheet, FcDocument } from "react-icons/fc";
import JSWChat from "../../components/JSWChat";
import JSWSidebar from "../../components/JSWSidebar";
import { IoSendSharp } from "react-icons/io5";
const JSWPrompt = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const [sidebarVis, setSidebarVis] = useState(true);
  const [UUID, setuuid] = useState(
    uuidv1({ msecs: new Date().getTime(), clockseq: 0x1234 })
  );
  const [feedbackFlag, setFeedbackFlag] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const messageContainerRef = useRef(null);
  const toastRef = useRef();

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  //ask question function call
  const handlePrompt = async (e) => {
    const now = new Date();
    e.preventDefault();
    let resData = [];
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    setGeneratedData([
      ...generatedData,
      { question: inputPrompt, summary: "", created_at: now, feedback: null },
    ]);
    setInputVal("");
    setLoading(true);
    // setFeedbackFlag(true);
    Promise.all([
      // getUnstructured(inputPrompt),
      getStructured(inputPrompt),
    ]).then((response) => {
      setLoading(false);
      let result = {
        question: inputPrompt,
        summary: response[0].summary,
        img_search_data: response[0].img_search_data,
        tuning_data: response[0].tuning_data,
        results: response[0].dataset,
        structured: response[0].summary,
        dataset: response[0].dataset,
        created_at: now,
        feedback: null,
      };
      setLoading(false);
      setGeneratedData([...generatedData, result]);
      saveData(result);
    });
  };

  const saveData = (result) => {
    let username = JSON.parse(sessionStorage.getItem("authentication"));
    let mydata = {
      username: username,
      question: result.question,
      summary: result.summary,
      feedback: result.feedback,
      UUID: UUID,
      img_search_data: result.img_search_data,
      results: result.results,
      tuning_data: result.tuning_data,
      created_at: result.created_at,
    };
    let data = sessionStorage.getItem("conversationHistory");
    if (data) {
      let tempData = JSON.parse(data);
      let tempConversion = [...tempData["conversion"], mydata];
      let conversionData = { conversion: tempConversion };
      sessionStorage.setItem(
        "conversationHistory",
        JSON.stringify(conversionData)
      );
      setConversationHistory(tempConversion);
      setLoading(false);
    } else {
      let conversionData = { conversion: [mydata] };
      sessionStorage.setItem(
        "conversationHistory",
        JSON.stringify(conversionData)
      );
      setConversationHistory([mydata]);
      setLoading(false);
    }
  };

  const clearData = () => {
    setGeneratedData([]);
    setConversationHistory([]);
  };
  // const getUnstructured = async (inputPrompt) => {
  //   let config = {
  //     method: "post",
  //     url: "https://al-genai-api-v1-gateway-a8d67j5q.uc.gateway.dev/reports",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     data: {
  //       question: inputPrompt,
  //     },
  //   };
  //   try {
  //     let response = await axios(config);
  //     if (response != undefined) {
  //       return {
  //         summary: response.data.summary,
  //         img_search_data: response.data.img_search_data,
  //         tuning_data: response.data.input_text,
  //         results: response.data.results,
  //       };
  //     }
  //   } catch (error) {
  //     console.log("data error", error);
  //     return {
  //       question: inputPrompt,
  //       summary: "Answer Not Found",
  //       img_search_data: [],
  //       tuning_data: "",
  //       results: "",
  //     };
  //   }
  // };
  const getStructured = async (inputPrompt) => {
    const now = new Date();
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-question-jsw-t2sql",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(config);
      console.log("getStructured", response);
      if (response != undefined) {
        return {
          summary: response.data.get_explain,
          dataset: response.data.values,
        };
      }
    } catch (error) {
      return { summary: "Answer Not Found", created_at: now, feedback: null };
    }
  };
  useEffect(() => {
    getConversations();
  }, []);

  const getConversations = async () => {
    setLoading2(true);
    let username = JSON.parse(sessionStorage.getItem("authentication"));
    // let config = {
    //   method: "post",
    //   url: "https://al-genai-api-v1-gateway-a8d67j5q.uc.gateway.dev/history",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   data: {
    //     username,
    //   },
    // };
    try {
      // let response = await axios(config);
      let data = sessionStorage.getItem("conversationHistory");
      if (data) {
        let tempData = JSON.parse(data);
        let tempConversion = [...tempData["conversion"]];
        setConversationHistory(tempConversion);
        setLoading2(false);
      } else {
        setConversationHistory([]);
        setLoading2(false);
      }
      // setConversationHistory(response.data.chat_data);

      // if (response != undefined) {
      // setConversationHistory(response.data.chat_data);
      // setLoading2(false);
      // }
    } catch (error) {
      console.log("data error", error);
      setLoading2(false);
    }
  };
  return (
    <div
      className="font-md"
      style={{
        background:
          "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
      }}
    >
      <Navbar subtitle="(Reports)" />
      <JSWSidebar
        generatedData={generatedData}
        setGeneratedData={setGeneratedData}
        sidebarVis={sidebarVis}
        setSidebarVis={setSidebarVis}
        setuuid={setuuid}
        conversationHistory={conversationHistory}
        setConversationHistory={setConversationHistory}
        feedbackFlag={feedbackFlag}
        loading2={loading2}
        clearData={clearData}
      />
      {!sidebarVis ? (
        <CButton
          className="p-0 position-fixed hamburger-button-active"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "60px",
            left: 10,
            zIndex: 12,
          }}
          onClick={() => {
            setSidebarVis(!sidebarVis);
          }}
        >
          <CIcon icon={cilHamburgerMenu} />
        </CButton>
      ) : null}
      <div
        ref={messageContainerRef}
        style={{
          backgroundColor: "rgba(221,233,254,0.3)",
          paddingLeft: sidebarVis ? "250px" : "100px",
          paddingRight: sidebarVis ? "0px" : "100px",
          paddingTop: "10px",
          overflowY: "auto",
          height: "90vh",
          textAlign: "left",
          paddingBottom: "80px",
          transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
          // backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
        }}
        className="scrollbar-jsw"
      >
        {/* <CToaster
          ref={toastRef}
          push={
            feedbackFlag && !loading ? (
              <CToast
                color="warning"
                visible={feedbackFlag && !loading}
                delay={5000}
                className="p-2 font-md d-flex align-items-center gap-1 w-100"
              >
                <CIcon icon={cilInfo} size="sm" height={15} width={15} />
                <div>Please give mandatory feedback for all the questions</div>
              </CToast>
            ) : null
          }
          placement="top-end"
        /> */}

        {generatedData.length == 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1,1fr)",
              gap: 25,
              margin: "auto",
              width: "80%",
            }}
            className="mt-2"
          >
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 left-to-right"
            >
              <b className="d-flex gap-2 align-items-center">
                <FcSearch size={"1.5rem"} /> Ask a Question
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Ask a question in natural language on Excel Documents.
              </div>
            </CCard>
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 right-to-left"
            >
              {" "}
              <b className="d-flex gap-2 align-items-center">
                <FcDataSheet size={"1.5rem"} /> Get Structured Data
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Returns results from Excel Documents in structured format and
                get summary in natural language.
              </div>
            </CCard>
            {/* <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 left-to-right "
            >
              {" "}
              <b className="d-flex gap-2 align-items-center">
                <FcDocument size={"1.5rem"} /> Get Unstructured Data
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Get natural language summary from PDF Documents
              </div>
            </CCard>{" "} */}
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 right-to-left"
            >
              <b className="d-flex gap-2 align-items-center">
                <FcBullish size={"1.5rem"} /> View Dashboards
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Visualize your data with easy to understand tables and graphs
                with option to add customised graphs as per your need.
              </div>
            </CCard>
          </div>
        ) : (
          <>
            {generatedData?.map((el, i) => {
              return (
                <JSWChat
                  el={el}
                  i={i}
                  UUID={UUID}
                  setFeedbackFlag={setFeedbackFlag}
                  setConversationHistory={setConversationHistory}
                  conversationHistory={conversationHistory}
                />
              );
            })}
          </>
        )}
      </div>
      {generatedData.length >= 20 ? (
        <CAlert
          style={{
            bottom: 100,
            position: "fixed",
            marginLeft: sidebarVis ? 450 : 300,
            zIndex: 11,
          }}
          color={"warning"}
          className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
        >
          <CIcon icon={cilWarning} size="sm" height={15} width={15} />
          <div>
            You have reached the chat limit of 20 questions. Please create New
            Chat chat to continue asking more questions.
          </div>
        </CAlert>
      ) : null}
      {/* <CForm
        className="pt-3"
        style={{
          bottom: 0,
          position: "fixed",
          paddingLeft: sidebarVis ? 250 : 0,
          width: "100%",
          paddingBottom: "15px",
          background: "linear-gradient(135deg, #f5f7fa 100%, #c3cfe2 10%)",
          backdropFilter: "blur(10px)",
          zIndex: 11,
        }}
        onSubmit={(e) => handlePrompt(e)}
      >
        <CCard
          style={{
            backgroundColor: "transparent",
            border: "none",
            width: "90%",
            padding: "2px",
            borderRadius: "10px",
          }}
          className=" m-auto inputfocus-jsw"
        >
          <CCol
            className="d-flex align-items-center w-100 inputfocus-jsw m-auto "
            style={{
              height: "45px",
              backgroundColor: "white",
              border: "none",
              borderRadius: "10px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <CFormInput
              className="border-0 inputfocus-jsw form-control font-lg"
              style={{
                backgroundColor: "white",
                boxShadow: "none",
                color: "#086EB5",
                borderRadius: "50px",

                height: "40px",
              }}
              onChange={(e) => {
                e.preventDefault();
                setInputVal(e.target.value);
              }}
              value={inputVal}
              placeholder={
                feedbackFlag && !loading
                  ? "Please give mandatory feedback for all the questions"
                  : "Ask a question"
              }
              disabled={loading || generatedData.length >= 20 || feedbackFlag}
            />
            <CButton
              type="submit"
              disabled={inputVal == "" || loading}
              variant="ghost"
              size="sm"
              className="p-0 border-0 hover"
            >
              {loading ? (
                <CSpinner
                  size="sm"
                  className="m-2 "
                  style={{ color: "#086EB5" }}
                />
              ) : (
                <IoSendSharp
                  size={"1.2rem"}
                  color="rgb(23,70,158)"
                  className="mx-2"
                />
                // <CIcon
                //   icon={cilSend}
                //   className="m-2 hover text-success"
                //   size="lg"
                // />
              )}
            </CButton>
          </CCol>
        </CCard>
      </CForm> */}
      <CForm
        className="pt-3"
        style={{
          bottom: 0,
          position: "fixed",
          paddingLeft: sidebarVis ? 250 : 0,
          width: "100%",
          paddingBottom: "15px",
          backgroundColor: "rgba(221,233,254)",
          // background: "linear-gradient(135deg, #f5f7fa 90%, rgba(221,233,254,0.3) 30%)",
          backdropFilter: "blur(10px)",
          zIndex: 11,
        }}
        onSubmit={(e) => handlePrompt(e)}
      >
        <CInputGroup className="px-3 position-relative ">
          <CFormInput
            className="border-0 inputfocus form-control"
            style={{
              backgroundColor: "white",
              boxShadow: "none",
              color: "rgb(23,70,158)",
              borderRadius: "10px",
              height: "40px",
              paddingRight: "45px",
            }}
            onChange={(e) => {
              e.preventDefault();
              setInputVal(e.target.value);
            }}
            value={inputVal}
            placeholder={
              // feedbackFlag && !loading
              //   ? "Please give mandatory feedback for all the questions"
              // :
              "Ask a question"
            }
            disabled={loading || generatedData.length >= 20}
          />
          <CButton
            type="submit"
            disabled={inputVal == "" || loading}
            variant="ghost"
            size="sm"
            className="p-0 border-0 hover position-absolute"
            style={{ right: "18px", top: "8px", zIndex: 5 }}
          >
            {loading ? (
              <CSpinner
                size="sm"
                className="mx-2"
                style={{ color: "rgb(23,70,158)" }}
              />
            ) : (
              <IoSendSharp
                size={"1.3rem"}
                color="rgb(23,70,158)"
                className="mx-2"
              />
              // <CIcon
              //   icon={cilSend}
              //   className="m-2 hover text-success"
              //   size="lg"
              // />
            )}
          </CButton>
        </CInputGroup>
      </CForm>
    </div>
  );
};

export default JSWPrompt;
