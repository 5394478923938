import { CContainer, CImage, CButton, CCardTitle } from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Light.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import CIcon from "@coreui/icons-react";
import {
  cilAccountLogout,
  cilHamburgerMenu,
  cilPowerStandby,
} from "@coreui/icons";
import { useNavigate } from "react-router-dom";

// const lookerApi = require("looker-sdk");
export const Navbar = (props) => {
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg px-3 navbar-light sticky-top w-100 justify-content-between align-items-center d-flex">
      <div>
        <Link to={"/home"}>
          <CImage width={130} src={AtgLogo} />
        </Link>
      </div>
      <CCardTitle
        // className="mt-3"
        style={{
          color: "white",
          alignItems: "end",
          fontWeight: 500,
          fontFamily: "system-ui",
        }}
      >
        DataGeir™ - GenAI Workbench (Demo Instance)
      </CCardTitle>
      <div className="d-flex align-items-center">
        {/* <CImage width={130} src={googleLogo} /> */}
        <CIcon
          icon={cilPowerStandby}
          className="hover  logout-icon-2"
          size="xl"
          onClick={() => {
            navigate("/");
            sessionStorage.clear();
          }}
        />
      </div>
    </nav>
  );
};
