import { CCard, CCardBody, CCardTitle, CCardText, CBadge } from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
const HomeCard = (props) => {
  const navigate = useNavigate();
  return (
    <div
      class="ag-courses_item"
      onClick={() => {
        navigate(props.route);
      }}
    >
      {props.icon}
      <div class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>
        <div class="ag-courses-item_title">{props.title}</div>
        <div class="ag-courses-item_date-box">{props.description}</div>
        <div
          className="font-md d-flex gap-2 p-1"
          style={{ fontWeight: 500, marginTop: "5px" }}
        >
          {props.tags.map((el) => {
            return (
              <span
                className="fixed-alert release-alert"
                style={{ textWrap: "nowrap" }}
              >
                {el}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
