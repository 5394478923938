import { cilX, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CButton,
  CForm,
  CFormInput,
  CSpinner,
  CFormLabel,
  CAlert,
  CRow,
  CCardText,
  CDropdownDivider,
  CHeaderDivider,
  CAvatar,
  CImage,
  CSidebar,
} from "@coreui/react";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { BsLink45Deg } from "react-icons/bs";
import { IoMdCloudDone } from "react-icons/io";
// import UploadPDF from "../pages/UploadPdf";
// import pdfIcon from "../assets/images/pdf_icon.png";

const VideolUrlSidebar = (props) => {
  return (
    <div className="p-1">
      <CForm
        className="d-flex align-items-center inputfocus  mb-0 "
        style={{
          borderRadius: "5px",
          height: "35px",
          backgroundColor: "white",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!props.urlList.includes(props.urlVal)) {
            props.setUrlList([...props.urlList, props.urlVal]);
            // props.setUrlList([props.urlVal]);
            props.setUrlVal("");
            props.handleUrlData(props.urlVal);
          } else {
            props.setUrlError(true);
          }
        }}
      >
        <CFormInput
          type="url"
          pattern="https://.*"
          className="border-0 inputfocus form-control position-relative font-md m-0"
          style={{
            backgroundColor: "white",
            boxShadow: "none",
            // color: "#086EB5",
            height: "30px",
            resize: "none",
          }}
          onChange={(e) => {
            props.setUrlVal(e.target.value);
            props.setUrlError(false);
          }}
          value={props.urlVal}
          placeholder="Enter video URL here"
        />
        <CButton
          type="submit"
          disabled={props.urlVal == ""}
          // disabled={urlVal == "" || urlList.length >= 3}
          variant="ghost"
          className="p-0 border-0 hover"
        >
          <CIcon
            icon={cilPlus}
            className="hover m-2"
            style={{
              color: "green",
              height: "20px",
              width: "20px",
            }}
          ></CIcon>
        </CButton>
      </CForm>
      {props.urlError ? (
        <span
          className="m-0 position-absolute mb-2 ms-2 pt-0"
          style={{
            color: "red",
            fontSize: "10px",
          }}
        >
          URL already Exists
        </span>
      ) : null}
      <CCol className="mt-2">
        {props.urlList?.map((el, i) => {
          return (
            <CCol
              key={i}
              style={{
                textOverflow: "ellipsis",
                color: "#086eb5",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              className="py-1 mt-1 hover"
            >
              {/* <marquee scrollDelay={300}> */}
              <BsLink45Deg className="hover" style={{ color: "#086EB5" }} />
              {el}
              {/* </marquee> */}
            </CCol>
          );
        })}
        {props.urlList.length > 0 ? (
          <CHeaderDivider className="border my-2" />
        ) : null}
      </CCol>
    </div>
  );
};

export default VideolUrlSidebar;
