import React, { useState } from "react";
import { CButton, CAvatar, CCol } from "@coreui/react";
import bot from "../assets/images/OIG.png";
import { ChatMessage } from "../../../components/ChatMessage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ProdChat = ({ el, i }) => {
  return (
    <div
      style={{
        paddingRight: "20px",
        maxWidth: "100%",
        // border: "1px solid red",
      }}
    >
      {el.question != undefined ? (
        <ChatMessage
          key={i + 1}
          text={el.question}
          isUser={true}
          isError={false}
          time={el.qtime}
        />
      ) : null}
      {el.summary == "" ? (
        <div
          style={{
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
          className="d-flex  justify-content-start mt-3 align-items-start"
        >
          <CAvatar src={bot} color="primary" textColor="white">
            A
          </CAvatar>
          <CCol
            className={` m-0 ms-3 align-items-center `}
            style={{
              maxWidth: "91%",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "5px 10px",
              marginBottom: "5px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
              <h4>
                <Skeleton count={1} width={"50%"} />
              </h4>
              <p>
                <Skeleton height={12} width={"75%"} count={2} />
              </p>
            </SkeletonTheme>
          </CCol>
        </div>
      ) : el.summary != undefined &&
        el.summary[0]?.toLowerCase().includes("answer not found") ? (
        <div name={i} style={{ position: "relative" }}>
          <div
            style={{
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
            className="d-flex  justify-content-start align-items-start mt-3 position-relative"
          >
            <span
              className="font-sm p-0 rounded-1 m-0 position-absolute text-medium-emphasis bg-white chat-animation chat-transition-user-sbi"
              style={{ top: "-17px", left: "70px" }}
            >
              {el.atime}
            </span>
            <CAvatar src={bot} color="primary" textColor="white">
              A
            </CAvatar>
            <div
              style={{ width: "max-content", maxWidth: "700px" }}
              className={`chat-message other align-items-center chat-animation chat-transition-bot`}
            >
              I apologize, but the current question is outside the scope of my
              training. Please ask questions related to Deceased person account
              Management.
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
          className="d-flex  justify-content-start align-items-start mt-3 position-relative"
        >
          <span
            className="font-sm p-0 rounded-1 m-0 position-absolute text-medium-emphasis bg-white chat-animation chat-transition-user-sbi"
            style={{ top: "-17px", left: "70px" }}
          >
            {el.atime}
          </span>
          {el.summary != undefined ? (
            <CAvatar src={bot} color="primary" textColor="white">
              A
            </CAvatar>
          ) : null}
          <div>
            {el.summary != undefined &&
              el.summary?.map((str) => {
                return (
                  <div
                    style={{
                      width: "max-content",
                      maxWidth: "700px",
                    }}
                    className={`chat-message other align-items-center position-relative `}
                  >
                    {str.split("\n").map((str) => {
                      return (
                        <p style={{ maxWidth: "100%" }} className=" p-0 m-0">
                          {str}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProdChat;
