import React, { useEffect, useRef, useState } from "react";
import {
  CAvatar,
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormTextarea,
  CImage,
  CInputGroup,
  CRow,
  CSpinner,
} from "@coreui/react";
import "react-loading-skeleton/dist/skeleton.css";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilSend } from "@coreui/icons";
import { FcSearch, FcBullish, FcAcceptDatabase } from "react-icons/fc";
import "../styles/prompt.css";
import axios from "axios";
import ChartTypes from "../components/ChartTypes";
import { ChatMessage } from "../components/ChatMessage";
import OIG from "../assets/images/OIG.png";
import { IoSendSharp } from "react-icons/io5";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IoIosChatbubbles } from "react-icons/io";
import { Navbar } from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import CommonSidebar from "../components/CommonSidebar";
import aiImage from "../assets/images/ai_image.png";
import arcDiag from "../assets/images/ask2bigquery_arch_dia.png";
const Prompt = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));
  const [visible, setVisible] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const messageContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const lastMessageRef = useRef(null);

  // Scroll to the last message when data updates
  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handlePrompt = async (e) => {
    e.preventDefault();
    const inputPrompt = inputVal.trim();
    if (inputPrompt.trim() == "") {
      return;
    }

    setLoading(true);
    let transformedData = [];

    if (generatedData != undefined && generatedData.length > 0) {
      transformedData = generatedData
        .filter((item) => {
          if (item.hasOwnProperty("explanation")) {
            if (
              !item.explanation?.toLowerCase().includes("answer not found") &&
              !item.explanation?.toLowerCase().includes("hello")
            ) {
              return item;
            } else {
              return;
            }
          } else {
            return;
          }
        })
        .map((item) => {
          return {
            user: item.question,
            ai: item.explanation,
          };
        });
    }
    console.log("transformedData", transformedData);
    console.log("prompt list", generatedData);

    setGeneratedData([
      ...generatedData,
      {
        question: inputPrompt,
        explanation: "",
      },
    ]);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/ask-question-bq-kapiva-multiturn"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/int-askbigquery",
      // url: "https://us-central1-linear-analyst-420619.cloudfunctions.net/ask-question-bq-kapiva-multiturn",
      // url: "https://us-central1-linear-analyst-420619.cloudfunctions.net/ask-question-bq-kapiva",
      // url:"https://us-central1-generativeai-coe.cloudfunctions.net/ask-question-bq-kapiva",
      // url: "https://us-central1-generativeai-coe.cloudfunctions.net/talk_to_bq_v2",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        question: [...generatedData, inputPrompt.trim()],
        conversation_history_list: [...transformedData],
        username: "genaidemo@atgeirsolutions.com",
      },
    };
    try {
      const response = await axios(config);
      console.log("bq", response);
      // let response = true;
      if (response != undefined) {
        let result = {
          question: inputPrompt,
          ...response.data,
        };
        setLoading(false);
        setInputVal("");
        setGeneratedData([...generatedData, result]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setInputVal("");
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, explanation: "answer not found" },
      ]);
      setLoading(false);
    }
  };

  // const ChatMessage = ({ text, isUser }) => {
  //   let name = JSON.parse(
  //     sessionStorage.getItem("authentication")
  //   )[0].toUpperCase();
  //   return (
  //     <>
  //       {isUser ? (
  //         <div
  //           className={`chat-message user mt-2 align-items-center`}
  //           style={{ marginLeft: "20px" }}
  //         >
  //           {console.log("text isUser", text)}
  //           {text}
  //         </div>
  //       ) : (
  //         <div className={`chat-message other mt-2 align-items-center`}>
  //           {console.log("text other", text)}
  //           {text} &nbsp;
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputVal.trim() !== "") {
      handlePrompt(e);
    }
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />

      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={generatedData}
            setGeneratedData={setGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"Ask BigQuery"}
            askQuestion={true}
            ArcDia={arcDiag}
          />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container" ref={messageContainerRef}>
            {generatedData.length == 0 ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                     style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcSearch size={"1.5rem"} /> Ask a Question
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask a question in natural language on sales database
                    </div>
                  </CCard>
                  <CCard
                     style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcAcceptDatabase size={"1.5rem"} /> Get Structured Data
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Returns results from Big Query database according to your
                      question
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcBullish size={"1.5rem"} /> View Dashboards
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Visualize your data with easy to understand tables and
                      graphs with option to add customised graphs as per your
                      need.
                    </div>
                  </CCard>
                </div>
              </>
            ) : (
              <>
                {generatedData?.map((el, i) => {
                  return (
                    <div
                      style={{ paddingRight: "20px" }}
                      className="mt-2 mb-2"
                      ref={
                        i === generatedData.length - 1 ? lastMessageRef : null
                      }
                    >
                      <ChatMessage key={i} text={el.question} isUser={true} />
                      <CCol
                        xs={{ cols: 2 }}
                        className="d-flex align-items-top justify-content-start ms-3 gap-2"
                      >
                        <CCol style={{ maxWidth: "fit-content" }}>
                          <CAvatar
                            src={OIG}
                            className="avatar"
                            style={{ padding: "2px" }}
                          ></CAvatar>
                        </CCol>

                        {el.explanation === "" ? (
                          <CCol
                            className={` m-0 mt-1 align-items-center `}
                            style={{
                              maxWidth: "91%",
                              backgroundColor: "white",
                              borderRadius: "15px",
                              padding: "5px 10px",
                              marginBottom: "5px",
                              boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                            }}
                          >
                            <SkeletonTheme
                              baseColor="#d4e8ffb8"
                              highlightColor="#73a9ff"
                            >
                              <h4>
                                <Skeleton count={1} width={"50%"} />
                              </h4>
                              <p>
                                <Skeleton height={12} width={"75%"} count={2} />
                              </p>
                            </SkeletonTheme>
                          </CCol>
                        ) : el?.explanation
                            ?.toLowerCase()
                            .includes("answer not found") ||
                          el.explanation
                            .toLowerCase()
                            .includes(
                              "rephrase the question or data not available"
                            ) ? (
                          <CCol
                            name={i}
                            style={{ maxWidth: "90%", position: "relative" }}
                          >
                            <div
                              style={{ width: "match-content" }}
                              className={`chat-message other align-items-center font-md ms-0`}
                            >
                              Kindly rephrase query or ask new questions.
                            </div>
                          </CCol>
                        ) : (
                          <CCol style={{ maxWidth: "91%" }}>
                            <ChartTypes
                              data={el}
                              queryText={true}
                              // page={"sales"}
                            />
                          </CCol>
                        )}
                      </CCol>
                    </div>
                  );
                })}
              </>
            )}

            {/* <CForm
              className="pt-3 new-input-form"
              onSubmit={(e) => handlePrompt(e)}
            >
              <CCol
                className="d-flex align-items-center inputfocus m-auto p-2"
                style={{
                  borderRadius: "5px",
                  height: "35px",
                  backgroundColor: "#EEF4F8",
                  width: "80%",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "#EEF4F8",
                    boxShadow: "none",
                    color: "#086EB5",
                    height: "30px",
                  }}
                  onChange={(e) => setInputVal(e.target.value)}
                  disabled={loading}
                  value={inputVal}
                  placeholder="Ask a question"
                />
                <CButton
                  type="submit"
                  disabled={inputVal == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover"
                      size="lg"
                      style={{ color: "#086EB5" }}
                    />
                  )}
                </CButton>
              </CCol>
            </CForm> */}
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => handlePrompt(e)}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setGeneratedData([]);
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder={"Ask a question"}
                  disabled={loading}
                />
                <CButton
                  type="submit"
                  disabled={inputVal == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </CCol>
      </CRow>
      {/* {!visible ? (
        <CButton
          color="info"
          className="p-0 position-fixed"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "60px",
            left: 10,
            zIndex: 12,
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      ) : null} */}
    </div>
  );
};

export default Prompt;
