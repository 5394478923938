import React, { useState } from "react";
import BarChart from "./BarChart";
import DownloadComponent from "./DownloadComponent";
import { FcInfo } from "react-icons/fc";
import OutputTable from "./OutputTable";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  CButton,
  CCard,
  CCol,
  CTooltip,
  CAlert,
  CCardText,
  CCollapse,
  CCardBody,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilChart, cilChartLine } from "@coreui/icons";
import { CodeViewWhite } from "./CodeViewWhite";

const ChartTypes = (props) => {
  const [chartVis, setChartVis] = useState(true);
  const [chartType, setChartType] = useState("bar");
  const [disableChart, setDisableChart] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div style={{ paddingLeft: "10px" }}>
      <div>
        <CAlert
          style={{
            backgroundColor: "#e5ffef",
            border: "1px solid #e5ffef",
          }}
          className="p-0"
        >
          <CCardBody className="p-2" style={{ fontSize: 12 }}>
            <FcInfo size={"1em"} /> {props.data.explanation}
          </CCardBody>
        </CAlert>
      </div>

      {props.queryText && (
        <div>
          <CodeViewWhite codeText={`${props.data?.query_text}`} />
        </div>
      )}

      <div
        style={{
          position: "relative",
          background: "white",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
        className="m-0 border rounded-2 p-1"
      >
        <div className="d-flex justify-content-between p-0">
          <CCard
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: "none",
              height: "100%",
              background: "white",
            }}
            className="p-1 d-flex justify-content-left"
          ></CCard>
          <CCard
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: "none",
              height: "100%",
            }}
            className="p-1"
          >
            <DownloadComponent data={props.data.values} />
          </CCard>
        </div>

        <CCard
          style={{
            width: "100%",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            border: "none",
            maxHeight: 270,
            overflow: "scroll",
            background: "#ffffff",
            padding: "1px 3px 1px 3px",
          }}
        >
          <OutputTable data={props.data.values} />
        </CCard>
      </div>
      {console.log("props.data.values", props.data.values)}
      {chartVis ? (
        <div style={{ background: "white" }} className="rounded">
          <BarChart
            chartType={chartType}
            data={props.data.values}
            setDisableChart={setDisableChart}
            disableChart={disableChart}
            setChartVis={setChartVis}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChartTypes;
