import React, { useState } from "react";
import {
  CSidebar,
  CButton,
  CTooltip,
  CCol,
  CFormInput,
  CImage,
  CAlert,
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react";
import { PiChatsLight } from "react-icons/pi";
import { useNavigate } from "react-router";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
  cilWarning,
  cilInfo,
  cilX,
} from "@coreui/icons";
import { CiImageOn } from "react-icons/ci";
import ArcDia from "../assets/images/image-arch-dia.png";
const ImageDescriptionSidebar = ({
  generatedData,
  setSidebarVis,
  sidebarVis,
  handleFileSelect,
  base64ImageFile,
  setGeneratedData,
  setBase64ImageFile,
}) => {
  const handleFileChange = (event) => {
    // event.preventDefault();
    setVis(false);
    const selectedFile = event.target.files;
    if (event.target.files[0]?.size < 5242880) {
      handleFileSelect(selectedFile);
    } else {
      event.target.value = "";
      setVis(true);
    }
  };
  const [vis, setVis] = useState(false);
  const [vis2, setVis2] = useState(false);

  return (
    <div
      // className="image-sidebar"
      visible={sidebarVis}
      position="fixed"
      placement={"start"}
      size="lg"
      style={{ zIndex: 12 }}
    >
      {/* <div style={{ height: "50px" }}></div> */}
      <div
        className="d-flex justify-content-between gap-2"
        style={{ top: "10px" }}
      >
        <div class="file-input w-100 p-1">
          <input
            onChange={(e) => {
              e.preventDefault();
              setGeneratedData([]);
              if (e.target.files.length > 0) {
                handleFileChange(e);
              }
            }}
            type="file"
            name="file-input"
            id="file-input"
            className="file-input__input"
            accept="image/png, image/gif, image/jpeg"
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <label class="file-input__label logout-button" for="file-input">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="upload"
              class="svg-inline--fa fa-upload fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
              ></path>
            </svg>
            <span>Upload file</span>
          </label>
        </div>
        {/* <CButton
          color="info"
          className="p-0 mb-2 pop-button"
          style={{
            width: "35px",
          }}
          variant="outline"
          onClick={() => {
            setSidebarVis(!sidebarVis);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton> */}
      </div>
      <CAlert
        color="danger"
        className="p-1 d-flex justify-content-center align-items-center gap-2 font-md"
        visible={vis}
      >
        <CIcon icon={cilWarning} /> File size too large!
      </CAlert>
      {base64ImageFile.length > 0 ? (
        <div
          className="position-relative border px-1 my-2"
          style={{ maxHeight: "300px" }}
        >
          <CIcon
            icon={cilX}
            className="position-absolute hover text-dark"
            style={{
              right: "0px",
              top: "2px",
              zIndex: 10,
              height: "20px",
              width: "20px",
            }}
            onClick={() => {
              setBase64ImageFile("");
              setGeneratedData([]);
            }}
          />
          <CImage
            style={{ maxHeight: "300px" }}
            src={`data:image/png;base64,${base64ImageFile}`}
            width={300}
          />
        </div>
      ) : (
        <></>
        
      )}

      {/* <CCol style={{ height: "70vh", overflow: "scroll" }}>
        {generatedData?.map((el, i) => {
          return (
            <CTooltip className="font-sm" content={el.question}>
              <CCol
                key={i}
                style={{
                  color: "#0B57D0",
                }}
                className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 hover"
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <CIcon
                    icon={cilCommentSquare}
                    size="sm"
                    width={12}
                    style={{ marginRight: "10px" }}
                  />
                  {el.question}
                </div>
              </CCol>
            </CTooltip>
          );
        })}
      </CCol> */}
      {/* <CButton
        onClick={() => {
          setVis2(true);
        }}
        className="new-chat"
        variant="outline"
        shape="rounded-pill"
      >
        Architecture Diagram
      </CButton>
      <CModal
        visible={vis2}
        size="xl"
        onClose={() => {
          setVis2(false);
        }}
      >
        <CModalHeader>Architecture Diagram</CModalHeader>
        <CModalBody className="d-flex">
          <CImage
            className=""
            style={{ width: "80%", margin: "auto" }}
            src={ArcDia}
          />
        </CModalBody>
      </CModal> */}
    </div>
  );
};

export default ImageDescriptionSidebar;
