import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilSend } from "@coreui/icons";
import { FcSearch, FcBullish, FcAcceptDatabase } from "react-icons/fc";
import "../../styles/prompt.css";
import axios from "axios";
import ChartTypes from "../../components/ChartTypes";

const MaricoQuestion = ({ promptList, setPromptList, reuse, visible, setVisible }) => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const messageContainerRef = useRef(null);
  console.log(
    "The description for P code P214D is 0.4 g/s\\n650 deg_ ".split("\n")
  );
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);
  const handlePrompt = async (e) => {
    e.preventDefault();
    console.log("handlePrompt")

    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    setLoading(true);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-q-marico-sales-insights",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        question: inputPrompt.trim(),
      },
    };
    // console.log("sales request",config)


    try {
      const response = await axios(config);
      console.log("sales respo",response)
      console.log("promptList",promptList)

      // let response = true;
      if (response != undefined) {
        setLoading(false);
        setInputVal("");
        setPromptList([...promptList, inputPrompt]);
        setGeneratedData([...generatedData, response.data]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setInputVal("");
      setPromptList([...promptList, inputPrompt]);
      setGeneratedData([...generatedData, []]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (promptList.length == 0) {
      setGeneratedData([]);
    }
  }, [promptList]);
  useEffect(() => {
    setInputVal(reuse);
  }, [reuse]);
  const ChatMessage = ({ text, isUser }) => {
    let name = JSON.parse(
      sessionStorage.getItem("authentication")
    )[0].toUpperCase();
    return (
      <>
        {isUser ? (
          <div
            className={`chat-message user mt-2 align-items-center`}
            style={{ marginLeft: "20px" }}
          >
            {text}
          </div>
        ) : (
          <div className={`chat-message other mt-2 align-items-center`}>
            {text} &nbsp;
          </div>
        )}
      </>
    );
  };
  return (
    <div
      ref={messageContainerRef}
      style={{
        backgroundColor: "#fffff",
        paddingLeft: visible ? "250px" : "100px",
        paddingRight: visible ? "0px" : "100px",
        marginTop: "10px",
        overflowY: "auto",
        height: "90vh",
        paddingBottom: "80px",
      }}
      className="chat-background"
    >
      {!visible ? (
        <CButton
          color="info"
          className="p-0 position-fixed"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "60px",
            left: 10,
            zIndex: 12,
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      ) : null}
      {generatedData.length == 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1,1fr)",
            gap: 25,
            margin: "auto",
            width: "80%",
          }}
          className="mt-3"
        >
          <CCard
            style={{
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",

              zIndex: 1,
              animationDuration: "1000ms",
            }}
            className="p-2 px-3 left-to-right"
          >
            <b className="d-flex gap-2 align-items-center">
              <FcSearch size={"2rem"} /> Ask a Question
            </b>
            <div className="mt-1 " style={{ color: "grey" }}>
              Ask a question in natural language on sales database
            </div>
          </CCard>
          <CCard
            style={{
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",

              zIndex: 1,
              animationDuration: "1000ms",
            }}
            className="p-2 px-3 right-to-left"
          >
            {" "}
            <b className="d-flex gap-2 align-items-center">
              <FcAcceptDatabase size={"2rem"} /> Get Structured Data
            </b>
            <div className="mt-1 " style={{ color: "grey" }}>
              Returns results from Big Query database according to your question
            </div>
          </CCard>
          <CCard
            style={{
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",

              zIndex: 1,
              animationDuration: "1000ms",
            }}
            className="p-2 px-3 left-to-right"
          >
            <b className="d-flex gap-2 align-items-center">
              <FcBullish size={"2rem"} /> View Dashboards
            </b>
            <div className="mt-1 " style={{ color: "grey" }}>
              Visualize your data with easy to understand tables and graphs with
              option to add customised graphs as per your need.
            </div>
          </CCard>
        </div>
      ) : (
        <>
          {generatedData?.map((el, i) => {
            return (
              <div style={{ paddingRight: "20px" }}>
                <ChatMessage key={i} text={promptList[i]} isUser={true} />
                <div name={i}>
                    <ChatMessage
                      key={i}
                      text={el.get_explain}
                      isUser={false}
                    />
                  </div>
                {el?.values.length == 0 ? (
                  <div name={i}>
                    <ChatMessage
                      key={i}
                      text={"No relevant data found."}
                      isUser={false}
                    />
                  </div>
                ) : (
                  <ChartTypes
                    data={el}
                    // page={"sales"}
                  />
                )}
              </div>
            );
          })}
        </>
      )}

      <CForm
        className="pt-3"
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
          background: "rgba(255,255,255,0.8)",
          paddingBottom: "20px",
          zIndex: 11,
          paddingLeft: visible ? 250 : 0,
          left: 0,
        }}
        onSubmit={(e) => handlePrompt(e)}
      >
        <CCol
          className="d-flex align-items-center w-75 inputfocus m-auto "
          style={{
            borderRadius: "5px",
            height: "45px",
            backgroundColor: "#EEF4F8",
          }}
        >
          <CFormInput
            className="border-0 inputfocus form-control"
            style={{
              backgroundColor: "#EEF4F8",
              boxShadow: "none",
              color: "#086EB5",
              height: "40px",
            }}
            onChange={(e) => setInputVal(e.target.value)}
            value={inputVal}
            placeholder="Ask a question"
          />
          <CButton
            type="submit"
            disabled={inputVal == "" || loading}
            variant="ghost"
            size="sm"
            className="p-0 border-0 hover"
          >
            {loading ? (
              <CSpinner
                size="sm"
                className="m-2 "
                style={{ color: "#086EB5" }}
              />
            ) : (
              <CIcon
                icon={cilSend}
                className="m-2 hover"
                size="lg"
                style={{ color: "#086EB5" }}
              />
            )}
          </CButton>
        </CCol>
      </CForm>
    </div>
  );
};

export default MaricoQuestion;
