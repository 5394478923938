import React, { useState, useRef, useEffect } from "react";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CAvatar,
  CCarousel,
  CCarouselItem,
  CImage,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CFormTextarea,
  CProgress,
  CRow,
  CTooltip,
  CModal,
  CModalBody,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilWarning, cilSend } from "@coreui/icons";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import { Navbar } from "../../components/Navbar";
import { ChatMessage } from "../../components/ChatMessage";
import aiImage from "../../assets/images/OIG.png";
import EcommerceSidebar from "../../components/EcommerceSidebar";
import database from "../../database/db.json";
import assistant from "../../assets/icons/assistant.png";
import { IoCloseCircleSharp } from "react-icons/io5";
import { HiMiniSquare2Stack } from "react-icons/hi2";
import { RiChatSmile3Fill } from "react-icons/ri";
const Ecommerce = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);
  const [height, setHeight] = useState(30);
  const [selectedCategory, setSelectedCategory] = useState();
  const [product, setProduct] = useState("");
  const [selectedImg, setSelectedImg] = useState();
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handlePrompt(event);
    }
    if (event.key === "Enter" && event.shiftKey) {
      adjustHeight(event.target);
    }
  };
  const adjustHeight = (element) => {
    const maxHeight = 90;
    const lineHeight = 16; // Adjust according to your textarea's line-height
    const lines = element.value.split("\n");
    const rowsFromLines = Math.max(lines.length, 1); // Ensure at least one row

    // If there are no newline characters, but text exists, consider it as a single line
    const singleLineLength = lines.reduce((acc, line) => acc + line.length, 0);
    const rowsFromLength = Math.ceil(singleLineLength / element.cols); // Determine rows based on text length

    const rows = Math.max(rowsFromLines, rowsFromLength);

    const newHeight = Math.min(rows * lineHeight, maxHeight);
    setHeight(newHeight);
  };
  const handleChange = (event) => {
    setInputVal(event.target.value);
    adjustHeight(event.target);
  };
  const handlePrompt = async (e) => {
    // e.preventDefault();
    let inputPrompt = inputVal;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setSelectedImg("");
      // setLoading(true);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          images:
            database[product.category.split(".")[0]][
              product.category.split(".")[1]
            ],
          summary: `Here are some latest trends in ${
            product.category.split(".")[1]
          }`,
        },
      ]);
      // getUnstructured(inputPrompt);
    }
  };
  // console.log(product);
  const getUnstructured = async (inputPrompt) => {
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-questions-abg-policy",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log(response);
        setLoading(false);
        setGeneratedData([
          ...generatedData,
          {
            question: inputPrompt,
            summary: response.data.summary,
            img_search_data: response.data.img_search_data,
            tuning_data: response.data.input_text,
            results: response.data.results,
          },
        ]);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "Answer Not Found",
          img_search_data: [],
          tuning_data: "",
          results: "",
        },
      ]);
    }
  };
  return (
    <div
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        background: "white",
      }}
    >
      <Navbar />
      <EcommerceSidebar
        generatedData={generatedData}
        setGeneratedData={setGeneratedData}
        sidebarVis={visible}
        setSidebarVis={setVisible}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
      />
      <div
        style={{
          backgroundColor: "#fffff",
          paddingLeft: "210px",
          paddingRight: "0px",
          marginTop: "10px",
          overflowY: "auto",
          height: "90vh",
          textAlign: "left",
          paddingBottom: "80px",
          position: "relative",
          transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        }}
      >
        <CButton
          style={{
            position: "fixed",
            right: "20px",
            bottom: "20px",
            display: visible ? "none" : "block",
          }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <RiChatSmile3Fill size={"1.8rem"} />
        </CButton>
        <div>
          <div
            style={{
              // height: "35vh",
              margin: "10px",
            }}
          ></div>
          {selectedCategory ? (
            <div>
              <h6
                style={{ margin: "10px" }}
                className="d-flex justify-content-between"
              >
                Trending {selectedCategory.subcateg}{" "}
                <CCloseButton
                  onClick={() => {
                    setSelectedCategory();
                  }}
                />
              </h6>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7,1fr)",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                {database[selectedCategory.category][
                  selectedCategory.subcateg
                ].map((ele) => {
                  return (
                    <div
                      className="border font-md rounded-2 bg-white"
                      style={{
                        boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          position: "absolute",
                          right: "3px",
                          top: "3px",
                        }}
                        className="font-sm hover"
                        onClick={() => {
                          setVisible2(true);
                        }}
                      >
                        4 <HiMiniSquare2Stack />
                      </div>
                      <CImage
                        className="w-100 rounded-top-2"
                        style={{
                          height: "150px",
                          objectFit: "fill",
                        }}
                        src={ele.image}
                      />
                      <div
                        style={{
                          margin: 4,
                          fontSize: 11,
                          color: "gray",
                          fontWeight: 600,
                        }}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div>{ele.name}</div>
                        <CTooltip content="AI Assistant" className="font-sm">
                          <CImage
                            style={{ width: "23px", height: "20px" }}
                            src={assistant}
                            className="hover"
                            onClick={() => {
                              setVisible(!visible);
                              setProduct(ele);
                            }}
                          />
                        </CTooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div
            style={{
              boxShadow: selectedCategory
                ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                : "",
              backgroundColor: selectedCategory ? "white" : "",
            }}
            className="rounded-1 p-1"
          >
            <h6 style={{ margin: "10px" }}>Trending Apparels</h6>
            {/* <marquee behavior="" direction=""> */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7,1fr)",
                gap: "10px",
                margin: "10px",
              }}
              // className="hover"
            >
              {database.trendapp.map((ele) => {
                return (
                  <div
                    className="border font-md rounded-2 bg-white hover"
                    style={{
                      boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    }}
                  >
                    <CImage
                      onClick={() => {
                        setVisible2(!visible2);
                      }}
                      className="w-100 rounded-top-2"
                      style={{
                        height: "150px",
                        objectFit: "fill",
                      }}
                      src={ele.image}
                    />
                    <div
                      style={{
                        margin: 4,
                        fontSize: 11,
                        color: "gray",
                        fontWeight: 600,
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>{ele.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <CModal
        visible={visible2}
        onClose={() => {
          setVisible2(false);
        }}
      >
        <CModalBody></CModalBody>
      </CModal>
      <COffcanvas
        placement="end"
        visible={visible}
        onHide={() => {
          setVisible(false);
          setGeneratedData([]);
        }}
        style={{ width: "50%" }}
      >
        <COffcanvasHeader>
          <COffcanvasTitle className="d-flex gap-2 align-items-center">
            <RiChatSmile3Fill />
            AI Assistant
          </COffcanvasTitle>
          <CCloseButton
            className="text-reset"
            onClick={() => setVisible(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody style={{ paddingBottom: "100px" }}>
          {generatedData.length == 0 ? (
            <div></div>
          ) : (
            <>
              {generatedData?.map((el, i) => {
                return (
                  <div
                    ref={messageContainerRef}
                    style={{ paddingRight: "20px" }}
                    className="mb-3"
                  >
                    <ChatMessage key={i + 1} text={el.question} isUser={true} />
                    {el.summary == "" ? (
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiImage}
                        >
                          {}
                        </CAvatar>
                        <div
                          className={`chat-message other mt-2 align-items-center `}
                          style={{ width: "75%" }}
                        >
                          <SkeletonTheme
                            baseColor="#d9edf9"
                            highlightColor="#2F8FC7"
                          >
                            <h4 style={{ width: "100%" }}>
                              <Skeleton count={1} width={"50%"} />
                            </h4>
                            <p style={{ width: "100%" }}>
                              <Skeleton height={12} width={"75%"} count={2} />
                            </p>
                          </SkeletonTheme>
                        </div>
                      </div>
                    ) : el.summary
                        ?.toLowerCase()
                        .includes(
                          "sorry couldn't find any information regarding that"
                        ) ||
                      el.summary?.toLowerCase().includes("answer not found") ? (
                      <div name={i}>
                        <ChatMessage
                          key={i}
                          text={
                            "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                          }
                          isUser={false}
                          isError={true}
                        />
                      </div>
                    ) : el.summary == "hello" ? (
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiImage}
                        >
                          {}
                        </CAvatar>
                        <div className={`chat-message other mt-2`}>
                          {el.question
                            ?.trim()
                            .toLowerCase()
                            .includes("what can you do for me") ? (
                            <div>
                              I can assist you with queries on Policy Documents.{" "}
                              <p
                                className="m-0 mt-1"
                                style={{ fontWeight: 500 }}
                              >
                                Here are some example questions to get you
                                started:
                              </p>
                              Which are the type of vehicle mentioned in the
                              document?
                              <br />
                              What is the fixed compensation given for hybrid
                              vehicle purchased by JB 5 employee?
                            </div>
                          ) : (
                            <div>
                              Hello! How can I assist you today? <br />
                              <p
                                className="m-0 mt-1"
                                style={{ fontWeight: 500 }}
                              >
                                Here are some example questions to get you
                                started:
                              </p>
                              What is the vehicle operating expenses given for
                              hybrid vehicle purchased by JB 5 employee?
                              <br />
                              What is the effective date of the revised policy?
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{ marginLeft: "10px" }}
                          className="d-flex justify-content-start position-relative gap-2 align-items-start"
                        >
                          <CAvatar
                            style={{
                              width: "35px",
                              height: "35px",
                            }}
                            className="font-md mt-2 border avatar"
                            size="sm"
                            src={aiImage}
                          >
                            {}
                          </CAvatar>
                          <div
                            className={`chat-message other mt-2 align-items-center font-md`}
                            style={{ maxWidth: "85%" }}
                          >
                            {el.summary.split("\n").map((str) => {
                              return <p className="p-0 m-0">{str}</p>;
                            })}
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: "10px" }}
                          className="d-flex justify-content-start position-relative gap-2 align-items-start"
                        >
                          <CAvatar
                            style={{
                              width: "35px",
                              height: "35px",
                              border: "none",
                              boxShadow: "none",
                            }}
                            size="sm"
                          ></CAvatar>
                          <div
                            className={`chat-message other mt-2 align-items-center font-md`}
                            style={{
                              width: "85%",
                              display: "grid",
                              gridTemplateColumns: "repeat(6,70px)",
                              gap: "10px",
                            }}
                          >
                            {el.images.slice(0, 6).map((img) => {
                              return (
                                <CImage
                                  onClick={() => {
                                    setSelectedImg(img.image);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                  className="rounded-2 hover"
                                  src={img.image}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {generatedData.length >= 20 ? (
            <CAlert
              style={{
                bottom: 100,
                position: "fixed",
                marginLeft: visible ? 450 : 300,
                zIndex: 11,
              }}
              color={"warning"}
              className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
            >
              <CIcon icon={cilWarning} size="sm" height={15} width={15} />
              <div>
                You have reached the chat limit of 20 questions. Please create
                New Chat chat to continue asking more questions.
              </div>
            </CAlert>
          ) : null}

          <CForm
            spellCheck={false}
            style={{
              bottom: "0px",
              position: "fixed",
              width: "47%",
              paddingBottom: "10px",
              background: "white",
              backdropFilter: "blur(10px)",
              zIndex: 11,
              paddingTop: "0px",
            }}
            // onSubmit={(e) => handlePromptJoin(e)}
          >
            <div className="d-flex gap-2">
              <div
                className="font-md p-1 rounded-5 border hover m-1"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  width: "max-content",
                  color: "rgba(0, 107, 178, 1)",
                  background: "white",
                }}
                onClick={() => {
                  setInputVal("Can I see more pictures of this product?");
                }}
              >
                Can I see more pictures of this product?
              </div>
              <div
                className="font-md p-1 rounded-5 border hover m-1"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  width: "max-content",
                  color: "rgba(0, 107, 178, 1)",
                  background: "white",
                }}
                onClick={() => {
                  setInputVal("Are there similar items available?");
                }}
              >
                Are there similar items available?
              </div>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "100%",
                padding: "2px",
                borderRadius: "5px",
                paddingTop: "14px",
              }}
              className=" m-auto"
            >
              <CCol
                className="d-flex align-items-end w-100 inputfocus m-auto "
                style={{
                  backgroundColor: "white",
                  border: "1px solid #9b9b9b",
                  borderRadius: "8px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                {selectedImg ? (
                  <div style={{ position: "relative" }}>
                    <IoCloseCircleSharp
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                      }}
                      className="hover"
                      size={"1rem"}
                      onClick={() => {
                        setSelectedImg();
                      }}
                    />
                    <CImage
                      src={selectedImg}
                      style={{
                        height: "70px",
                        width: "50px",
                        objectFit: "contain",
                      }}
                      className="m-2"
                    />
                  </div>
                ) : null}
                <CFormTextarea
                  className="border-0 inputfocus form-control ps-3"
                  value={inputVal}
                  disabled={loading}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  style={{
                    height: `${height}px`,
                    resize: "none",
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: "8px",
                    fontSize: "12px",
                    marginTop: ".15rem",
                    marginBottom: ".15rem",
                  }}
                  placeholder="Ask your query"
                />
                <CButton
                  // type="submit"
                  onClick={() => {
                    handlePrompt();
                  }}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                  disabled={
                    inputVal == "" || loading || generatedData.length >= 20
                  }
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </COffcanvasBody>
      </COffcanvas>
    </div>
  );
};

export default Ecommerce;
