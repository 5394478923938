import {
  CTable,
  CTableHead,
  CTableDataCell,
  CTableHeaderCell,
  CTableBody,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";

const OutputTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  const [column, setColumn] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      setColumn(Object.keys(data[0]));
      let tempData = [];
      data.forEach((el) => {
        tempData.push(Object.values(el));
      });
      setTableData(tempData);
    }
  }, [data]);
  const isInt = (n) => {
    return parseInt(n) === n;
  };
  return (
    <CTable bordered small hover responsive className="scrollbar-jsw">
      <CTableHead
        style={{
          position: "sticky",
          top: -1,
          fontSize: "11px",
          backgroundColor: "#F2F2F3",
          fontWeight: "500",
        }}
      >
        <CTableRow style={{ backgroundColor: "#F2F2F3" }}>
          {column?.map((el) => {
            return (
              <CTableHeaderCell
                style={{ backgroundColor: "#F2F2F3", fontWeight: "500" }}
              >
                {el}
              </CTableHeaderCell>
            );
          })}
        </CTableRow>
      </CTableHead>
      <CTableBody style={{ fontSize: "10px" }} className="scrollbar-jsw">
        {tableData?.map((el) => {
          return (
            <CTableRow>
              {el.map((co, index) => {
                return (
                  <CTableDataCell style={{ whiteSpace: "nowrap" }}>
                    {co
                      ? !isNaN(co)
                        ? isInt(co)
                          ? column[index].toLowerCase() == "year"
                            ? co
                            : co.toLocaleString()
                          : co.toFixed(2).toLocaleString()
                        : co
                      : co}
                  </CTableDataCell>
                );
              })}
            </CTableRow>
          );
        })}
      </CTableBody>
    </CTable>
  );
};

export default OutputTable;
