import "./App.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainRoutes from "./routes";
import "./styles/login.css";
import "./styles/prompt.css";
import "./styles/scrollbar.css";
import "./styles/sidebar.css";
import "./styles/chatbubble.css";
import "./styles/homepage.css";
import "./styles/urlprompt.css";
import "./styles/chat.css";
import "./styles/loader.css";
import "./styles/iteneraryInsight.css";
import "./styles/ecommerce.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-multi-carousel/lib/styles.css";
import "./pages/SBI/App.css";
import "./pages/SBI/styles/login.css";
import "./pages/SBI/styles/prompt.css";
import "./pages/SBI/styles/scrollbar.css";
import "./pages/SBI/styles/sidebar.css";
import "./pages/SBI/styles/chatbubble.css";
import "./pages/SBI/styles/loader.css";
import "./pages/SBI/styles/homepage.css";
function App() {
  return (
    <div style={{ height: "100vh" }}>
      {/* <Navbar /> */}

      <MainRoutes />
    </div>
  );
}

export default App;
