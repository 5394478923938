import {
  CTable,
  CTableHead,
  CTableDataCell,
  CTableHeaderCell,
  CTableBody,
  CTableRow,
  CCard,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";

const OutputTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  const [column, setColumn] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      setColumn(Object.keys(data[0]));
      let tempData = [];
      data.forEach((el) => {
        tempData.push(Object.values(el));
      });
      setTableData(tempData);
    }
    console.log(data);
  }, [data]);
  const isInt = (n) => {
    return parseInt(n) === n;
  };
  return (
    data && (
      <CCard
        className="border-0"
        style={{ maxHeight: "300px", maxWidth: "70vw" }}
      >
        <CTable bordered small responsive>
          <CTableHead
            style={{
              position: "sticky",
              top: -1,
              fontSize: "11px",
              backgroundColor: "#F2F2F3",
              fontWeight: "500",
            }}
          >
            <CTableRow style={{ backgroundColor: "#F2F2F3" }}>
              {column?.map((el) => {
                return (
                  <CTableHeaderCell
                    style={{
                      backgroundColor: "#F2F2F3",
                      fontWeight: "500",
                      minWidth:
                        el == "Complaint Description" ? "300px" : "100px",
                    }}
                  >
                    {el}
                  </CTableHeaderCell>
                );
              })}
            </CTableRow>
          </CTableHead>
          <CTableBody style={{ fontSize: "10px" }}>
            {tableData?.map((el) => {
              return (
                <CTableRow>
                  {el.map((co) => {
                    return (
                      <CTooltip
                        className="font-sm"
                        content={
                          co ? (!isNaN(co) ? co.toLocaleString() : co) : co
                        }
                      >
                        <CTableDataCell>
                          {co
                            ? !isNaN(co)
                              ? co.toLocaleString()
                              : co.length > 40
                              ? co.slice(0, 40)
                              : co
                            : co}
                        </CTableDataCell>
                      </CTooltip>
                    );
                  })}
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CCard>
    )
  );
};

export default OutputTable;
