import { CAvatar } from "@coreui/react";
import {
  AiOutlineExclamationCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import aiImage from "../assets/images/OIG.png";
import userImage from "../assets/images/user.png";

export const QnAMessage = ({ text, isUser, isError, subChat }) => {
  let name = JSON.parse(
    sessionStorage.getItem("authentication")
  )[0].toUpperCase();
  return (
    <>
      {isUser ? (
        <div
          className={`chat-message align-items-center d-flex gap-2 justify-content-end`}
        >
          {text}
        </div>
      ) : isError ? (
        <div
          className={`chat-message error align-items-center d-flex gap-2`}
          style={{ marginLeft: "30px" }}
        >
          <AiOutlineExclamationCircle size={"1em"} />
          {text}
        </div>
      ) : (
        <div
          className={`chat-message other align-items-center d-flex gap-2`}
          style={{
            marginLeft: "0px",
            background:
              "linear-gradient(135deg, rgba(238,244,248,1) 0%,rgba(242,242,249,1) 100%)",
          }}
        >
          <div>{text}</div>
        </div>
      )}
    </>
  );
};
