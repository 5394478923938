import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CImage,
  CCardImage,
  CRow,
  CCol,
  CCardText,
} from "@coreui/react";

const TravelPlacesCard = ({
  placeName,
  placeImage,
  placeDescription,
  placeInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <CCard className="mb-1 p-1" style={{ overflow: "hidden" }}>
      <CRow className="g-0">
        <CCol md={4}>
          {placeImage != undefined ? (
            <CCardImage
              style={{ maxHeight: "90px", minWidth: "70px" }}
              src={placeImage}
            />
          ) : (
            <CCardImage
              style={{ maxHeight: "100px" }}
              src={
                "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.png"
              }
            />
          )}
        </CCol>
        <CCol md={8}>
          <CCardBody className="p-1">
            <CCardTitle style={{ fontSize: "14px" }}>{placeName}</CCardTitle>
            <CCardText className="font-md">{placeDescription}</CCardText>
          </CCardBody>
        </CCol>
      </CRow>
    </CCard>
  );
};

export default TravelPlacesCard;
