import {
  CSidebar,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CImage,
  CModal,
  CModalHeader,
  CCloseButton,
  CModalBody,
  CCardTitle,
  CCol,
  CTooltip,
} from "@coreui/react";
import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilPowerStandby,
  cilZoom,
  cilZoomIn,
} from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import { TbTargetArrow } from "react-icons/tb";
import { CiCircleQuestion } from "react-icons/ci";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Dark.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import ArcDia from "../assets/images/image-arch-dia.png";
import { BsZoomIn } from "react-icons/bs";
const PlainSidebar = ({
  generatedData,
  setGeneratedData,
  setSidebarVis,
  sidebarVis,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  return (
    <CSidebar className="new-sidebar">
      <div
        className="d-flex align-items-center justify-content-between p-2 mb-1"
        style={{
          boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
          clipPath: "inset(0px 0px -15px 0px)",
        }}
      >
        <div className="d-flex justify-content-center gap-3">
          <CCardTitle className="title-sidebar">
            Group Insurance Document Q&A
          </CCardTitle>

          {/* <CImage width={90} src={AtgLogo} />
          <CImage width={90} src={googleLogo} /> */}
        </div>
        {/* <div
          className="rolling"
          onClick={() => {
            navigate("/chat-app");
          }}
        >
          <CIcon icon={cilPowerStandby} className="hover me-1" size="lg" />
          <span className="font-lg" style={{ fontWeight: 600 }}>
            Log Out
          </span>
        </div> */}
      </div>

      <div style={{ overflow: "scroll" }} className="px-0">
        <CAccordion alwaysOpen activeItemKey={1} flush>
          <CAccordionItem className="p-0" itemKey={1}>
            <CAccordionHeader
              style={{ color: "#0b2e50" }}
              className="font-md p-0"
            >
              Architecture Diagram
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column align-items-end">
              <BsZoomIn
                onClick={() => {
                  setVisible(true);
                }}
                className="hover logout-icon"
                size={"1rem"}
              />
              <CImage
                onDoubleClick={() => {
                  setVisible(true);
                }}
                className="w-100 hover"
                src={ArcDia}
              />
              <CModal
                visible={visible}
                size="xl"
                onClose={() => {
                  setVisible(false);
                }}
              >
                <CModalHeader>Architecture Diagram</CModalHeader>
                <CModalBody>
                  <CImage className="w-100" src={ArcDia} />
                </CModalBody>
              </CModal>
            </CAccordionBody>
          </CAccordionItem>
          {/* <CAccordionItem className="p-0" itemKey={2}>
            <CAccordionHeader
              style={{ color: "#0b2e50" }}
              className="font-md p-0"
            >
              Steps To Use
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-2 p-2">
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Initiate the Chat</b>
                  <div className="text-medium-emphasis">
                    Once in the Deceased Management section, you'll likely find
                    an option to start a chat or interact with the chatbot.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Introduction</b>
                  <div className="text-medium-emphasis">
                    The chatbot will greet you and provide a brief overview of
                    its capabilities. Follow any initial instructions or
                    prompts.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Provide Necessary Information</b>
                  <div className="text-medium-emphasis">
                    The chatbot may request basic information about the deceased
                    account holder, such as their name, account details, and
                    relationship to you.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Documentation Guidance</b>{" "}
                  <div className="text-medium-emphasis">
                    The chatbot will guide you through the required
                    documentation. This may include death certificates, legal
                    documents, and any specific forms needed for processing.
                  </div>
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem> */}
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>Asked Questions</CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-2 p-2">
              <CCol style={{ height: "70vh", overflow: "scroll" }}>
                {generatedData?.map((el, i) => {
                  return (
                    <CTooltip className="font-sm" content={el.question}>
                      <CCol
                        key={i}
                        style={{
                          color: "#0B57D0",
                        }}
                        // className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 zoom hover"
                      >
                        <div
                        // style={{
                        //   whiteSpace: "nowrap",
                        //   overflow: "hidden",
                        //   textOverflow: "ellipsis",
                        // }}
                        >
                          {/* <CIcon
                            icon={cilCommentSquare}
                            size="sm"
                            width={12}
                            style={{ marginRight: "10px" }}
                          /> */}
                          {/* {el.question} */}
                          <div className="d-flex gap-2 mb-1 ">
                            <CiCircleQuestion
                              size={"2rem"}
                              style={{ color: "#64be64" }}
                            />{" "}
                            <div
                              style={{ width: "90%" }}
                              className="text-medium-emphasis d-flex align-items-center"
                            >
                              {el.question}
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CTooltip>
                  );
                })}
              </CCol>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
    </CSidebar>
  );
};

export default PlainSidebar;

// import { CSidebar, CButton, CTooltip, CCol } from "@coreui/react";
// import { PiChatsLight } from "react-icons/pi";
// import { useNavigate } from "react-router";
// import CIcon from "@coreui/icons-react";
// import {
//   cilCommentSquare,
//   cilAccountLogout,
//   cilHamburgerMenu,
// } from "@coreui/icons";
// const PlainSidebar = ({
//   generatedData,
//   setGeneratedData,
//   setSidebarVis,
//   sidebarVis,
// }) => {
//   return (
//     <CSidebar
//       className="guide-sidebar"
//       visible={sidebarVis}
//       position="fixed"
//       placement={"start"}
//       style={{ zIndex: 12 }}
//     >
//       <div style={{ height: "50px" }}></div>
//       <div
//         className="d-flex justify-content-between gap-2"
//         style={{ top: "10px" }}
//       >
//         <CButton
//           variant="outline"
//           className="sticky logout-button w-100 mb-2 font-md pop-button"
//           shape="rounded-pill"
//           style={{ width: "80%" }}
//           disabled={generatedData.length < 1}
//           onClick={() => {
//             setGeneratedData([]);
//           }}
//         >
//           <PiChatsLight size={"1.5em"} /> Clear Chat {generatedData.length}/20
//         </CButton>
//         <CButton
//           color="info"
//           className="p-0 mb-2 pop-button"
//           style={{
//             width: "35px",
//           }}
//           variant="outline"
//           onClick={() => {
//             setSidebarVis(!sidebarVis);
//           }}
//         >
//           <CIcon icon={cilHamburgerMenu} className="text-dark" />
//         </CButton>
//       </div>

//       <CCol style={{ height: "70vh", overflow: "scroll" }}>
//         {generatedData?.map((el, i) => {
//           return (
//             <CTooltip className="font-sm" content={el.question}>
//               <CCol
//                 key={i}
//                 style={{
//                   color: "#0B57D0",
//                 }}
//                 className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 zoom hover"
//               >
//                 <div
//                   style={{
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                   }}
//                 >
//                   <CIcon
//                     icon={cilCommentSquare}
//                     size="sm"
//                     width={12}
//                     style={{ marginRight: "10px" }}
//                   />
//                   {el.question}
//                 </div>
//               </CCol>
//             </CTooltip>
//           );
//         })}
//       </CCol>
//     </CSidebar>
//   );
// };

// export default PlainSidebar;
