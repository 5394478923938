import React, { useState, useRef, useEffect } from "react";
// import { Navbar } from "../components/Navbar";
import "../styles/SliderComponent.css";

import {
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CRow,
  CCardBody,
  CCardTitle,
  CCardSubtitle,
  CFormSelect,
  CCardHeader,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  COffcanvasBody,
  CCloseButton,
  CLink,
  CSidebar,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CAccordion,
  CImage,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react";
import axios from "axios";
import WaveSurfer from "wavesurfer.js";
import { IoChatbubbles } from "react-icons/io5";
import ChatConversation from "./ChatConversation";
import Sidebar from "../components/Sidebar";
import SliderComponent from "./SliderComponent";
import Chat from "../components/Chat";
import OutputTable from "../components/OutputTable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Navbar } from "../../../../components/Navbar";
import CommonSidebar from "../../../../components/CommonSidebar";
import { BsZoomIn } from "react-icons/bs";
import aiImage_icon from "../../../../assets/images/ai_image.png";
import {
  FcAddImage,
  FcAssistant,
  FcAudioFile,
  FcDataSheet,
  FcUpload,
} from "react-icons/fc";
import Sales from "../../../../assets/temp/Sales.wav";
import us_audio_1 from "../../../../assets/temp/us_auidio_1.wav";
import us_audio_2 from "../../../../assets/temp/us_audio_2.wav";
import arcDiagram from "../../../../assets/images/sci.png";
const Prompt = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const waveref = useRef(null);
  const wavesurferRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [conversation, setConversation] = useState([]);
  const processedTimecodesRef = useRef(new Set());
  const processedTimecodesRef2 = useRef(new Set());
  const processedTimecodesRef3 = useRef(new Set());
  const processedTimecodesRef4 = useRef(new Set());
  const processedTimecodesRef5 = useRef(new Set());
  const processedTimecodesRef6 = useRef(new Set());
  const [audioFiles, setAudioFiles] = useState();
  const [audioUrl, setAudioUrl] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [selct, setSelct] = useState("");
  const [intent, setIntent] = useState([]);
  const [info, setInfo] = useState([]);
  const [intentConvo, setIntentConvo] = useState([]);
  const [infoConvo, setInfoConvo] = useState([]);
  const [generatedData, setGeneratedData] = useState([]);
  const [delay, setDelay] = useState(7);
  const [currentTimestamp, setCurrentTimeStamp] = useState(0);
  const [count, setCount] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [additionalInfoConvo, setAdditionalInfoConvo] = useState([]);
  const [summary, setSummary] = useState();
  const [showSummary, setShowSummary] = useState(false);
  const [structured, setStructured] = useState([]);
  const [structuredConvo, setStructuredConvo] = useState([]);
  const [structuredQuestion, setStructuredQuestion] = useState([]);
  const [structuredConvoQuestion, setStructuredConvoQuestion] = useState([]);
  const [vis, setVis] = useState(false);
  const region = JSON.parse(sessionStorage.getItem("region"));
  // let region = "IND"
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  const audioMap = {
    "Sales.wav": Sales,
    "us_audio_1.wav": us_audio_1,
    "us_audio_2.wav": us_audio_2,
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  useEffect(() => {
    setDelay((prev) => {
      return prev + 4 + count;
    });
    setCount(count + 1);
  }, [conversation]);
  const convertTimecodeToSeconds = (timecode) => {
    const parts = timecode.split(":").map(Number);
    //added delay of 10 sec
    return parts[0] * 3600 + parts[1] * 60 + parts[2] + delay;
  };
  useEffect(() => {
    try {
      if (audioUrl?.length > 0) {
        setLoading2(true);
        wavesurferRef.current = WaveSurfer.create({
          container: waveref.current,
          height: 40,
          normalize: true,
          waveColor: "#9a9b9c",
          progressColor: "#2074d5",
          backend: "MediaElement",
          xhr: {
            withCredentials: false,
          },
          interact: false,
        });
        wavesurferRef.current.load(audioUrl);
        setLoading2(false);
        wavesurferRef.current.on("click", () => {
          wavesurferRef.current.playPause();
        });
        wavesurferRef.current.on("ready", () => {
          // wavesurferRef.current.playPause();
          console.log("ready");
        });
        wavesurferRef.current.on("finish", () => {
          console.log("Audio playback has finished");

          setShowSummary(true);
          // You can add any additional logic you want to trigger on audio end here
        });
        return () => {
          if (wavesurferRef.current) {
            wavesurferRef.current.destroy();
          }
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [audioUrl]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  useEffect(() => {
    try {
      if (wavesurferRef.current) {
        wavesurferRef.current.on("audioprocess", () => {
          const currentTime = wavesurferRef.current.getCurrentTime();
          setCurrentTimeStamp(currentTime);

          generatedData.forEach((item) => {
            const itemTimeInSeconds = convertTimecodeToSeconds(item.Timestamp);
            // console.log("no convo");
            if (
              currentTime >= itemTimeInSeconds &&
              !processedTimecodesRef.current.has(item.Timestamp)
            ) {
              console.log("convo");
              setConversation((prev) => [...prev, item]);
              processedTimecodesRef.current.add(item.Timestamp);
            }
          });
          intent.forEach((item) => {
            const itemTimeInSeconds = convertTimecodeToSeconds(item.Timestamp);
            if (
              currentTime >= itemTimeInSeconds &&
              !processedTimecodesRef2.current.has(item.Timestamp)
            ) {
              setIntentConvo(item);
              setTimeout(() => {
                setIntentConvo();
              }, 4000);
              processedTimecodesRef2.current.add(item.Timestamp);
            }
          });

          if (info?.length != 0) {
            info.forEach((item) => {
              const itemTimeInSeconds = convertTimecodeToSeconds(
                item.timestamp
              );
              if (
                currentTime >= itemTimeInSeconds &&
                !processedTimecodesRef3.current.has(item.timestamp)
              ) {
                setInfoConvo((prev) => [item, ...prev]);
                processedTimecodesRef3.current.add(item.timestamp);
              }
            });
          }
          if (additionalInfo?.length != 0) {
            additionalInfo.forEach((item) => {
              const itemTimeInSeconds = convertTimecodeToSeconds(
                item.Timestamp
              );
              if (
                currentTime >= itemTimeInSeconds &&
                !processedTimecodesRef4.current.has(item.Timestamp)
              ) {
                setAdditionalInfoConvo((prev) => [
                  ...prev,
                  item.important_information,
                ]);
                processedTimecodesRef4.current.add(item.Timestamp);
              }
            });
          }
          if (structured?.length != 0) {
            const itemTimeInSeconds = convertTimecodeToSeconds(
              structured.Time[0].Timestamp
            );
            if (
              currentTime >= itemTimeInSeconds &&
              !processedTimecodesRef6.current.has(structured.Time[0].Timestamp)
            ) {
              setStructuredConvo(structured.crm_data);
              processedTimecodesRef6.current.add(structured.Time[0].Timestamp);
            }
          }
          structuredQuestion?.forEach((item) => {
            const itemTimeInSeconds = convertTimecodeToSeconds(item.Timestamp);
            if (
              currentTime >= itemTimeInSeconds &&
              !processedTimecodesRef5.current.has(item.Timestamp)
            ) {
              setStructuredConvoQuestion((prev) => [item, ...prev]);
              processedTimecodesRef5.current.add(item.Timestamp);
            }
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [wavesurferRef, convertTimecodeToSeconds, generatedData, audioUrl]);

  const getTranscript = async (inputPrompt) => {
    setLoading(true);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-prj-srv-customer-complaints-pv.cloudfunctions.net/cf-1-1"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/US_POST_CALL_ANALYSIS_1",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        filename: audioFile,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        getCRMData();
        console.log("transcript", response.data);
        setLoading(false);
        setGeneratedData(JSON.parse(response.data.Transcript));
        setAdditionalInfo(JSON.parse(response.data["Important Information"]));
        setIntent(JSON.parse(response.data.Sentiments));
        wavesurferRef.current.play();
        setPlaying(true);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setGeneratedData([]);
    }
  };

  const getSOPfollowUp = async (inputPrompt) => {
    let config = {
      method: "post",
      url: "https://us-central1-prj-srv-customer-complaints-pv.cloudfunctions.net/cf-2",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        filename: audioFile,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("SOP", response.data);
        setInfo(response.data["SOP Follow-up Questions"]);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setInfo([]);
    }
  };

  const getCRMData = async (inputPrompt) => {
    setLoading(true);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-prj-srv-customer-complaints-pv.cloudfunctions.net/cf-3-1"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/US_POST_CALL_ANALYSIS_2",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        filename: audioFile,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("crm", response.data);
        setLoading(false);
        setStructured(response.data["BigQuery_Extracted_Rows"]);
        if (response.data["CRM_Follow-up_Questions"]?.length > 0) {
          setStructuredQuestion(
            JSON.parse(response.data["CRM_Follow-up_Questions"])
          );
        } else {
          setStructuredQuestion([
            {
              Timestamp: "00:00:00",
              "Follow-up Questions": "No CRM Questions Available",
            },
          ]);
        }
        setSummary(JSON.parse(response.data["CRM_Data_Summary"]));
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setStructuredConvo([]);
      setStructuredQuestion([
        {
          Timestamp: "00:00:00",
          "Follow-up Questions": "No CRM Questions Available",
        },
      ]);
    }
  };

  useEffect(() => {
    getAudioList();
  }, []);
  // console.log(conversation);
  const getAudioList = async () => {
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-prj-srv-customer-complaints-pv.cloudfunctions.net/sign-func"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/US_POST_CALL_SIGN_FUNC",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name:
          region == "IND" ? "test-buck-tata-003" : "us-post-call-analysis-buck",
      },
    };
    try {
      let response = await axios(config);
      console.log(response);
      setAudioFiles(response.data.signed_urls);
    } catch (error) {
      console.log("data error", error);
    }
  };
  const convertToBold = (text) => {
    const parts = text.split("**");
    return parts.map((part, index) => {
      return index % 2 === 0 ? (
        <span style={{ fontWeight: 100 }} key={index}>
          {part}
        </span>
      ) : (
        <strong key={index}>{part}</strong>
      );
    });
  };
  const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  return (
    <div className="font-md">
      {/* <Navbar subtitle="(Reports)" /> */}
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CSidebar className="new-sidebar">
            <div
              className="d-flex align-items-center justify-content-between p-2 mb-1"
              style={{
                boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
                clipPath: "inset(0px 0px -15px 0px)",
              }}
            >
              <div className="d-flex justify-content-center gap-3">
                <CCardTitle className="title-sidebar">
                  Speech Summarization
                </CCardTitle>
              </div>
            </div>
            <div style={{ overflow: "scroll" }} className="px-0">
              <CAccordion alwaysOpen activeItemKey={1} flush>
                <CAccordionItem className="p-0" itemKey={1}>
                  <CAccordionHeader
                    style={{ color: "#0b2e50" }}
                    className="font-md p-0"
                  >
                    Select Audio
                  </CAccordionHeader>
                  <CAccordionBody className="d-flex flex-column align-items-end p-1">
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <CCol
                        className="p-1 w-100"
                        md={3}
                        style={{ overflowY: "scroll", maxHeight: "60vh" }}
                      >
                        <CCard
                          className="mb-2"
                          style={{
                            width: "100%",
                            position: "sticky",
                            top: "-4px",
                            zIndex: 4,
                          }}
                        >
                          <CCardBody>
                            <CRow>
                              <CCol>
                                <CFormSelect
                                  size="sm"
                                  className="mb-3"
                                  onChange={(e) => {
                                    setSelct(e.target.value);
                                    let obj = JSON.parse(e.target.value);
                                    if (region === "IND") {
                                      setAudioUrl(obj.url);
                                    } else {
                                      setAudioUrl(audioMap[obj.fileName]);
                                    }
                                    setAudioFile(obj.fileName);
                                  }}
                                  value={selct}
                                >
                                  <option value={""} disabled>
                                    Select Audio File
                                  </option>
                                  {audioFiles?.map((el) => {
                                    return (
                                      <option value={JSON.stringify(el)}>
                                        {el.fileName}
                                      </option>
                                    );
                                  })}
                                </CFormSelect>
                              </CCol>
                            </CRow>
                            <div className="d-flex gap-2">
                              <CButton
                                onClick={() => {
                                  getTranscript();
                                  if (region === "IND") {
                                    getSOPfollowUp();
                                  }
                                }}
                                size="sm"
                                color="primary"
                                disabled={
                                  audioUrl?.length == 0 ||
                                  loading ||
                                  audioFile?.length == 0
                                }
                                className="w-50"
                              >
                                {loading ? <CSpinner size="sm" /> : null} Run
                              </CButton>
                              {playing ? (
                                <CButton
                                  onClick={() => {
                                    wavesurferRef.current?.pause();
                                  }}
                                  size="sm"
                                  color="success"
                                  disabled={
                                    audioUrl?.length == 0 ||
                                    loading ||
                                    audioFile?.length == 0 ||
                                    wavesurferRef.current ||
                                    generatedData.length === 0
                                  }
                                  className="w-50"
                                >
                                  Pause
                                </CButton>
                              ) : (
                                <CButton
                                  onClick={() => {
                                    wavesurferRef.current?.play();
                                  }}
                                  size="sm"
                                  color="success"
                                  disabled={
                                    audioUrl?.length == 0 ||
                                    loading ||
                                    audioFile?.length == 0 ||
                                    wavesurferRef.current ||
                                    generatedData.length === 0
                                  }
                                  className="w-50"
                                >
                                  Play
                                </CButton>
                              )}

                              <CButton
                                onClick={() => {
                                  wavesurferRef.current?.pause();
                                  setConversation([]);
                                  setAudioUrl("");
                                  setSelct("");
                                  setAudioFile("");
                                  setIntent([]);
                                  setGeneratedData([]);
                                  setInfo([]);
                                  setInfoConvo([]);
                                  setIntentConvo([]);
                                  setAdditionalInfo([]);
                                  setAdditionalInfoConvo([]);
                                  setStructuredConvo([]);
                                  setStructuredConvoQuestion([]);
                                  setStructured([]);
                                  setVis(false);
                                  processedTimecodesRef.current = new Set();
                                  processedTimecodesRef2.current = new Set();
                                  processedTimecodesRef3.current = new Set();
                                }}
                                size="sm"
                                color="danger"
                                disabled={loading}
                                className="w-50"
                              >
                                Reset
                              </CButton>
                            </div>
                            <div ref={waveref} className="m-auto mt-2"></div>
                            {audioUrl?.length == 0 ? (
                              <CAlert
                                className="p-1 m-auto text-center"
                                color="info"
                              >
                                Please Select Audio File
                              </CAlert>
                            ) : null}
                          </CCardBody>
                        </CCard>
                        {additionalInfoConvo?.length > 0 && (
                          <CCard
                            className="mb-2"
                            style={{
                              width: "100%",
                              backgroundColor: "rgb(218 255 218)",
                              marginBottom: "100px",
                            }}
                          >
                            <CCardBody>
                              <CCardSubtitle className="text-center">
                                Call Information
                              </CCardSubtitle>
                              {additionalInfoConvo?.length > 0 &&
                                additionalInfoConvo?.map((el) => {
                                  return (
                                    <CCol>
                                      <b>{Object.keys(el)[0]}:</b>{" "}
                                      <span>{Object.values(el)[0]}</span>
                                    </CCol>
                                  );
                                })}
                            </CCardBody>
                          </CCard>
                        )}
                        {/* {conversation.length > 0 ? (
                          <div>
                            <CCard
                              className="mb-2"
                              style={{
                                width: "100%",
                                backgroundColor: "#deeffa",
                              }}
                            >
                              <CCardHeader>
                                <CCardSubtitle>SOP Links</CCardSubtitle>
                              </CCardHeader>
                              <CCardBody className="font-md">
                                {infoConvo.length == 0 ? (
                                  <CSpinner size="sm" />
                                ) : (
                                  infoConvo?.map((item) => {
                                    return item?.links?.map((linkItem) => (
                                      <CCol>
                                        <span>Link: </span>
                                        <span className="font-sm">
                                          <CLink
                                            target="_blank"
                                            // className="d-flex flex-wrap"
                                            href={linkItem.link}
                                          >
                                            {linkItem.link}
                                          </CLink>
                                        </span>
                                      </CCol>
                                    ));
                                  })
                                )}
                              </CCardBody>
                            </CCard>
                          </div>
                        ) : null} */}
                      </CCol>
                    </div>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem className="p-0" itemKey={2}>
                  <CAccordionHeader
                    style={{ color: "#0b2e50" }}
                    className="font-md p-0"
                  >
                    Architecture Diagram
                  </CAccordionHeader>
                  <CAccordionBody className="d-flex flex-column align-items-end">
                    <BsZoomIn
                      onClick={() => {
                        setVisible(true);
                      }}
                      className="hover logout-icon"
                      size={"1rem"}
                    />
                    <CImage
                      onDoubleClick={() => {
                        setVisible(true);
                      }}
                      className="w-100 hover"
                      src={arcDiagram}
                    />
                    <CModal
                      visible={visible}
                      size="xl"
                      onClose={() => {
                        setVisible(false);
                      }}
                    >
                      <CModalHeader>Architecture Diagram</CModalHeader>
                      <CModalBody>
                        <CImage className="w-100" src={arcDiagram} />
                      </CModalBody>
                    </CModal>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </div>
          </CSidebar>
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container">
            {conversation?.length > 0 && !showSummary && (
              <CCol
                className="p-2 m-0 mx-2 rounded"
                style={{ backgroundColor: "lightblue", maxHeight: "30px" }}
              >
                <SliderComponent
                  type={intentConvo?.Sentiment}
                ></SliderComponent>
              </CCol>
            )}
            <CCol className="p-2">
              {loading && (
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <h4>
                    <Skeleton count={1} width={"50%"} />
                  </h4>
                  <p>
                    <Skeleton height={12} width={"75%"} count={2} />
                  </p>
                </SkeletonTheme>
              )}
              {structuredConvo?.length > 0 && (
                <OutputTable data={structuredConvo} />
              )}
              {structuredConvoQuestion?.length > 0 && !showSummary && (
                <CCard
                  className="mb-2 mt-2"
                  style={{ width: "100%", backgroundColor: "#deeffa" }}
                >
                  <CCardHeader>
                    <CCardTitle>CRM Questions</CCardTitle>
                  </CCardHeader>
                  <CCardBody className="font-md">
                    {structuredConvoQuestion.map((ele) => {
                      return (
                        <div>
                          <b>Question:</b>{" "}
                          <span>{ele["Follow-up Questions"]}</span>
                        </div>
                      );
                    })}
                  </CCardBody>
                </CCard>
              )}
              {conversation?.length === 0 &&
              structuredConvo?.length === 0 &&
              additionalInfoConvo?.length === 0 &&
              intentConvo?.length === 0 &&
              !loading ? (
                <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                  <div
                    style={{
                      width: "80%",
                      marginTop: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    className="d-flex align-items-center justify-content-center mt-auto"
                  >
                    <CImage
                      src={aiImage_icon}
                      width={100}
                      style={{ opacity: "0.6" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: 25,
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "auto",
                      width: "80%",
                    }}
                  >
                    <CCard
                      style={{
                        zIndex: 1,
                      }}
                      className="p-2 px-3 scale-up-center"
                    >
                      <b className="d-flex gap-2 align-items-center">
                        <FcAudioFile size={"1.5rem"} /> Select Audio File
                      </b>
                      <div className="mt-1 " style={{ color: "light" }}>
                        Select audio file from the list give and Play.
                      </div>
                    </CCard>
                    <CCard
                      style={{
                        zIndex: 1,
                      }}
                      className="p-2 px-3 scale-up-center"
                    >
                      <b className="d-flex gap-2 align-items-center">
                        <FcDataSheet size={"1.5rem"} />
                        Get structured data
                      </b>
                      <div className="mt-1 " style={{ color: "light" }}>
                        Returns results from BigQuery Database in structured
                        format.
                      </div>
                    </CCard>
                    <CCard
                      style={{
                        zIndex: 1,
                      }}
                      className="p-2 px-3 scale-up-center"
                    >
                      <b className="d-flex gap-2 align-items-center">
                        <FcAssistant size={"1.5rem"} /> Get Natural Language
                        Summary with Sentiment
                      </b>
                      <div className="mt-1 " style={{ color: "light" }}>
                        Get natural language response from the Google Gemini Pro
                        model.
                      </div>
                    </CCard>
                  </div>
                </div>
              ) : null}
              {showSummary && (
                <CCard
                  className="mb-2 mt-2"
                  style={{ width: "100%", backgroundColor: "#deeffa" }}
                >
                  <CCardHeader>
                    <CCardTitle>Audio Summary</CCardTitle>
                  </CCardHeader>
                  <CCardBody className="font-md">
                    {summary["Whole_Summary"]}
                  </CCardBody>
                  <CCardHeader>
                    <CCardTitle>
                      What colud have been done better by the Customer
                      Representative
                    </CCardTitle>
                  </CCardHeader>
                  <ul>
                    {/* {summary["Explanation"].split("|").map((el) => {
                        return <li>{convertToBold(el)}</li>;
                      })} */}
                    {summary["What_could_have_been_better"]
                      .split("|")
                      .map((el) => {
                        return <li>{convertToBold(el)}</li>;
                      })}
                  </ul>
                </CCard>
              )}
              <CButton
                shape="rounded-pill"
                style={{
                  position: "fixed",
                  right: "30px",
                  bottom: "30px",
                }}
                color="primary"
                onClick={() => {
                  setVis(true);
                }}
              >
                <IoChatbubbles size={"2rem"} />
              </CButton>

              <COffcanvas
                backdrop={false}
                visible={vis}
                placement="end"
                // style={{ width: "45%" }}
                onHide={() => {
                  setVis(false);
                }}
              >
                <COffcanvasHeader className="p-2 ">
                  <COffcanvasTitle style={{ fontWeight: 500, fontSize: 16 }}>
                    Audio Transcript
                  </COffcanvasTitle>
                  <CCloseButton
                    onClick={() => {
                      setVis(false);
                    }}
                  />
                </COffcanvasHeader>
                <COffcanvasBody>
                  <div
                    // ref={messageContainerRef}
                    style={{
                      paddingTop: "10px",
                      textAlign: "left",
                      paddingBottom: "80px",
                      transition:
                        "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
                    }}
                  >
                    {loading ? (
                      <CCol
                        className={` m-0 mt-1 align-items-center `}
                        style={{
                          maxWidth: "91%",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#d4e8ffb8"
                          highlightColor="#73a9ff"
                        >
                          <h4>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </CCol>
                    ) : null}
                    <ChatConversation data={conversation}></ChatConversation>
                  </div>
                </COffcanvasBody>
              </COffcanvas>
            </CCol>
          </div>
        </CCol>
      </CRow>
    </div>
  );
};

export default Prompt;
