import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CImage,
  CCardImage,
  CRow,
  CCol,
  CCardText,
} from "@coreui/react";

const NewsCard = ({ data }) => {
  return (
    <a href={data.link} target="_blank" style={{ textDecoration: "none" }}>
      <CCard
        className="mb-1"
        style={{ maxWidth: "540px", height: "150px", overflow: "hidden" }}
      >
        <CRow className="g-0">
          <CCol md={4} className="p-2">
            <CCardImage src={data.imageUrl} />
            <p style={{ fontSize: "10px" }} className="p-0 m-0">
              {data.date}
            </p>
            <p>
              <b style={{ fontWeight: 600 }}>Source</b> : {data.source}
            </p>
          </CCol>
          <CCol md={8}>
            <CCardBody className="p-2">
              <CCardTitle style={{ fontSize: "14px" }}>{data.title}</CCardTitle>
              <CCardText className="font-md">{data.snippet}</CCardText>
            </CCardBody>
          </CCol>
        </CRow>
      </CCard>
    </a>
  );
};

export default NewsCard;
