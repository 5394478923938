import React, { useState, useRef, useEffect } from "react";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CAvatar,
  CImage,
  CRow,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilSearch, cilSend, cilX } from "@coreui/icons";
import { Navbar } from "../components/Navbar";
import { ChatMessage } from "../components/ChatMessage";
import { FcAssistant, FcAddImage, FcUpload } from "react-icons/fc";
import aiImage from "../assets/images/OIG.png";
import aiImage_icon from "../assets/images/ai_image.png";
import ImageDescriptionSidebar from "../components/ImageDescriptionSidebar";
import { IoIosChatbubbles } from "react-icons/io";
import CommonSidebar from "../components/CommonSidebar";
import aiAvatarImage from "../assets/images/OIG.png";
import ArcDia from "../assets/images/image-arch-dia.png";

const ImageDescription = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));

  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);
  const [base64ImageFile, setBase64ImageFile] = useState("");

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);
  const handleFileSelect = (files) => {
    if (files.length > 0) {
      for (const fl of files) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Str = reader.result;
          const splitBase64Strings = base64Str.split(";");
          const splitBase64Str = splitBase64Strings[1].split(",");
          setBase64ImageFile(splitBase64Str[1]);
        };
        reader.readAsDataURL(fl);
      }
    }
  };
  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setLoading(true);
      getUnstructured(inputPrompt);
    }
  };

  const getUnstructured = async (inputPrompt) => {
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/image_description"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/INT_image_description",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        user_question: inputPrompt,
        user_image: base64ImageFile,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log(response);
        setLoading(false);
        setGeneratedData([
          ...generatedData,
          {
            question: inputPrompt,
            summary: response.data.Output,
          },
        ]);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "Answer Not Found",
        },
      ]);
    }
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />

      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={generatedData}
            setGeneratedData={setGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            CComponentTitle={"Image Upload"}
            title={"Ask Questions From Image"}
            CustomComponent={
              <ImageDescriptionSidebar
                generatedData={generatedData}
                setGeneratedData={setGeneratedData}
                sidebarVis={visible}
                setSidebarVis={setVisible}
                handleFileSelect={handleFileSelect}
                base64ImageFile={base64ImageFile}
                setBase64ImageFile={setBase64ImageFile}
              />
            }
            ArcDia={ArcDia}
            askQuestion={true}
          />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container" ref={messageContainerRef}>
            {generatedData.length == 0 ? (
              <>
                <div
                  style={{
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage_icon}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcUpload size={"1.5rem"} /> Upload Image
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Please upload Image in order to address the question
                      adequately.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcAddImage size={"1.5rem"} />
                      Select Image & Ask Questions
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Uppload a Image and ask a question in natural language.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcAssistant size={"1.5rem"} /> Get Natural Language
                      Answer
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Get natural language response from the Google Gemini Pro
                      model.
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {/* {generatedData?.map((data, index) => {
              return (
                <div>

                  <ChatMessage
                    key={index}
                    text={data.question}
                    isUser={true}
                    isError={true}
                  />
                  {data.answer.length == 0 ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        { }
                      </CAvatar>
                      <CCol
                        className={` m-0 mt-1 align-items-center `}
                        style={{
                          maxWidth: "91%",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#d4e8ffb8"
                          highlightColor="#73a9ff"
                        >
                          <h4>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </CCol>
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        { }
                      </CAvatar>
                      <div
                        className={`chat-message other mt-2 align-items-center font-md ms-0`}
                        style={{ maxWidth: "85%" }}
                      >
                        {data.answer.split("\n").map((str) => {
                          return <p className="p-0 m-0">{str}</p>;
                        })}
                      </div>
                    </div>
                  )}
                
                </div>
              );
            })} */}

            {generatedData?.map((el, i) => {
              return (
                <div
                  ref={messageContainerRef}
                  style={{ paddingRight: "20px" }}
                  className="mb-4"
                >
                  <ChatMessage key={i + 1} text={el.question} isUser={true} />
                  {el.summary == "" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      ></CAvatar>
                      <div
                        className={`chat-message other mt-2 align-items-center`}
                        style={{ width: "75%" }}
                      >
                        <SkeletonTheme
                          baseColor="#d9edf9"
                          highlightColor="#2F8FC7"
                        >
                          <h4 style={{ width: "100%" }}>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p style={{ width: "100%" }}>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </div>
                    </div>
                  ) : el.summary
                      ?.toLowerCase()
                      .includes(
                        "sorry couldn't find any information regarding that"
                      ) ||
                    el.summary?.toLowerCase().includes("answer not found") ? (
                    <div name={i}>
                      <ChatMessage
                        key={i}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : el.summary == "hello" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiImage}
                      >
                        {}
                      </CAvatar>
                      <div className={`chat-message other mt-2`}>
                        Hello! How can I assist you today?
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiImage}
                        >
                          {}
                        </CAvatar>
                        <div
                          className={`chat-message other mt-2 align-items-center font-md`}
                          style={{ maxWidth: "85%" }}
                        >
                          {el.summary.split("\n").map((str) => {
                            return <p className="p-0 m-0">{str}</p>;
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => handlePrompt(e)}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setGeneratedData([]);
                setBase64ImageFile("");
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder={"Ask a question"}
                  disabled={loading}
                />
                <CButton
                  type="submit"
                  disabled={inputVal == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </CCol>
        {/* <CForm
          className="pt-3 "
          style={{
            bottom: 0,
            position: "fixed",
            paddingLeft: visible ? 300 : 0,
            width: "100%",
            background: "transparent",
            zIndex: 11,
            backdropFilter: "blur(5.7px)",
            background:
              "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
          }}
          onSubmit={(e) => handlePrompt(e)}
        >
          <div
            className="rolling-chat "
            onClick={() => {
              setGeneratedData([]);
              setBase64ImageFile("");
            }}
          >
            <IoIosChatbubbles size={"2rem"} />
            <span className="font-lg">New Chat</span>
          </div>
          <CCard
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "70%",
            }}
            className="p-2 "
          >
            <CCol
              className="d-flex align-items-center w-100 inputfocus m-auto "
              style={{
                height: "45px",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <CFormInput
                className="border-0 inputfocus form-control position-relative font-md"
                style={{
                  backgroundColor: "white",
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  // color: "#0B57D0",
                  height: "30px",
                  borderRadius: "20px",
                  paddingRight: "40px",
                }}
                onChange={(e) => {
                  e.preventDefault();
                  setInputVal(e.target.value);
                }}
                value={inputVal}
                placeholder="Ask a question"
                disabled={loading || base64ImageFile.length == 0}
              />
              {inputVal.length > 0 ? (
                <CIcon
                  icon={cilX}
                  size="md"
                  className="position-absolute text-medium-emphasis hover"
                  style={{ right: "75px" }}
                  disabled={loading}
                  onClick={() => {
                    setInputVal("");
                  }}
                />
              ) : null}
              <CButton
                type="submit"
                disabled={inputVal == "" || loading || generatedData.length >= 20}
                // variant="outline"
                size="sm"
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "32px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  background:
                    "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
                  border: "none",
                }}
                className="hover d-flex align-items-center justify-content-center p-0 mx-2"
              >
                {loading ? (
                  <CSpinner
                    size="sm"
                    className="m-2 "
                    style={{ color: "white" }}
                  />
                ) : (
                  <CIcon
                    icon={cilSearch}
                    className="hover text-light p-0"
                    size="md"
                  // style={{ color: "#086EB5" }}
                  />
                )}
              </CButton>
            </CCol>
          </CCard>
        </CForm> */}
      </CRow>
    </div>
  );
};

export default ImageDescription;
