import { CCard, CCloseButton, CImage, COffcanvas } from "@coreui/react";
import React from "react";
import { useState } from "react";

const ImagePage = (props) => {
  const [vis, setVis] = useState(false);
  const [viewPort, setViewPort] = useState();
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: vis ? "repeat(2,1fr)" : "repeat(3,1fr)",
          gap: vis ? "10px" : "10px",
          width: vis ? "80%" : "100%",
          overflow: "scroll",
          height: "80vh",
          marginTop: "10px",
        }}
      >
        {props.data?.map((el) => {
          return (
            <div
              style={{
                height: vis ? "200px" : "250px",
                width: vis ? "280px" : "310px",
                padding: "10px",
              }}
              className={`rounded-3 image-box d-flex flex-column ${
                el.metadata.name == viewPort ? "image-selected" : ""
              }`}
            >
              <CImage
                onClick={() => {
                  setViewPort(el.metadata.name);
                  setVis(true);
                }}
                className="image-design rounded-3 "
                style={{
                  height: "90%",
                  width: "100%",
                }}
                src={el.metadata.name}
              />
              <div
                className="font-lg"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {/* {decodeURI(el.metadata.name)} */}
                {decodeURI(
                  el.metadata.name.split("/")[
                    el.metadata.name.split("/").length - 1
                  ]
                ).replace(/%2C/g, " ")}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          position: "sticky",
          top: "100px",
          right: "0px",
          marginLeft: "20px",
          padding: "10px",
          paddingTop: "40px",
          maxWidth: "420px",
        }}
        className={`${vis ? "rounded-3 border" : "d-none"}`}
      >
        {vis ? (
          <CCloseButton
            onClick={() => {
              setVis(false);
              setViewPort();
            }}
            style={{ position: "absolute", right: 10, top: 10 }}
          />
        ) : null}
        <CImage
          className={`${vis ? "resize opened" : "resize"}`}
          src={viewPort}
        />
        <div className="font-lg mt-1" style={{ wordBreak: "break-word" }}>
          {decodeURI(
            viewPort?.split("/")[viewPort?.split("/").length - 1]
          ).replace(/%2C/g, " ")}
          {/* {decodeURI(viewPort)} */}
        </div>
      </div>
    </div>
  );
};

export default ImagePage;
