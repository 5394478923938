import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

// Weather icons
import { ReactComponent as PartlyCloudy } from "../../assets/icons/weather/partly-cloudy.svg";
import { ReactComponent as Haze } from "../../assets/icons/weather/haze.svg";
import { ReactComponent as HeavyRain } from "../../assets/icons/weather/heavy-rain.svg";
import { ReactComponent as Rain } from "../../assets/icons/weather/rain.svg";
import { ReactComponent as Sleet } from "../../assets/icons/weather/sleet.svg";
import { ReactComponent as Snow } from "../../assets/icons/weather/snow.svg";
import { ReactComponent as Sunny } from "../../assets/icons/weather/sunny.svg";
import { ReactComponent as Cloudy } from "../../assets/icons/weather/cloudy.svg";
import { ReactComponent as Thunderstorm } from "../../assets/icons/weather/thunderstorm.svg";
import { MdLocationOn } from "react-icons/md";
import { CiTempHigh } from "react-icons/ci";
import axios from "axios";
import { dummyData } from "./weatherDummyData";
const WeatherCard = (props) => {
  const [weatherData, setWeatherData] = useState();
  const [index, setInd] = useState(Math.floor(Math.random() * 5));

  const getWeather = async () => {
    let config = {
      method: "GET",
      url: `https://api.openweathermap.org/data/2.5/forecast?q=${props.city}&units=metric&APPID=ee7da7dbd3cd6894f2423274cbdf0cfc`,
    };
    axios
      .request(config)
      .then((response) => {
        console.log("weather response", response);
        let temp = [];
        response.data.list.map((el) => {
          if (el.dt_txt.split(" ")[1] == "15:00:00") {
            temp.push(el);
          }
        });
        // console.log("temp", temp);
        setWeatherData(temp);
      })
      .catch((error) => {
        console.log(error);
        let temp = [];
        dummyData[index].list.map((el) => {
          if (el.dt_txt.split(" ")[1] == "15:00:00") {
            temp.push(el);
          }
        });
        setWeatherData(temp);
      });
  };
  useEffect(() => {
    getWeather();
  }, [props.city]);
  return (
    <>
      {weatherData == undefined ? (
        <div
          className={`chat-message other mt-2 align-items-center `}
          style={{
            width: "100%",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
        >
          <SkeletonTheme baseColor="#d9edf9" highlightColor="#2F8FC7">
            <h4 style={{ width: "100%" }}>
              <Skeleton count={1} width={"100%"} height={80} />
            </h4>
            <p
              style={{ width: "100%" }}
              className="d-flex gap-2 justify-content-evenly "
            >
              <Skeleton height={52} width={45} count={1} />
              <Skeleton height={52} width={45} count={1} />
              <Skeleton height={52} width={45} count={1} />
              <Skeleton height={52} width={45} count={1} />
              <Skeleton height={52} width={45} count={1} />
            </p>
          </SkeletonTheme>
        </div>
      ) : (
        <div
          className=" rounded mt-2"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
        >
          <div
            style={{
              background: weatherData[0]?.weather[0].icon.includes("01")
                ? "radial-gradient(ellipse at center, rgba(255,241,201,1) 1%,rgba(255,230,152,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("02")
                ? "radial-gradient(ellipse at center, rgba(255,241,201,1) 1%,rgba(255,230,152,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("03")
                ? "radial-gradient(ellipse at center, rgba(234,248,255,1) 0%,rgba(214,246,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("04")
                ? "radial-gradient(ellipse at center, rgba(234,248,255,1) 0%,rgba(214,246,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("09")
                ? "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("10")
                ? "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("11")
                ? "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("13")
                ? "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)"
                : weatherData[0]?.weather[0].icon.includes("50")
                ? "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)"
                : "radial-gradient(ellipse at center, rgba(203,240,255,1) 0%,rgba(174,232,255,1) 100%)",
              color: weatherData[0]?.weather[0].icon.includes("01")
                ? "#a8840f"
                : weatherData[0]?.weather[0].icon.includes("02")
                ? "#a8840f"
                : weatherData[0]?.weather[0].icon.includes("03")
                ? "#55717D"
                : weatherData[0]?.weather[0].icon.includes("04")
                ? "#55717D"
                : weatherData[0]?.weather[0].icon.includes("09")
                ? "#278AB2"
                : weatherData[0]?.weather[0].icon.includes("10")
                ? "#278AB2"
                : weatherData[0]?.weather[0].icon.includes("11")
                ? "#278AB2"
                : weatherData[0]?.weather[0].icon.includes("13")
                ? "#278AB2"
                : weatherData[0]?.weather[0].icon.includes("50")
                ? "#278AB2"
                : "#278AB2",
            }}
            className="p-2  rounded"
          >
            {/* Header */}
            <div className="d-flex gap-4 justify-content-between">
              <div>
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "25px" }}
                >
                  <CiTempHigh />
                  {weatherData[0]?.main.temp.toFixed(0)}
                  <span>&deg;</span> C
                </div>
                <div style={{ opacity: "0.8" }}>
                  {weatherData[0]?.weather[0].description}
                </div>
              </div>
              <div>
                {weatherData[0]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "60px", width: "60px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "60px", width: "60px" }} />
                ) : null}
              </div>
            </div>
            {/* Location */}
            <div
              style={{ opacity: "0.8" }}
              className="d-flex align-items-center gap-1 mt-1"
            >
              <MdLocationOn />
              {props.city}
            </div>
            {/* Current temprature */}
            <div style={{ opacity: "0.8" }} className="font-md mt-1">
              Min - {weatherData[0]?.main.temp_min.toFixed(0)}
              <span>&deg;</span> C / Max -{" "}
              {weatherData[0]?.main.temp_max.toFixed(0)}
              <span>&deg;</span> C
            </div>
            {/* weekly forecast */}
          </div>
          <div className="d-flex gap-2 mt-2 p-2">
            <div
              style={{
                color: "rgba(0,107,178,0.9)",
                background: "#FFFFFF",
                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
              className="font-md p-1 rounded"
            >
              <div className="text-center" style={{ fontWeight: 600 }}>
                Day-1
              </div>
              <div className="d-flex justify-content-center align-items-center gap-1">
                {weatherData[0]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "35px", width: "35px" }} />
                ) : weatherData[0]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "35px", width: "35px" }} />
                ) : null}
              </div>
              <div style={{ fontSize: "11px" }}>
                {weatherData[0]?.main.temp_min.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C /
                {weatherData[0]?.main.temp_max.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C
              </div>
            </div>
            <div
              style={{
                color: "rgba(0,107,178,0.9)",
                background: "#FFFFFF",
                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
              className="font-md p-1 rounded"
            >
              <div className="text-center" style={{ fontWeight: 600 }}>
                Day-2
              </div>
              <div className="d-flex justify-content-center">
                {weatherData[1]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "35px", width: "35px" }} />
                ) : weatherData[1]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "35px", width: "35px" }} />
                ) : null}
              </div>
              <div style={{ fontSize: "11px" }}>
                {weatherData[1]?.main.temp_min.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C /
                {weatherData[1]?.main.temp_max.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C
              </div>
            </div>
            <div
              style={{
                color: "rgba(0,107,178,0.9)",
                background: "#FFFFFF",
                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
              className="font-md p-1 rounded"
            >
              {" "}
              <div className="text-center" style={{ fontWeight: 600 }}>
                Day-3
              </div>
              <div className="d-flex justify-content-center">
                {weatherData[2]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "35px", width: "35px" }} />
                ) : weatherData[2]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "35px", width: "35px" }} />
                ) : null}
              </div>
              <div style={{ fontSize: "11px" }}>
                {weatherData[2]?.main.temp_min.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C /
                {weatherData[2]?.main.temp_max.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C
              </div>
            </div>
            <div
              style={{
                color: "rgba(0,107,178,0.9)",
                background: "#FFFFFF",
                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
              className="font-md p-1 rounded"
            >
              <div className="text-center" style={{ fontWeight: 600 }}>
                Day-4
              </div>
              <div className="d-flex justify-content-center">
                {weatherData[3]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "35px", width: "35px" }} />
                ) : weatherData[3]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "35px", width: "35px" }} />
                ) : null}
              </div>
              <div style={{ fontSize: "11px" }}>
                {weatherData[3]?.main.temp_min.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C /
                {weatherData[3]?.main.temp_max.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C
              </div>
            </div>
            <div
              style={{
                color: "rgba(0,107,178,0.9)",
                background: "#FFFFFF",
                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
              className="font-md  p-1 rounded"
            >
              <div className="text-center" style={{ fontWeight: 600 }}>
                Day-5
              </div>
              <div className="d-flex justify-content-center">
                {weatherData[4]?.weather[0].icon.includes("01") ? (
                  <Sunny style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("02") ? (
                  <PartlyCloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("03") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("04") ? (
                  <Cloudy style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("09") ? (
                  <HeavyRain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("10") ? (
                  <Rain style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("11") ? (
                  <Thunderstorm style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("13") ? (
                  <Snow style={{ height: "35px", width: "35px" }} />
                ) : weatherData[4]?.weather[0].icon.includes("50") ? (
                  <Haze style={{ height: "35px", width: "35px" }} />
                ) : null}
              </div>
              <div style={{ fontSize: "11px" }}>
                {weatherData[4]?.main.temp_min.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C /
                {weatherData[4]?.main.temp_max.toFixed(0)}
                <span className=" p-0 m-0">&deg;</span>C
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WeatherCard;
