import React from "react";
import { Navbar } from "../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CFormTextarea,
  CImage,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilSend } from "@coreui/icons";
import { useState } from "react";
import { RiImageEditFill } from "react-icons/ri";
import axios from "axios";
const ImageGen = () => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const generateImage = async (e) => {
    e.preventDefault();
    if (prompt.trim() == "") {
      return;
    }
    // // setLoading(true);
    let data = JSON.stringify({
      instances: [{ prompt: prompt.trim(), height: 720, width: 1280 }],
      project: "generativeai-coe",
      location: "asia-southeast1",
      endpoint:
        "projects/301481222425/locations/asia-southeast1/endpoints/7028298227069747200",
    });
    let config = {
      method: "post",
      url: "https://asia-southeast1-generativeai-coe.cloudfunctions.net/generate-images",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer 930ed3566a5ad89e9a3fe162fc004a0319433030cb7bfa80ae9d4bc779e7d8ee`,
      },
      data,
    };
    try {
      let response = await axios(config);
    } catch (err) {
      console.log("Image Response Error", err);
    }
  };
  return (
    <div>
      <Navbar />
      <div>
        <CImage />
      </div>
      <CForm
        className="pt-3"
        style={{
          bottom: 0,
          position: "fixed",
          //   left: 155,
          width: "100%",
          background: "white",
          // borderTop: "1px solid lightgrey",
          paddingBottom: "20px",
          zIndex: 11,
        }}
        onSubmit={(e) => generateImage(e)}
      >
        <CCol
          className="d-flex align-items-center w-75 inputfocus m-auto p-2"
          style={{
            borderRadius: "5px",
            height: "85px",
            backgroundColor: "#EEF4F8",
            // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <CFormTextarea
            className="border-0 inputfocus form-control"
            style={{
              backgroundColor: "#EEF4F8",
              boxShadow: "none",
              color: "#086EB5",
              height: "80px",
              resize: "none",
            }}
            onChange={(e) => setPrompt(e.target.value)}
            value={prompt}
            placeholder="Enter the prompt here"
          />
          <CButton
            type="submit"
            disabled={prompt.trim() == "" || loading}
            variant="ghost"
            className="p-0 border-0 hover"
          >
            {loading ? (
              <CSpinner
                size="sm"
                className="m-2"
                style={{ color: "#086EB5", height: "20px", width: "20px" }}
              />
            ) : (
              <RiImageEditFill
                className="m-2 hover"
                style={{ color: "#086EB5", height: "25px", width: "25px" }}
              />
            )}
          </CButton>
        </CCol>
      </CForm>
    </div>
  );
};

export default ImageGen;
// let a = {
//   method: "post",
//   url: "https://asia-southeast1-generativeai-coe.cloudfunctions.net/generate-images",
//   headers: {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     Authorization:
//       "Bearer 930ed3566a5ad89e9a3fe162fc004a0319433030cb7bfa80ae9d4bc779e7d8ee",
//   },
//   data: {
//     instances: [
//       {
//         prompt: "A room with red walls and yellow ceiling",
//         height: 720,
//         width: 1280,
//       },
//     ],
//     project: "generativeai-coe",
//     location: "asia-southeast1",
//     endpoint:
//       "projects/301481222425/locations/asia-southeast1/endpoints/7028298227069747200",
//   },
// };
