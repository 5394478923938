import { CImage, CCloseButton } from "@coreui/react";
import React, { useRef, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import VideoRecogPlayer from "./VideoRecogPlayer";

const VideoRecogCard = (props) => {
  const [videoArr, setVideoArr] = useState([]);
  const [vis, setVis] = useState(false);
  const [viewPort, setViewPort] = useState();
  useEffect(() => {
    const sortedArrays = {};
    if (props.video != "Error") {
      for (const obj of props.video) {
        if (!sortedArrays[obj.gcs_uri]) {
          sortedArrays[obj.gcs_uri] = [];
        }
        sortedArrays[obj.gcs_uri].push(obj);
      }
      setVideoArr(Object.values(sortedArrays));
    }
  }, []);

  return (
    <div className="fadein">
      {props.video != "Error" ? (
        <div
          className="mb-1 p-2 w-100"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gap: "10px",
          }}
        >
          {videoArr?.map((vid) => {
            return (
              <>
                <VideoRecogPlayer vid={vid} />
              </>
            );
          })}
        </div>
      ) : null}

      {props.img != "Error" ? (
        <div
          style={{
            display: "flex",
            backgroundColor: "rgba(255,255,255,0.6)",
            backdropFilter: "blur(10px)",
            padding: 5,
            borderRadius: 16,
            justifyContent: "space-evenly",
            width: "max-content",
            maxWidth: "100%",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
          className="ms-2"
        >
          <div
            style={{
              display: "flex",
              gap: vis ? "10px" : "10px",
              marginTop: "10px",
              width: "max-content",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {props.img != "Error" &&
              props.img?.map((el) => {
                return (
                  <div
                    style={{
                      height: vis ? "210px" : "250px",
                      width: vis ? "280px" : "330px",
                      padding: "10px",
                    }}
                    className={`rounded-3 image-box d-flex flex-column ${
                      el.gcs_signed_uri == viewPort?.url ? "image-selected" : ""
                    }`}
                  >
                    <CImage
                      alt={el.path.split("/")[el.path.split("/").length - 1]}
                      onClick={() => {
                        setViewPort({
                          url: el.gcs_signed_uri,
                          name: el.path.split("/")[
                            el.path.split("/").length - 1
                          ],
                        });
                        setVis(true);
                      }}
                      className="image-design rounded-3 "
                      style={{
                        height: "90%",
                        width: "100%",
                      }}
                      src={el.gcs_signed_uri}
                    />
                    <div
                      className="font-lg"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.path.split("/")[el.path.split("/").length - 1]}
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              position: "sticky",
              top: "100px",
              right: "0px",
              marginLeft: "20px",
              padding: "10px",
              paddingTop: 15,
              maxWidth: "420px",
              background: "transparent",
              animationDuration: "1000ms",
            }}
            className={`${vis ? "rounded-3 fadein" : "d-none fadeout"}`}
          >
            {vis ? (
              <CCloseButton
                onClick={() => {
                  setVis(false);
                  setViewPort();
                }}
                style={{
                  position: "absolute",
                  right: 20,
                  top: 20,
                  // border: "1px solid black",
                  borderRadius: "50%",
                  padding: "6px",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  color: "white",
                }}
                className="text-light"
              />
            ) : null}
            <CImage
              className={`${vis ? "resize opened" : "resize"}`}
              src={viewPort?.url}
            />
            <div
              className="font-lg mt-2 mx-2"
              style={{
                wordBreak: "break-word",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {viewPort?.name}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VideoRecogCard;
