import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const CellTable = (props) => {
  const [tableData, setTableData] = useState([]);
  const [column, setColumn] = useState([]);
  useEffect(() => {
    const rows = props.data.trim().split("\n").slice(2);
    console.log(rows);
    const headers = rows[0]
      .split("|")
      .map((header) => header.trim())
      .filter(Boolean);

    const documents = rows.slice(1).map((row) => {
      const values = row
        .split("|")
        .map((value) => value.trim())
        .filter(Boolean);
      const document = {};

      headers.forEach((header, index) => {
        document[header] = values[index];
      });

      return document;
    });
    console.log(documents);
    // documents.shift();
    if (documents?.length > 0) {
      setColumn(Object.keys(documents[0]));
      let tempData = [];
      documents.forEach((el) => {
        tempData.push(Object.values(el));
      });
      setTableData(tempData);
    }

    // setData(documents);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        maxHeight: 140,
        overflow: "scroll",
      }}
    >
      <table>
        <thead
          style={{
            position: "sticky",
            top: -2,
            fontSize: "12px",
            backgroundColor: "#FED9ED",
            maxHeight: "15px",
          }}
        >
          <tr style={{ backgroundColor: "FED9ED" }}>
            {column.map((cl) => {
              return (
                <td
                  style={{
                    width: "max-content",
                    color: "#7a7676",
                    backgroundColor: "#FED9ED",
                    whiteSpace: "nowrap",
                  }}
                  className="border px-2"
                  scope="col"
                >
                  {cl}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody style={{ fontSize: "11px", backgroundColor: "white" }}>
          {tableData.map((row) => {
            return (
              <tr>
                {row.map((value, i) => {
                  return (
                    <td
                      style={{
                        minWidth: ("" + value?.length * 3) / 2,
                      }}
                      className="border px-1"
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CellTable;
