import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { CButton, CTooltip } from "@coreui/react";

const DownloadComponent = ({ data, fName }) => {
  console.log("fname",fName)
  const [tableData, setTableData] = useState([]);
  const [column, setColumn] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setColumn(Object.keys(data[0]));
      let tempData = [];
      data.forEach((el) => {
        tempData.push(Object.values(el));
      });
      setTableData(tempData);
    }
  }, [data]);
  return (
    <CSVLink filename={fName == undefined ? `Table.csv` : `${fName}.csv`} data={tableData} headers={column}>
      <CTooltip content="Download" className="font-sm button-tooltip">
        <CButton
          size="sm"
          className="p-1 d-flex justify-content-center align-items-cente"
        >
          <CIcon size="md" icon={cilCloudDownload}></CIcon>
        </CButton>
      </CTooltip>
    </CSVLink>
  );
};

export default DownloadComponent;
