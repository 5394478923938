import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CAlert,
  CCard,
  CAvatar,
  CImage,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import aiImage from "../../assets/images/ai_image.png";
import { styled, useTheme } from "@mui/material/styles";
import { cilSend, cilWarning, cilHamburgerMenu } from "@coreui/icons";
import axios from "axios";
import VideoRecognitionSidebar from "./VideoRecognitionSidebar";
import { ChatMessage } from "../../components/ChatMessage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { QnAMessage } from "../../components/QnAMessage";
import VideoRecogCard from "./VideoRecogCard";
import {
  FcCamcorderPro,
  FcAlarmClock,
  FcGenealogy,
  FcPanorama,
} from "react-icons/fc";
import userImage from "../../assets/images/user.jpg";
import bg from "../../assets/images/chat.png";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CommonSidebar from "../../components/CommonSidebar";
import { IoIosChatbubbles } from "react-icons/io";

const VideoRecognitionPrompt = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const messageContainerRef = useRef(null);
  const [urlGeneratedData, setUrlGeneratedData] = useState([]);
  const [visible, setVisible] = useState(false);

  const playerRef = useRef();
  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, answer: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, answer: "" },
      ]);
      setInputVal("");
      setLoading(true);

      Promise.all([getVideo(inputPrompt), getImg(inputPrompt)]).then(
        (response) => {
          console.log(response);
          setGeneratedData([
            ...generatedData,
            {
              question: inputPrompt,
              answer: { video: response[0], img: response[1] },
            },
          ]);
          setLoading(false);
        }
      );
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };

  const getImg = async (inputPrompt) => {
    let configimg = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/image_metadata_search",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(configimg);
      if (response != undefined) {
        console.log("img response", response.data.image_search_data);
        if (response.data.image_search_data.length > 0) {
          return response.data.image_search_data;
        } else {
          return "Error";
        }
      }
    } catch (error) {
      console.log("image data error", error);
      return "Error";
    }
  };
  const getVideo = async (inputPrompt) => {
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/video_metadata_search",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("vid response", response.data.video_search_data);
        if (response.data.video_search_data.length > 0) {
          return response.data.video_search_data;
        } else {
          return "Error";
        }
      }
    } catch (error) {
      console.log("video data error", error);
      return "Error";
    }
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={urlGeneratedData}
            setGeneratedData={setUrlGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"Video Intelligence"}
          />
        </CCol>
        <CCol sm={9} className="ps-1" style={{ position: "relative" }}>
          <div className="chat-container" ref={messageContainerRef}>
            {generatedData.length == 0 ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcCamcorderPro size={"1.5rem"} /> Ask a Question
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Pose question about a specific video and this will
                      pinpoint the exact moments where the answer is addressed.
                    </div>
                  </CCard>
                  <CCard
                       style={{
                        zIndex: 1,
                      }}
                      className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcAlarmClock size={"2rem"} /> Time-Stamped Results
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Returns accurate timestamps for when the specified objects
                      or scenes occur in the video.
                    </div>
                  </CCard>
                  <CCard
                        style={{
                          zIndex: 1,
                        }}
                        className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcGenealogy size={"2rem"} /> Multi-Class Recognition
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Capable of recognizing a wide range of objects and
                      entities, including people, animals, and various items.
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {generatedData?.map((data, index) => {
              return (
                <div>
                  <ChatMessage
                    key={index}
                    text={data.question}
                    isUser={true}
                    isError={true}
                  />

                  {data.answer.length == 0 ? (
                    <div
                      style={{ marginLeft: "20px", marginRight: "10px" }}
                      className={`chat-message other align-items-center`}
                    >
                      <SkeletonTheme
                        baseColor="#f4d9ff"
                        highlightColor="#3EB7EF"
                      >
                        <h4 style={{ width: "100%" }}>
                          <Skeleton count={1} width={"40%"} />
                        </h4>
                        <p style={{ width: "100%" }}>
                          <Skeleton height={12} width={"90%"} count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ) : data.answer.video === "Error" &&
                    data.answer.img === "Error" ? (
                    <div name={0}>
                      <QnAMessage
                        key={0}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "40px" }}>
                      <VideoRecogCard
                        video={data.answer.video}
                        img={data.answer.img}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            <CForm
              className="pt-3 new-input-form"
              onSubmit={(e) => handlePrompt(e)}
            >
              <div
                className="rolling-chat"
                onClick={() => {
                  setGeneratedData([]);
                }}
              >
                <IoIosChatbubbles size={"2rem"} />
                <span className="font-lg" style={{ fontWeight: 600 }}>
                  New Chat
                </span>
              </div>
              <CCard
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "80%",
                  padding: "2px",
                  borderRadius: "50px",
                }}
              >
                <CCol
                  className="d-flex align-items-center w-100 inputfocus m-auto "
                  style={{
                    height: "45px",
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "50px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  <CFormInput
                    className="border-0 inputfocus form-control font-md"
                    style={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      color: "#086EB5",
                      borderRadius: "50px",

                      height: "40px",
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      setInputVal(e.target.value);
                    }}
                    value={inputVal}
                    placeholder={"Ask a question"}
                    disabled={loading || generatedData.length >= 20}
                  />
                  <CButton
                    type="submit"
                    disabled={inputVal == "" || loading}
                    variant="ghost"
                    size="sm"
                    className="p-0 border-0 hover"
                  >
                    {loading ? (
                      <CSpinner
                        size="sm"
                        className="m-2 "
                        style={{ color: "#086EB5" }}
                      />
                    ) : (
                      <CIcon
                        icon={cilSend}
                        className="m-2 hover text-success"
                        size="lg"
                      />
                    )}
                  </CButton>
                </CCol>
              </CCard>
            </CForm>
          </div>
        </CCol>
      </CRow>
    </div>
  );
};

export default VideoRecognitionPrompt;
