import React, { useEffect, useState, useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { CButton, CCol, CFormSelect, CRow, CTooltip } from "@coreui/react";
import { cilX, cilChart, cilChartLine } from "@coreui/icons";
import Multiselect from "multiselect-react-dropdown";

import CIcon from "@coreui/icons-react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  ArcElement
);
const zoomOptions = {
  pan: {
    enabled: true,
    mode: "x",
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
  },
};
const barDataStructure = JSON.stringify({
  labels: [],
  datasets: [],
});
const structureBarChartData = (data, resp, structure) => {
  let labels = [];
  let datasets = [];
  structure.dataset.forEach((k) => {
    datasets.push([]);
  });
  data.map((el) => {
    labels.push(el[structure.label]);
    datasets.map((d, i) => {
      d.push(el[structure.dataset[i]]);
    });
  });
  return (resp = {
    labels,
    datasets,
  });
};
function generateRGBAColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.7; // Set the alpha value to 0.8 for 80% opacity
  return {
    bg: `rgba(${r}, ${g}, ${b}, ${a})`,
    border: `rgb(${r}, ${g}, ${b})`,
  };
}

const BarChart = (props) => {
  const [graphData, setGraphData] = useState();
  const [createdGraphData, setCreatedGraphData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [yAxisColumnNames, setyAxisColumnNames] = useState();
  const [chartType, setChartType] = useState("bar");
  const [filteredYAxisCol, setFilteredYAxisCol] = useState([]);
  const [disableChart, setDisableChart] = useState(false);
  const graphRef = useRef(null);
  const multiselectRef = useRef(null);

  const [xAxis, setXAxis] = useState("");
  const [yAxis, setYAxis] = useState();

  function getIntegerValueKeys(array) {
    const integerValueKeys = [];
    for (const key in array[0]) {
      // console.log("typeof array[0][key])", typeof array[0][key]);
      let temp = !isNaN(array[0][key]) ? Number(array[0][key]) : array[0][key];
      if (typeof temp === "number") {
        integerValueKeys.push(key);
      }
    }
    return integerValueKeys;
  }

  useEffect(() => {
    setDisableChart(false);
    let columns = [];
    if (props.data.length > 0) {
      columns = Object.keys(props.data[0]);

      const integerValueKeys = getIntegerValueKeys(props.data);
      if (integerValueKeys.length == 0) {
        setDisableChart(true);
      }
      setyAxisColumnNames(integerValueKeys);

      let tempData = [];
      props.data.forEach((el) => {
        tempData.push(Object.values(el));
      });
      let structure = {
        label: 0,
        dataset: [],
      };
      let stringCount = 0;
      let numCount = 0;
      let yAxisCol = [];

      tempData[0].map((k, i) => {
        if (typeof k == "string") {
          stringCount++;
          structure.label = i;
        } else if (typeof k == "number" || k == null) {
          numCount++;
          structure.dataset.push(i);
        }
      });
      if (stringCount == 0) {
        structure.label = 0;
        structure.dataset.shift();
      }
      if (tempData[0].length > 1) {
        let res = structureBarChartData(tempData, {}, structure);
        let graphStructure = JSON.parse(barDataStructure);
        graphStructure.labels = res.labels;
        res.datasets.forEach((ele, i) => {
          const color = generateRGBAColor();
          graphStructure.datasets.push({
            label: columns[structure.dataset[i]],
            borderColor: "rgba(255,255,255,.55)",
            backgroundColor: "",
            pointBackgroundColor: "#fff",
            fill: true,
            borderRadius: 16,
            maxBarThickness: 8,
            datalabels: {
              display: true, // Set to false to disable data labels for this dataset
            },
            backgroundColor: color.bg,
            borderColor: color.border,
            borderWidth: 1.5,
            data: ele,
            options: {
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    color: "rgb(255, 99, 132)",
                    display: false,
                  },
                },
              },
              plugins: {
                pan: {
                  enabled: true,
                  mode: "x",
                },
                limits: {
                  x: { min: 1, max: 7 },
                },
                zoom: {
                  pan: {
                    enabled: true,
                  },
                },
              },
            },
          });
        });
        graphStructure.title = columns[structure.label];
        setGraphData(graphStructure);
        setCreatedGraphData([graphStructure]);
      } else if (tempData[0].length == 1) {
        setDisableChart(true);
        // props.setChartVis(false);
      } else {
      }
    }

    setColumnNames(columns);
  }, [props.data]);

  function handleAddGraph() {
    let xAxisArray = [];
    let yAxisArray = [];

    const tempGraphData = props.data;
    tempGraphData.map((d) => {
      xAxisArray.push(d[xAxis]);
    });
    yAxis.map((el) => {
      let temparr = [];
      tempGraphData.map((dt) => {
        temparr.push(dt[el]);
      });
      yAxisArray.push(temparr);
    });
    let tempAddGraphData = JSON.parse(barDataStructure);
    yAxis.map((yax, ind) => {
      const color = generateRGBAColor();
      let graphStruct = {
        label: yax,
        borderColor: "rgba(255,255,255,.55)",
        backgroundColor: "white",
        pointBackgroundColor: "white",
        fill: true,
        borderRadius: 16,
        maxBarThickness: 8,
        datalabels: {
          display: true, // Set to false to disable data labels for this dataset
        },
        backgroundColor: color.bg,
        borderColor: color.border,
        borderWidth: 1.5,
        data: yAxisArray[ind],
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                display: false,
              },
            },
          },
          plugins: {
            pan: {
              enabled: true,
              mode: "x",
            },
            limits: {
              x: { min: 1, max: 7 },
            },
            zoom: {
              pan: {
                enabled: true,
              },
            },
          },
        },
      };
      tempAddGraphData.datasets.push(graphStruct);
      tempAddGraphData.title = xAxis;
    });
    tempAddGraphData.labels = xAxisArray;
    setCreatedGraphData([...createdGraphData, tempAddGraphData]);
    setXAxis("");
    setYAxis();
    multiselectRef.current.resetSelectedValues();
    scrollToBottom();
  }
  function removeGraph(array, dictionary) {
    return array.filter((item) => item !== dictionary);
  }
  const scrollToBottom = () => {
    const container = graphRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      {disableChart ? null : (
        <div
          ref={graphRef}
          style={{ background: "white" }}
          className="rounded p-2 mt-2"
        >
          <CCol className="mt-2">
            <CCol
              // style={{ height: "70px" }}
              className="d-flex gap-2 justify-content-end align-items-start pt-0 pe-2"
            >
              <CCol className="d-flex align-items-center gap-1 ps-2">
                <CTooltip
                  content="Bar chart"
                  className="graph-tooltip font-sm"
                  arrow={true}
                >
                  <CButton
                    onClick={() => {
                      if (chartType == "bar" && props.chartVis) {
                        // props.setChartVis(false);
                      } else {
                        // props.setChartVis(true);
                        setChartType("bar");
                      }
                    }}
                    disabled={disableChart}
                    size="sm"
                    className="p-1 d-flex justify-content-center align-items-center text-white"
                    style={{
                      background: "#ea4335",
                      border: "1px solid #ea4335",
                    }}
                  >
                    <CIcon size="md" icon={cilChart}></CIcon>
                  </CButton>
                </CTooltip>
                <CTooltip
                  content="Line Chart"
                  className="graph-tooltip font-sm"
                  arrow={true}
                >
                  <CButton
                    onClick={() => {
                      if (chartType == "line" && props.chartVis) {
                        // props.setChartVis(false);
                      } else {
                        // props.setChartVis(true);
                        setChartType("line");
                      }
                    }}
                    className="p-1 d-flex justify-content-center align-items-center text-white"
                    disabled={disableChart}
                    size="sm"
                    style={{
                      background: "#673ab7",
                      border: "1px solid #673ab7",
                    }}
                  >
                    <CIcon size="md" icon={cilChartLine}></CIcon>
                  </CButton>
                </CTooltip>
              </CCol>
              <div style={{ width: "200px" }}>
                <CFormSelect
                  onChange={(e) => {
                    setXAxis(e.target.value);
                    let filtered = yAxisColumnNames.filter((el) => {
                      return el != e.target.value;
                    });
                    setFilteredYAxisCol(filtered);
                  }}
                  placeholder="Select X-axis"
                  className="font-md"
                  value={xAxis}
                >
                  <option disabled value={""}>
                    Select x-axis
                  </option>
                  {columnNames.map((el) => {
                    return <option value={el}>{el}</option>;
                  })}
                </CFormSelect>
              </div>
              <div style={{ width: "200px" }}>
                <Multiselect
                  ref={multiselectRef}
                  className="p-0 m-0 font-md"
                  options={filteredYAxisCol}
                  showCheckbox
                  onSelect={(list) => {
                    setYAxis(list);
                  }}
                  onRemove={(list) => {
                    setYAxis(list);
                  }}
                  avoidHighlightFirstOption={true}
                  emptyRecordMsg="No values available"
                  placeholder={"Select Y-axes"}
                  closeOnSelect={true}
                  displayValue="name"
                  disablePreSelectedValues
                  // selectedValues={[xAxis]}
                  isObject={false}
                  disable={xAxis == ""}
                  hideSelectedList
                  style={{
                    height: "25px",
                    "box-shadow": "rgba(0, 0, 0, 0.44) 0px 3px 8px",
                    searchBox: {
                      minHeight: "20px",
                      padding: "3px 5px",
                    },
                    chips: {
                      background: "none",
                      color: "black",
                      "border-radius": "5px",
                      "font-size": "12px",
                      height: "auto",
                    },
                    multiselectContainer: {
                      marginBottom: "5px",
                    },
                    optionContainer: {
                      "font-size": "10px",
                      maxHeight: "100px",
                      minHeight: "22px",
                      marginTop: "0px",
                      paddingTop: "3px",
                    },
                    option: {
                      height: "20px",
                      background: "#FFFFFF",
                      color: "grey",
                      marginTop: "0px",
                      paddingTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
              </div>

              <CButton
                disabled={
                  xAxis == undefined ||
                  yAxis == undefined ||
                  xAxis == "" ||
                  yAxis == ""
                    ? true
                    : false
                }
                className="font-md"
                size="sm"
                color="primary"
                onClick={() => {
                  handleAddGraph();
                }}
              >
                Add Graph
              </CButton>
            </CCol>

            <CRow className="p-2 w-100">
              {createdGraphData !== undefined
                ? createdGraphData.map((gData) => (
                    <CCol
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                      }}
                      className="border rounded p-1 m-2 w-100"
                      sm={12}
                    >
                      <CCol style={{ display: "flex", justifyContent: "end" }}>
                        <CIcon
                          className="me-2"
                          style={{ cursor: "pointer" }}
                          size="sm"
                          icon={cilX}
                          onClick={() => {
                            const filteredArray = removeGraph(
                              createdGraphData,
                              gData
                            );
                            setCreatedGraphData(filteredArray);
                          }}
                        />
                      </CCol>
                      {chartType == "bar" ? (
                        <Bar
                          width={"100%"}
                          height={"25vh"}
                          datasetIdKey="id1"
                          options={{
                            layout: {
                              padding: {
                                top: 15,
                              },
                            },
                            interaction: {
                              mode: "index",
                              intersect: false,
                            },
                            plugins: {
                              datalabels: {
                                display: false,
                              },
                              tooltip: {
                                position: "average",
                              },
                              legend: {
                                align: "start",
                                labels: {
                                  boxWidth: 12,
                                  boxHeight: 8,
                                  font: {
                                    size: 9,
                                  },
                                  textAlign: "left",
                                  align: "start",
                                },
                                display: true,
                                maxWidth: 20,
                                font: {
                                  size: 9,
                                },
                              },
                              zoom: zoomOptions,
                            },
                            scales: {
                              x: {
                                title: {
                                  display: true,
                                  text: gData.title,
                                },
                                stacked: false,
                                ticks: {
                                  font: {
                                    size: 9,
                                  },
                                },
                              },
                              y: {
                                stacked: false,
                                ticks: {
                                  // max: Math.max(...props.data.datasets[0].data) * 1.2,
                                  font: {
                                    size: 9,
                                  },
                                },
                              },
                            },
                          }}
                          data={gData}
                        />
                      ) : (
                        <Line
                          width={"100%"}
                          height={"25vh"}
                          datasetIdKey="id1"
                          options={{
                            layout: {
                              padding: {
                                top: 15,
                              },
                            },
                            interaction: {
                              mode: "index",
                              intersect: false,
                            },
                            plugins: {
                              datalabels: {
                                display: false,
                              },
                              tooltip: {
                                position: "average",
                              },
                              legend: {
                                align: "start",
                                labels: {
                                  boxWidth: 12,
                                  boxHeight: 8,
                                  font: {
                                    size: 9,
                                  },
                                  textAlign: "left",
                                  align: "start",
                                },
                                display: true,
                                maxWidth: 20,
                                font: {
                                  size: 9,
                                },
                              },
                              zoom: zoomOptions,
                            },
                            scales: {
                              x: {
                                title: {
                                  display: true,
                                  text: gData.title,
                                },
                                stacked: false,
                                ticks: {
                                  font: {
                                    size: 9,
                                  },
                                },
                              },
                              y: {
                                stacked: false,
                                ticks: {
                                  // max: Math.max(...props.data.datasets[0].data) * 1.2,
                                  font: {
                                    size: 9,
                                  },
                                },
                              },
                            },
                          }}
                          data={gData}
                        />
                      )}
                      {/* <div ref={graphRef} /> */}
                    </CCol>
                  ))
                : null}
            </CRow>
          </CCol>
        </div>
      )}
    </>
  );
};

export default BarChart;
