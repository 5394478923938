import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CCard,
  CAvatar,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import aiImage from "../assets/images/OIG.png";
import { ReactComponent as PartlyCloudy } from "../assets/icons/weather/partly-cloudy.svg";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilSearch, cilSend, cilX } from "@coreui/icons";
import { SiYourtraveldottv } from "react-icons/si";
import { FcAbout, FcLandscape } from "react-icons/fc";
import { ChatMessage } from "../components/ChatMessage";
import axios from "axios";
import ItineraryInsightSidebar from "../components/ItineraryInsightSidebar";
import FlightCard from "../components/FlightCard";
import TravelPlacesCard from "../components/PlacesCard";
import WeatherCard from "../components/WeatherComponents/WeatherCard";
import NewsCard from "../components/NewsCard";
import { FaPlaneArrival } from "react-icons/fa6";
import ItineraryChat from "../components/ItineraryChat";
const ItineraryInsight = () => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const messageContainerRef = useRef(null);
  const [travelData, setTravelData] = useState([]);
  const [isFilghtDetailsFound, setIsFilghtDetailsFound] = useState(false);
  const [locationOutput, setLocationOutput] = useState();
  const [chatHistoryData, setChatHistory] = useState([]);
  const [visible, setVisible] = useState(true);
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  const handleSubmit = async (question) => {
    if (question.trim() == "") {
      return;
    }
    setTravelData([
      ...travelData,
      {
        question: question.trim(),
        answer: "",
      },
    ]);
    if (isFilghtDetailsFound) {
      getNewsData(question.trim(), locationOutput);
      return;
    }
    setLoading(true);
    let data = {
      text: question.trim(),
      chat_history: chatHistoryData,
    };
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/travel-itenary-get-travel-details",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        setLoading(false);
        console.log("travel response", response);
        if (typeof response.data.output == "string") {
          setTravelData([
            ...travelData,
            {
              question: question.trim(),
              answer: { chat: response.data.output },
            },
          ]);
        } else if (response.data.output.destination) {
          setLocationOutput(response.data.output);

          getFlightData(question.trim(), response.data.output);
        }
        setChatHistory(response.data.chat_history);
      }
    } catch (err) {
      console.log("Travel response Error", err);
      setLoading(false);
      setTravelData([
        ...travelData,
        {
          question: question.trim(),
          answer: "no answer found",
        },
      ]);
    }
  };
  // console.log("Travel array", travelData);
  const getFlightData = async (question, locationData) => {
    let data = {
      source: locationData.source,
      destination: locationData.destination,
    };
    console.log("data", data);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/travel-itenary-get-flight-details",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };

    let data1 = {
      destination: locationData.destination,
    };
    let config1 = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/travel-itenary-get-weather-details",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data1,
    };
    try {
      let response = await axios(config);
      // let response1 = await axios(config1);
      console.log("flight response", response);
      // console.log("weather response", response1);
      if (response != undefined && response.data.length > 0) {
        setIsFilghtDetailsFound(true);
        setLoading(false);
        let dtArr = locationData.date.split(".");
        dtArr[dtArr.length - 1] = "2024";
        setTravelData([
          ...travelData,
          {
            question: question,
            answer: {
              flight: response.data[0],
              date: dtArr.join("."),
              ques: `what are your travel preferences in ${locationData.destination}?`,
              weather: "",
            },
          },
        ]);
      } else {
        setTravelData([
          ...travelData,
          {
            question: question.trim(),
            answer: "no answer found",
          },
        ]);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setTravelData([
        ...travelData,
        {
          question: question.trim(),
          answer: "no answer found",
        },
      ]);
    }
  };
  const getNewsData = async (question, locationData) => {
    let data = {
      destination: locationData.destination,
      preferences: question,
    };
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/travel-itenary-get-news-details",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        setLoading(false);
        console.log("news response", response);
        setTravelData([
          ...travelData,
          {
            question: question,
            answer: { places: response.data.places, news: response.data.news },
          },
        ]);
        setIsFilghtDetailsFound(false);
        setLocationOutput();
        setChatHistory([]);
      }
    } catch (err) {
      console.log("places response Error", err);
      setLoading(false);
      setTravelData([
        ...travelData,
        {
          question: question.trim(),
          answer: "no answer found",
        },
      ]);
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [travelData]);
  return (
    <div
      className="position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        background:
          "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
        height: "100vh",
      }}
    >
      <Navbar />
      <SiYourtraveldottv
        className="watermark"
        style={{ right: visible ? "9%" : "15%" }}
      />
      <ItineraryInsightSidebar
        setPromptList={setTravelData}
        promptList={travelData}
        setVisible={setVisible}
        visible={visible}
      />
      {!visible ? (
        <CButton
          color="info"
          className="p-0 position-fixed"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "60px",
            zIndex: 12,
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      ) : null}
      <div
        ref={messageContainerRef}
        style={{
          paddingLeft: visible ? "250px" : "100px",
          paddingRight: visible ? "10px" : "100px",
          transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
          overflow: "scroll",
          height: "100%",
          paddingBottom: "150px",
        }}
      >
        {travelData.length == 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1,1fr)",
              gap: 25,
              margin: "auto",
              width: "80%",
            }}
            className="mt-3"
          >
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 left-to-right"
            >
              <b className="d-flex gap-2 align-items-center">
                <FaPlaneArrival style={{ color: "skyblue" }} size={"1.5rem"} />{" "}
                Give Destination
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Give your departure and arrival cities, date of travel.
              </div>
            </CCard>
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 right-to-left"
            >
              {" "}
              <b className="d-flex gap-2 align-items-center">
                <FcAbout size={"1.5rem"} /> Get Flight Data
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Get departure and arrival airport and flight details.
              </div>
            </CCard>
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 left-to-right "
            >
              {" "}
              <b className="d-flex gap-2 align-items-center">
                <PartlyCloudy style={{ height: "30px", width: "30px" }} /> Get
                Realtime Weather
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Get 5 days weather forecast from date of departure
              </div>
            </CCard>{" "}
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 right-to-left"
            >
              <b className="d-flex gap-2 align-items-center">
                <FcLandscape size={"1.5rem"} /> View Tourism Locations and News
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Get a list of tourist places of your given category and top news
                from the destination city.
              </div>
            </CCard>
          </div>
        ) : (
          travelData.length > 0 &&
          travelData?.map((data, index) => {
            return <ItineraryChat data={data} index={index} />;
          })
        )}
      </div>
      <CForm
        className="pt-3 "
        style={{
          bottom: 0,
          position: "fixed",
          paddingLeft: visible ? 250 : 0,
          width: "100%",
          background: "transparent",
          zIndex: 11,
          backdropFilter: "blur(5.7px)",
          background:
            "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
          paddingBottom: "20px",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          setPrompt("");
          handleSubmit(e.target[0].value);
        }}
      >
        <CCard
          style={{
            backgroundColor: "transparent",
            border: "none",
            width: "70%",
          }}
          className="p-2  m-auto"
        >
          <CCol
            className="d-flex align-items-center w-100 inputfocus m-auto "
            style={{
              height: "45px",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CFormInput
              className="border-0 inputfocus form-control position-relative font-md"
              style={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                // color: "#0B57D0",
                height: "30px",
                borderRadius: "20px",
                paddingRight: "40px",
              }}
              onChange={(e) => {
                e.preventDefault();
                setPrompt(e.target.value);
              }}
              value={prompt}
              placeholder="Ask a question"
              disabled={loading}
            />
            {prompt.length > 0 ? (
              <CIcon
                icon={cilX}
                size="md"
                className="position-absolute text-medium-emphasis hover"
                style={{ right: "75px" }}
                disabled={loading}
                onClick={() => {
                  setPrompt("");
                }}
              />
            ) : null}
            <CButton
              type="submit"
              disabled={prompt == "" || loading}
              size="sm"
              style={{
                borderRadius: "50%",
                height: "30px",
                width: "32px",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                background:
                  "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
                border: "none",
              }}
              className="hover d-flex align-items-center justify-content-center p-0 mx-2"
            >
              {loading ? (
                <CSpinner
                  size="sm"
                  className="m-2 "
                  style={{ color: "white" }}
                />
              ) : (
                <CIcon
                  icon={cilSearch}
                  className="hover text-light p-0"
                  size="md"
                />
              )}
            </CButton>
            <div style={{ position: "absolute", right: -60 }}>
              <CButton
                // variant="outline"
                className="font-md pop-button"
                shape="rounded-pill"
                style={{
                  backgroundColor: "#f77e34",
                  color: "white",
                  border: "none",
                }}
                disabled={loading || travelData.length == 0}
                onClick={() => {
                  setTravelData([]);
                  setPrompt("");
                  setChatHistory([]);
                  setIsFilghtDetailsFound(false);
                  setLocationOutput();
                }}
              >
                Clear
              </CButton>
            </div>
          </CCol>
        </CCard>
      </CForm>
    </div>
  );
};

export default ItineraryInsight;
