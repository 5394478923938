import React, { useEffect, useRef, useState } from "react";
import VideoPlayer from "./VideoPlayer";

import TypeWriterEffect from 'react-typewriter-effect';
import { CButton, CCard, CCardBody, CCardImage, CCardText, CCardTitle, CCol, CRow } from "@coreui/react";
import { cilClock } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const VideoCard = (props) => {

  const [videoResults, setVideoResults] = useState(props.videoData);
  const [selectedVideoTimeIndex, setSelectedVideoTimeIndex] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedVideoTime, setSelectedVideoTime] = useState();

  const handleTranscriptOnTime = (index) => {
    setSelectedVideoTimeIndex(index)
  }

  const convertSecToMinutes = (seconds) => {

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minuteString = minutes < 10 ? "0" + minutes : minutes.toString();
    const secondString = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds.toString();
    return minuteString + ":" + secondString
  };
  const seekToSeconds = (time, index) => {
    // setPlaying(false)
    // handleTranscriptOnTime(index)
    // playerRef.current.seekTo(time, "seconds");
    // setCurrentPlayer(playerRef)
    // setActiveIndex(index)
    // setPlaying(true)

  };
  return (
    <div>
      {/* {videoResults?.map((vid, index) => { */}

      <div className="border rounded-2 m-1 p-2 my-2"
        onClick={() => setSelectedVideo(videoResults.answer.url)}
      >
        <div className="d-flex justify-content-start position-relative gap-1 align-items-start mb-1 font-lg">
          <div
            className={`audio-message other mt-1 align-items-center `}
            style={{
              width: "100%",
            }}
          >
            {videoResults.answer.answer}

          </div>
        </div>
        <div className="mb-1">
          <div className="d-flex justify-content-start position-relative gap-1 align-items-start my-1">
            <div
              style={{
                width: "100%",
              }}
              className="d-flex justify-content-around align-items-center"
            >
              <div
                style={{
                  borderTop: "1px solid lightgrey",
                  width: "32%",
                  height: "0px",
                }}
              ></div>
              <div className="text-medium-emphasis font-md">
                {/* Transcript text from video */}
                <div className="text-medium-emphasis font-md">
                  The following relevant details are found in the youtube video
                </div>
              </div>
              <div
                style={{
                  borderTop: "1px solid lightgrey",
                  width: "32%",
                  height: "0px",
                }}
              ></div>
            </div>
          </div>
          {videoResults.answer.transcript?.map((text, index) => {
            return <>
              <CCard className="mb-3" >
                <CRow className="g-1">
                  <CCol md={5}>
                    <div className="mb-1 p-2">
                      <VideoPlayer
                        videoUrl={videoResults.answer.url}
                        videoTime={videoResults.answer.start_time[index]}
                        selectedVideo={selectedVideo}
                        handleTranscriptOnTime={handleTranscriptOnTime}
                        seekToSeconds={seekToSeconds}
                      />
                    </div>
                  </CCol>
                  <CCol md={7}>
                    <CCardBody>
                      <div className="justify-content-start position-relative gap-1 align-items-start">
                        <div
                          className={`transcript-message other align-items-center font-md mb-0`}
                          style={{
                            width: "100%",
                            color: '#7832c3',
                            paddingLeft: 0,

                          }}
                        >
                          <span style={{
                            color: "#646465", fontWeight: 500, fontFamily: "system-ui",
                          }}>Transcript</span> : {text}
                        </div>
                      </div>
                      <CCardText>
                        <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                          <div
                            className={`transcript-message other mt-1 align-items-center font-md mb-0`}
                            style={{
                              width: "100%",
                              color: "#e1950b",
                              paddingLeft: 0

                            }}
                          >
                            <span style={{
                              color: "#646465", fontWeight: 500, fontFamily: "system-ui",
                            }}>Keywords :</span> {videoResults.answer.keywords[index]}
                          </div>
                        </div>
                      </CCardText>
                      <CCardText>
                        <small className="text-medium-emphasis me-2">Match percentage: {`${videoResults.answer.match_percentage[index]}%`}</small>

                        <small className="text-medium-emphasis">Start time: {`${convertSecToMinutes(videoResults.answer.start_time[index])}`}</small>

                      </CCardText>
                    </CCardBody>
                  </CCol>
                </CRow>
              </CCard>
              {/* <CCard className="mb-1" style={{ boxShadow: "rgb(188 214 221) 0px 1px 5px" }}>
                <CCardBody> <>
                  <div className="justify-content-start position-relative gap-1 align-items-start">
                    <div
                      className={`transcript-message other align-items-center font-md mb-0`}
                      style={{
                        width: "100%",
                        color: '#7832c3',
                        paddingLeft: 0,

                      }}
                    >
                      <span style={{
                        color: "grey", fontWeight: 400, fontFamily: "system-ui",
                      }}>Transcript</span> : {text}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                    <div
                      className={`transcript-message other mt-1 align-items-center font-md mb-0`}
                      style={{
                        width: "100%",
                        color: "#e1950b",
                        paddingLeft: 0

                      }}
                    >
                      <span style={{
                        color: "grey", fontWeight: 400, fontFamily: "system-ui",
                      }}>Keywords</span> :  {videoResults.answer.keywords[index]}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                    <div
                      className={`transcript-message other align-items-center font-md mb-0`}
                      style={{
                        width: "100%",
                        color: "#11939b",
                        paddingLeft: 0

                      }}
                    >
                      <span style={{
                        color: "grey", fontWeight: 400, fontFamily: "system-ui",
                      }}>Match percentage</span> : {`${videoResults.answer.match_percentage[index]}%`}
                    </div>
                  </div>
                  <div style={{ height: "150px" }} className="mb-1 ">
                    <VideoPlayer
                      // index={index}
                      videoUrl={videoResults.answer.url}
                      videoTime={videoResults.answer.start_time[index]}
                      selectedVideo={selectedVideo}
                      handleTranscriptOnTime={handleTranscriptOnTime}
                    />
                  </div>
                </></CCardBody>
              </CCard> */}
            </>
          })}


        </div>
        {/* <div style={{ height: "150px" }} className="mb-1 ">
          <VideoPlayer
            // index={index}
            videoUrl={videoResults.answer.url}
            videoTime={videoResults.answer.start_time}
            selectedVideo={selectedVideo}
            handleTranscriptOnTime={handleTranscriptOnTime}
          />
        </div> */}
      </div>

      {/* })} */}
    </div>
  );
};

export default VideoCard;