import {
  CSidebar,
  CButton,
  CTooltip,
  CCol,
  CFormCheck,
  CCollapse,
} from "@coreui/react";
import { PiChatsLight } from "react-icons/pi";
import { useNavigate } from "react-router";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
} from "@coreui/icons";
import {
  GiClothes,
  GiRunningShoe,
  GiLargeDress,
  GiDropEarrings,
  GiHeartNecklace,
  GiFeatherNecklace,
} from "react-icons/gi";
import { RiShirtFill } from "react-icons/ri";
import { FaShirt } from "react-icons/fa6";
import { MdOutlineWatch } from "react-icons/md";
import { BsSunglasses } from "react-icons/bs";
import Categorybox from "./Categorybox";
const EcommerceSidebar = ({
  generatedData,
  setGeneratedData,
  setSidebarVis,
  sidebarVis,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <CSidebar
      className="guide-sidebar"
      visible={true}
      position="fixed"
      placement={"start"}
      style={{ zIndex: 12, width: "200px" }}
    >
      <div style={{ height: "50px" }}></div>

      <CCol
        style={{ height: "70vh", overflow: "scroll" }}
        className="text-dark p-1"
      >
        <b style={{ fontSize: "16px" }}>Categories</b>
        <Categorybox
          title="Apparels"
          subcategory={[
            { name: "Shirts", icon: <FaShirt /> },
            { name: "Dresses", icon: <GiLargeDress /> },
          ]}
          setSelectedCategory={setSelectedCategory}
          icon={<GiClothes size={"1.3rem"} />}
        />
      </CCol>
    </CSidebar>
  );
};

export default EcommerceSidebar;
