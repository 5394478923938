import React, { useState, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import {
  BsQuestionDiamondFill,
  BsLink45Deg,
  BsFiletypeCsv,
  BsSearch,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillAudio, AiFillPlayCircle } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { SiYourtraveldottv } from "react-icons/si";
import { IoVideocamOutline, IoDocumentText } from "react-icons/io5";
import { RiKakaoTalkFill } from "react-icons/ri";
import { FaBalanceScale, FaRegMoneyBillAlt } from "react-icons/fa";
import { FaArrowTrendUp } from "react-icons/fa6";
import HomeCard from "../components/HomeCard";
import { MdAttachMoney } from "react-icons/md";
import dataset from "../database/usecase.json";
import { CFormInput } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilFilter } from "@coreui/icons";
import bagroundImg from "../assets/images/home_bg.avif";
const Homepage = () => {
  const navigate = useNavigate();
  const [tabData, setTabData] = useState(dataset.tabs);
  const componentMap = {
    BsQuestionDiamondFill: BsQuestionDiamondFill,
    BsLink45Deg: BsLink45Deg,
    BsFiletypeCsv: BsFiletypeCsv,
    BsSearch: BsSearch,
    AiFillAudio: AiFillAudio,
    AiFillPlayCircle: AiFillPlayCircle,
    IoDocumentText: IoDocumentText,
    IoVideocamOutline: IoVideocamOutline,
    SiYourtraveldottv: SiYourtraveldottv,
    CiImageOn: CiImageOn,
    RiKakaoTalkFill: RiKakaoTalkFill,
    FaArrowTrendUp: FaArrowTrendUp,
  };
  const renderComponentFromJSON = (jsonData) => {
    const { component, props } = jsonData;
    const Component = componentMap[component];

    if (!Component) {
      return null; // or a fallback component
    }

    return <Component {...props} />;
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // if (searchTerm === "") {
    //   setSearchResults([]);
    //   return;
    // }

    const delayDebounceFn = setTimeout(() => {
      const results = searchItems(searchTerm, tabData);
      setSearchResults(results);
    }, 350); // Debounce time in milliseconds

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, tabData]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function searchItems(inputText, jsonData) {
    const searchText = inputText.toLowerCase();
    return jsonData.filter((item) => {
      const titleMatch = item.title.toLowerCase().includes(searchText);
      const tagsMatch = item.tags.some((tag) =>
        tag.toLowerCase().includes(searchText)
      );
      return titleMatch || tagsMatch;
    });
  }
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `URL(${bagroundImg})`,
          // background:
          //   "linear-gradient(to bottom, rgba(255,221,191,1) 0%,rgba(255,255,255,1) 100%)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          // display: "",
          // alignItems: "center",
          paddingBottom: "50px",
          paddingTop: "30px",
          overflow: "scroll",

        }}
      >
        <div
          className="text-center homepage-title"
          style={{ fontSize: "22px", color: "#06025e", fontWeight: 600 }}
        >
          Gen-AI Powered Systems
        </div>
        {/* <div className="d-flex justify-content-center px-4 gap-3">
          <CFormInput
            size="sm"
            className="w-25"
            type="text"
            style={{ boxShadow: "none", outline: "none" }}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
        </div> */}
        <div class="ag-format-container">
          <div class="ag-courses_box">
            {searchResults.map((tab) => {
              return (
                <HomeCard
                  route={tab.route}
                  icon={renderComponentFromJSON(tab.icon)}
                  title={tab.title}
                  description={tab.description}
                  tags={tab.tags}
                />
              );
            })}

            {/* <HomeCard
              route={"/marico-search"}
              icon={<FaBalanceScale className="ag-icons" size={"1.8rem"} />}
              title={"Business Law Conversation"}
              description={"Ask Questions on Business Law PDF Documents."}
            />
            <HomeCard
              route={"/marico-sales"}
              icon={<MdAttachMoney className="ag-icons" size={"2rem"} />}
              title={"Sales Insights"}
              description={"Ask Questions on Sales PDF Documents."}
            /> */}
            {/* <HomeCard
              route={"/jsw-search"}
              icon={<BsSearch className="ag-icons" size={"1.8rem"} />}
              title={"JSW Cement"}
              description={"Search on Unstructured, Structured data"}
            /> 
            ** asdk
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
