import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import PDFCard from "../components/PDFCard";
import {
  AiOutlineCaretRight,
  AiOutlineCaretLeft,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import PlainPDFCard from "./PlainPdfCard";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const responsive = {
  large: {
    breakpoint: {
      max: 3000,
      min: 1300,
    },
    items: 4,
  },
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};
const CarouselCitations = ({ el, disabled }) => {
  const [citationVis, setCitationVis] = useState(true);
  useEffect(() => {}, [citationVis]);
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="d-flex justify-content-center align-items-center">
        <AiOutlineCaretLeft
          style={{ height: "20px", width: "20px", cursor: "pointer" }}
          className={currentSlide === 0 ? "disable text-medium-emphasis" : ""}
          onClick={() => previous()}
        />
        <AiOutlineCaretRight
          style={{ height: "20px", width: "20px", cursor: "pointer" }}
          onClick={() => next()}
          className={
            currentSlide == el.results?.length - 3 || el.results?.length <= 3
              ? "disable text-medium-emphasis"
              : ""
          }
        />
      </div>
    );
  };
  return (
    <div style={{ width: citationVis ? "720px" : "115px" }}>
      <b
        onClick={() => {
          setCitationVis(!citationVis);
        }}
        className=" hover d-flex gap-1 align-items-center"
      >
        Show Citations{" "}
        {!citationVis ? (
          <IoIosArrowDown size={20} />
        ) : (
          <IoIosArrowUp size={20} />
        )}
      </b>
      <div style={{ display: citationVis ? "block" : "none" }}>
        <Carousel
          arrows={false}
          centerMode={false}
          className="mt-2 position-relative mb-2 mx-0 px-0 "
          containerClass="container"
          draggable
          minimumTouchDrag={80}
          responsive={responsive}
          sliderClass=""
          slidesToSlide={2}
          swipeable
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
        >
          {el.results?.map((pd) => {
            return (
              <div className="mx-1">
                <PlainPDFCard
                  disabled={disabled}
                  title={pd.answer}
                  url={pd.source.signed_url}
                  name={pd.source.link}
                  pg={pd.source["page-number"]}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselCitations;
