import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CCardText,
} from "@coreui/react";
import { CFormLabel, CRow } from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilSend, cilWarning, cilHamburgerMenu } from "@coreui/icons";
import { QnAMessage } from "../../components/QnAMessage";
import VoiceSpeechSidebar from "./VoiceSpeechSlider";
import DownloadComponent from "../../components/DownloadComponent";
import Typewriter from "typewriter-effect";
import { BsPersonCircle } from "react-icons/bs";
import { RiCustomerService2Fill, RiCustomerServiceFill } from "react-icons/ri";

const VoiceSpeechUpload = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const [visible, setVisible] = useState(true);
  const messageContainerRef = useRef(null);
  const [audioFile, setAudioFile] = useState(null);
  const [base64AudioFile, setBase64AudioFile] = useState("");

  const fileInputRef = useRef(null);
  const items = [
    {
      "Customer Phone Number": "",
      CRE: "",
      "Customer Name": "",
      Gender: "",
      "Noise/Sound": "",
      "Rain/Storm/Leakage": "",
      "Pest/Mesh": "",
      Safety: "",
      "Easy to move": "",
      Durability: "",
      "Energy Efficiency": "",
      "Dust/Pollution": "",
      "Large Windows": "",
      "Slim/Sleek windows": "",
      "Colour/Coating": "",
      Glass: "",
      uPVC: "",
      Aluminium: "",
      "Showroom/Store": "",
      "Partner/Local partner": "",
      "Home visit": "",
      Price: "",
      Customisation: "",
      Warranty: "",
      "Installation/Process": "",
      Hardware: "",
      Timeline: "",
      Others: "",
    },
  ];
  const [tableRows, setTableRows] = useState(items);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };

  const AudioPlayer = ({ audioFile }) => {
    return (
      <audio
        controls
        style={{
          height: 30,
          border: "1px solid #cecfd3",
          borderRadius: "50px",
        }}
      >
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
  };
  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handleFileSelect = (files) => {
    if (files.length > 0) {
      for (const fl of files) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Str = reader.result;
          const splitBase64Strings = base64Str.split(";");
          const splitBase64Str = splitBase64Strings[1].split(",");
          setBase64AudioFile(splitBase64Str[1]);
        };
        reader.readAsDataURL(fl);
      }
    }
  };

  const handlePrompt = async (e) => {
    e.preventDefault();
    let responseData = {};
    let inputPrompt = <AudioPlayer audioFile={audioFile} />;

    setAudioFile(null);
    setGeneratedData([
      ...generatedData,
      {
        name: inputVal,
        question: inputPrompt,
        answer: "",
      },
    ]);
    setInputVal("");
    setLoading(true);

    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/audio_summary-1",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data: {
        file_name: inputVal,

        mp3_encoded: base64AudioFile,
      },
    };
    try {
      let response = await axios(config);
      if (response.data != undefined) {
        let transcript = response.data["result_dataset"];
        let summery = response.data["summary"];
        let info = response.data["extract_dict"];
        let customer_point = response.data["customer_point"];
        let customer_ph = response.data["customer_cont_number"];
        let representative_name = response.data["representative_name"];
        let customer_gender = response.data["gender"];
        // console.log("summary", summery)
        for (const item of items) {
          for (const customerPoint of customer_point) {
            // If the item key is included in the customer_point array
            if (customerPoint in item) {
              // Set the item value to "yes"
              item[customerPoint] = "yes";
            } else {
              item["Customer Phone Number"] =
                capitalizeFirstLetter(customer_ph);
              item["CRE"] = capitalizeFirstLetter(representative_name);
              item["Customer Name"] = capitalizeFirstLetter(info.person_name);
              item["Gender"] = capitalizeFirstLetter(customer_gender);
              item["Others"] = customerPoint;
            }
          }
        }
        setTableRows(items);
        setGeneratedData([
          ...generatedData,
          {
            name: inputVal,
            question: inputPrompt,
            transcript: transcript,
            answer: summery,
            extractInfo: info,
            customerPointRows: items,
          },
        ]);
      }
    } catch (err) {
      console.log("Response Error", err);
      setGeneratedData([
        ...generatedData,
        {
          name: inputVal,
          question: inputPrompt,
          transcript: "Answer Not Found.",
          answer: "Answer Not Found.",
          extractInfo: "Answer Not Found.",
          customerPointRows: "Answer Not Found.",
        },
      ]);
    }
    setLoading(false);

    fileInputRef.current.value = "";
  };
  function capitalizeFirstLetter(string) {
    const firstChar = string.charAt(0).toUpperCase();
    const remainingChars = string.slice(1);
    return `${firstChar}${remainingChars}`;
  }
  const getFileName = (el) => {
    return el.customerPointRows[0]["Customer Phone Number"];
  };

  return (
    <div className="font-md">
      <Navbar />
      <VoiceSpeechSidebar
        generatedData={generatedData}
        setGeneratedData={setGeneratedData}
        visible={visible}
        setVisible={setVisible}
      />
      {!visible ? (
        <CButton
          color="info"
          className="p-0 position-absolute"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "70px",
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      ) : null}
      <div
        ref={messageContainerRef}
        style={{
          backgroundColor: "#fffff",
          marginLeft: visible ? "250px" : "100px",
          marginRight: visible ? "auto" : "100px",
          overflowY: "auto",
          height: "90vh",
          textAlign: "left",
          paddingBottom: "80px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(https://www.talk-business.co.uk/wp-content/uploads/2017/05/shutterstock_472353400.jpg)`,
        }}
      >
        {generatedData.length == 0 ? (
          <div>
            <CAlert
              style={{
                animationDuration: "3000ms",
                color: "black",
                border: "none",
                background: "transparent",
                fontSize: "18px",
                fontFamily: "cursive",
              }}
              color="light"
              className="m-3 font-lg w-75 left-to-right"
            >
              • User need to upload audio file.
            </CAlert>
            <CAlert
              style={{
                animationDuration: "3000ms",
                color: "black",
                border: "none",
                background: "transparent",
                fontSize: "18px",
                fontFamily: "cursive",
              }}
              color="light"
              className="m-3 font-lg w-75 left-to-right"
            >
              • Make sure audio file is clear and well-recorded. This will help
              the service to generate a more accurate transcript and summary
            </CAlert>
            <CAlert
              style={{
                animationDuration: "3000ms",
                color: "black",
                border: "none",
                background: "transparent",
                fontSize: "18px",
                fontFamily: "cursive",
              }}
              color="light"
              className="m-3 font-lg w-75 left-to-right"
            >
              • The service will then transcribe the audio file into text and
              generate summary from audio file.
            </CAlert>
          </div>
        ) : (
          <>
            {generatedData?.map((el, i) => {
              return (
                <div
                  style={{ paddingRight: "20px", marginTop: 10 }}
                  className="mb-2"
                >
                  <div
                    style={{
                      fontWeight: 500,
                      marginLeft: "20px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgb(203 251 221)",
                      paddingLeft: "10px",
                      borderRadius: "80px 80px 80px 80px",
                      animationDuration: "3000ms",
                    }}
                    className="mb-2 right-to-left"
                  >
                    <p
                      style={{
                        marginTop: "20px",
                        marginLeft: "-10px",
                        marginRight: "10px",
                        fontWeight: 500,
                      }}
                      className="mb-3"
                    >
                      {el.question}
                    </p>
                    {el.name}
                  </div>
                  {el.answer == "" ? (
                    <div
                      style={{ marginLeft: "20px" }}
                      className={`transcript-message  other align-items-center `}
                    >
                      <SkeletonTheme
                        baseColor="#f4d9ff"
                        highlightColor="#3EB7EF"
                      >
                        <h4 style={{ width: "100%" }}>
                          <Skeleton count={1} width={"40%"} />
                        </h4>
                        <p style={{ width: "100%" }}>
                          <Skeleton height={12} width={"90%"} count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ) : el.answer == "Answer Not Found." ? (
                    <div name={i}>
                      <QnAMessage
                        key={i}
                        text={
                          "We couldn't find any data matching your request. Please try again later."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="bg-white rounded"
                      style={{
                        marginLeft: "20px",
                        padding: "10px",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -10px",
                      }}
                    >
                      {el.answer == "Answer Not Found." ? null : (
                        <>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start my-1">
                            <div
                              style={{
                                width: "100%",
                              }}
                              className="d-flex justify-content-around align-items-center"
                            >
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "42%",
                                  height: "0px",
                                }}
                              ></div>
                              <div className="text-medium-emphasis font-md">
                                Audio transcript
                              </div>
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "42%",
                                  height: "0px",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                            <div
                              className={`transcript-message other mt-1 align-items-center `}
                              style={{
                                width: "100%",
                              }}
                            >
                              {el.transcript.split("\n").map((line, index) => (
                                <CRow className="mb-1">
                                  <div style={{ width: "3%" }}>
                                    {line.length == 0 ? null : (
                                      <CCardText
                                        style={{
                                          color: line.startsWith(
                                            "Customer Representative:"
                                          )
                                            ? "#437DDB"
                                            : line.startsWith("Customer:")
                                            ? "forestgreen"
                                            : "white",
                                        }}
                                      >
                                        {line.startsWith(
                                          "Customer Representative:"
                                        ) ? (
                                          <RiCustomerService2Fill
                                            className="me-1"
                                            size={"1.5em"}
                                          ></RiCustomerService2Fill>
                                        ) : (
                                          <BsPersonCircle
                                            className="me-1"
                                            size={"1.5em"}
                                          ></BsPersonCircle>
                                        )}
                                      </CCardText>
                                    )}
                                  </div>
                                  <div style={{ width: "90%" }}>
                                    {line.length == 0 ? null : (
                                      <CCardText
                                        style={{
                                          color:
                                            line.startsWith(
                                              "Customer Representative:"
                                            ) || line.startsWith("Agent:")
                                              ? "#437DDB"
                                              : line.startsWith("Customer:")
                                              ? "forestgreen"
                                              : "white",
                                        }}
                                      >
                                        {line}
                                      </CCardText>
                                    )}
                                  </div>
                                </CRow>
                              ))}
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start my-1">
                            <div
                              style={{
                                width: "100%",
                              }}
                              className="d-flex justify-content-around align-items-center"
                            >
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "42%",
                                  height: "0px",
                                }}
                              ></div>
                              <div className="text-medium-emphasis font-md">
                                Transcript summary
                              </div>
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "42%",
                                  height: "0px",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                            <div
                              className={`audio-message other mt-1 align-items-center `}
                            >
                              {/* <p className="p-0 m-0 ">{el.answer}</p> */}
                              <Typewriter
                                options={{
                                  autoStart: true,
                                  loop: false,
                                  delay: 1,
                                  cursor: "",
                                }}
                                onInit={(typewriter) => {
                                  typewriter.typeString(el.answer).start();
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start my-1">
                            <div
                              style={{
                                width: "100%",
                              }}
                              className="d-flex justify-content-around align-items-center"
                            >
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "32%",
                                  height: "0px",
                                }}
                              ></div>
                              <div className="text-medium-emphasis font-md">
                                What pain points or requirement did customer
                                mention
                              </div>
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "32%",
                                  height: "0px",
                                }}
                              ></div>
                            </div>
                            <DownloadComponent
                              data={el.customerPointRows}
                              fName={getFileName(el)}
                            ></DownloadComponent>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                            <div
                              className={`transcript-message other mt-1 align-items-center `}
                              style={{
                                width: "100%",
                              }}
                            >
                              <CTable small bordered responsive>
                                <CTableHead>
                                  <CTableRow>
                                    {Object.keys(el.customerPointRows[0]).map(
                                      (cl) => {
                                        return (
                                          <CTableHeaderCell
                                            style={{
                                              minWidth: 100,
                                              color: "#7a7676",
                                              backgroundColor: "#e7f8ff",
                                            }}
                                            scope="col"
                                          >
                                            {cl}
                                          </CTableHeaderCell>
                                        );
                                      }
                                    )}
                                  </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                  <CTableRow>
                                    {Object.values(el.customerPointRows[0]).map(
                                      (value) => {
                                        return (
                                          <CTableDataCell
                                            style={{
                                              minWidth:
                                                value !== "" || value !== "yes"
                                                  ? (value.length * 7.5) / 2
                                                  : 100,
                                            }}
                                          >
                                            {value}
                                          </CTableDataCell>
                                        );
                                      }
                                    )}
                                  </CTableRow>
                                </CTableBody>
                              </CTable>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start my-1">
                            <div
                              style={{
                                width: "100%",
                              }}
                              className="d-flex justify-content-around align-items-center"
                            >
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "40%",
                                  height: "0px",
                                }}
                              ></div>
                              <div className="text-medium-emphasis font-md">
                                Additional information
                              </div>
                              <div
                                style={{
                                  borderTop: "1px solid lightgrey",
                                  width: "40%",
                                  height: "0px",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start position-relative gap-1 align-items-start">
                            <div
                              className={`transcript-message other mt-1 align-items-center `}
                              style={{
                                width: "100%",
                              }}
                            >
                              <CRow>
                                {el.extractInfo.product_name.length != 0 &&
                                el.extractInfo.product_name != "unknown" ? (
                                  <CCol style={{ width: "max-content" }} xs={3}>
                                    <label
                                      style={{
                                        fontFamily: "system-ui",
                                        color: "gray",
                                      }}
                                    >
                                      Product name :{" "}
                                      <label
                                        style={{
                                          fontFamily: "system-ui",
                                          color: "#dd7609",
                                        }}
                                      >
                                        {" "}
                                        {el.extractInfo.product_name}
                                      </label>
                                    </label>
                                  </CCol>
                                ) : null}
                                {el.extractInfo.sentiment.length != 0 &&
                                el.extractInfo.sentiment != "unknown" ? (
                                  <CCol xs={3}>
                                    <label
                                      style={{
                                        fontFamily: "system-ui",
                                        color: "gray",
                                      }}
                                    >
                                      Sentiment :{" "}
                                      <label
                                        style={{
                                          fontFamily: "system-ui",
                                          color: "#dd7609",
                                        }}
                                      >
                                        {" "}
                                        {el.extractInfo.sentiment}
                                      </label>{" "}
                                    </label>
                                  </CCol>
                                ) : null}
                              </CRow>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
      <CForm
        className="pt-3 "
        style={{
          bottom: 0,
          position: "fixed",
          left: visible ? 125 : 0,
          width: "100%",
          background: "transparent",
          paddingBottom: "20px",
          zIndex: 11,
          backdropFilter: "blur(5.7px)",
        }}
        onSubmit={(e) => handlePrompt(e)}
      >
        <CCard
          style={{
            backgroundColor: "transparent",
            border: "none",
            width: "80%",
          }}
          className="p-2  m-auto"
        >
          <CCol
            className="d-flex align-items-center w-100 inputfocus m-auto "
            style={{
              height: "45px",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CFormInput
              className="border-0 inputfocus form-control"
              style={{
                backgroundColor: "#f0feff",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                height: "35px",
                borderRadius: "20px",
                fontSize: 14,
              }}
              type="file"
              ref={fileInputRef}
              accept=".mp3,.wav"
              onChange={(e) => {
                e.preventDefault();
                if (e.target.files.length > 0) {
                  setInputVal(e.target.files[0].name);
                  setAudioFile(URL.createObjectURL(e.target.files[0]));
                  handleFileSelect(e.target.files);
                }
              }}
              placeholder="Upload Voice speech file"
              disabled={loading || generatedData.length >= 20}
            />
            <CButton
              type="submit"
              disabled={inputVal == "" || loading || generatedData.length >= 20}
              size="sm"
              color="success"
              style={{
                borderRadius: "50%",
                height: "40px",
                width: "42px",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                background:
                  "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
              }}
              className="hover d-flex align-items-center justify-content-center p-0 mx-2"
            >
              {loading ? (
                <CSpinner
                  size="sm"
                  className="m-2 "
                  style={{ color: "white" }}
                />
              ) : (
                <CIcon
                  icon={cilSend}
                  className="hover text-light p-0"
                  size="md"
                />
              )}
            </CButton>
          </CCol>
        </CCard>
      </CForm>
    </div>
  );
};

export default VoiceSpeechUpload;
