import {
  CSidebar,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CImage,
  CModal,
  CModalHeader,
  CCloseButton,
  CModalBody,
  CCardTitle,
} from "@coreui/react";
import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilPowerStandby, cilZoom, cilZoomIn } from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import { TbTargetArrow } from "react-icons/tb";
import { CiCircleQuestion } from "react-icons/ci";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Dark.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import ArcDia from "../assets/images/Dialogflow-CX-Arch.svg";
import { BsZoomIn } from "react-icons/bs";
const PolicySidebar = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  return (
    <CSidebar className="new-sidebar">
      <div
        className="d-flex align-items-center justify-content-between p-2 mb-1"
        style={{
          boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
          clipPath: "inset(0px 0px -15px 0px)",
        }}
      >
        <div className="d-flex justify-content-center gap-3">
          <CCardTitle className="title-sidebar">{"Banking Assistant"}</CCardTitle>
        </div>
        <div className="d-flex justify-content-center gap-3">
          {/* <CImage width={90} src={AtgLogo} />
          <CImage width={90} src={googleLogo} /> */}
        </div>
        {/* <div
          className="rolling"
          onClick={() => {
            navigate("/chat-app");
          }}
        >
          <CIcon icon={cilPowerStandby} className="hover me-1" size="lg" />
          <span className="font-lg" style={{ fontWeight: 600 }}>
            Log Out
          </span>
        </div> */}
      </div>

      <div style={{ overflow: "scroll" }} className="px-0">
        <CAccordion alwaysOpen activeItemKey={1} flush>
          <CAccordionItem className="p-0" itemKey={1}>
            <CAccordionHeader
              style={{ color: "#0b2e50" }}
              className="font-md p-0"
            >
              Architecture Diagram
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column align-items-end">
              <BsZoomIn
                onClick={() => {
                  setVisible(true);
                }}
                className="hover logout-icon"
                size={"1rem"}
              />
              <CImage
                onDoubleClick={() => {
                  setVisible(true);
                }}
                className="w-100 hover"
                src={ArcDia}
              />
              <CModal
                visible={visible}
                size="xl"
                onClose={() => {
                  setVisible(false);
                }}
              >
                <CModalHeader>Architecture Diagram</CModalHeader>
                <CModalBody>
                  <CImage className="w-100" src={ArcDia} />
                </CModalBody>
              </CModal>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="p-0" itemKey={2}>
            <CAccordionHeader
              style={{ color: "#0b2e50" }}
              className="font-md p-0"
            >
              Steps To Use
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-2 p-2">
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Initiate the Chat</b>
                  <div className="text-medium-emphasis">
                    Once in the Deceased Management section, you'll likely find
                    an option to start a chat or interact with the chatbot.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Introduction</b>
                  <div className="text-medium-emphasis">
                    The chatbot will greet you and provide a brief overview of
                    its capabilities. Follow any initial instructions or
                    prompts.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Provide Necessary Information</b>
                  <div className="text-medium-emphasis">
                    The chatbot may request basic information about the deceased
                    account holder, such as their name, account details, and
                    relationship to you.
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <TbTargetArrow size={"2rem"} style={{ color: "#7e72f4" }} />
                <div className="d-flex flex-column" style={{ width: "80%" }}>
                  <b>Documentation Guidance</b>{" "}
                  <div className="text-medium-emphasis">
                    The chatbot will guide you through the required
                    documentation. This may include death certificates, legal
                    documents, and any specific forms needed for processing.
                  </div>
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>Frequently Asked Questions</CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-2 p-2">
              <div className="d-flex gap-2">
                <CiCircleQuestion size={"2rem"} style={{ color: "#64be64" }} />
                <div style={{ width: "90%" }} className="text-medium-emphasis">
                  What is the purpose of the Deceased Management Chatbot?
                </div>
              </div>
              <div className="d-flex gap-2">
                <CiCircleQuestion size={"2rem"} style={{ color: "#64be64" }} />{" "}
                <div style={{ width: "90%" }} className="text-medium-emphasis">
                  what documents of sureties are required to be submitted?
                </div>
              </div>
              <div className="d-flex gap-2">
                <CiCircleQuestion size={"2rem"} style={{ color: "#64be64" }} />{" "}
                <div style={{ width: "90%" }} className="text-medium-emphasis">
                  what is there in the Letter of Disclaimer?
                </div>
              </div>
              <div className="d-flex gap-2">
                <CiCircleQuestion size={"2rem"} style={{ color: "#64be64" }} />{" "}
                <div style={{ width: "90%" }} className="text-medium-emphasis">
                  What information do I need to provide about the deceased
                  account holder's accounts?
                </div>
              </div>
              <div className="d-flex gap-2">
                <CiCircleQuestion size={"2rem"} style={{ color: "#64be64" }} />{" "}
                <div style={{ width: "90%" }} className="text-medium-emphasis">
                  Can you provide an overview of the claim settlement process?
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
    </CSidebar>
  );
};

export default PolicySidebar;
