import React, { useEffect, useState } from "react";
import PDFCard from "../components/PDFCard";
import axios from "axios";
import { CCollapse, CImage } from "@coreui/react";
const Citations = ({ el }) => {
  return (
    <div className="mt-1 font-md">
      <div>
        {el?.results.map((pd) => {
          return (
            <div className="my-2">
              <PDFCard
                title={pd.answer}
                url={pd.source.link}
                pg={pd.source["page-number"]}
                signed_url={pd.source.signed_url}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Citations;
