import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CRow,
  CImage,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilWarning, cilHamburgerMenu, cilSearch, cilX } from "@coreui/icons";
import FinanceSidebar from "../../components/FinanceSidebar";
import Citations from "../../components/Citations";
import ChartTypesFinance from "../../components/ChartTypesFinance";
import { QnAMessage } from "../../components/QnAMessage";
import ImagePage from "../../components/ImagePage";
import { BsBodyText, BsBook, BsGraphUp, BsImages } from "react-icons/bs";
import CommonSidebar from "../../components/CommonSidebar";
import aiImage from "../../assets/images/ai_image.png";
import { FcSearch } from "react-icons/fc";
import { IoIosChatbubbles } from "react-icons/io";
const FinancialPrompt = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [loadingStruct, setLoadingStruct] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const [visible, setVisible] = useState(true);
  const [activeKey, setActiveKey] = useState(0);
  const [current, setCurrent] = useState({});
  const [strucData, setStrucData] = useState("null");
  const [unstrucData, setUnStrucData] = useState("null");
  const [imgData, setImgData] = useState("null");
  const [videoData, setVideoData] = useState("null");
  const messageContainerRef = useRef(null);

  const handlePrompt = async (e) => {
    e.preventDefault();
    let responseData = {};
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    setUnStrucData("null");
    setStrucData("null");
    setImgData("null");
    setVideoData("null");

    setActiveKey(0);
    // setInputVal("");
    setLoading(true);
    setLoadingStruct(true);
    setLoadingText(true);
    setLoadingImage(true);
    getUnStructured(inputPrompt, responseData);
    getStructured(inputPrompt, responseData);
    getImages(inputPrompt, responseData);
    // getVideo(inputPrompt, responseData)
  };
  const getStructured = async (inputPrompt, responseData) => {
    let config1 = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/maruti-ask-structured-questions-t2sql",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt.trim(),
      },
    };
    try {
      let structuredReq = await axios(config1);
      setLoadingStruct(false);
      if (structuredReq.data.query_text.includes("No Relevant SQL Query")) {
        setStrucData();
      } else {
        setStrucData(structuredReq.data);
        setActiveKey(1);
      }
    } catch (err) {
      console.log("error", err);
      setStrucData();
      setLoadingStruct(false);
    }
    return responseData;
  };
  const getUnStructured = async (inputPrompt, responseData) => {
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-questions-maruti",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt.trim(),
      },
    };
    try {
      let unStructuredReq = await axios(config);
      console.log("unStructuredReq", unStructuredReq);
      setLoadingText(false);
      setUnStrucData(unStructuredReq.data);
      if (
        unStructuredReq.data?.summary ==
          "I am unable to provide a response using the information provided" ||
        unStructuredReq.data?.summary == "Empty Response" ||
        unStructuredReq.data?.summary == "More context information needed"
      ) {
      } else {
        setActiveKey(0);
      }
    } catch (err) {
      console.log("error", err);
      setLoading(false);
      setUnStrucData("Answer Not Found.");
      setLoadingText(false);
    }
    return responseData;
  };
  const getImages = async (inputPrompt, responseData) => {
    let config2 = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/image-search-maruti-1",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt.trim(),
      },
    };
    try {
      let imageRes = await axios(config2);
      setLoadingImage(false);
      if (imageRes.data.results.length > 0) {
        setImgData(imageRes.data.results);
        setActiveKey(2);
      } else {
        setImgData();
      }
    } catch (err) {
      console.log("Image Error", err);
      responseData.img = undefined;
      setImgData();
      setLoadingImage(false);
    }
    return responseData;
  };
  const getVideo = async (inputPrompt, responseData) => {
    setLoadingVideo(true);

    let config2 = {
      method: "post",
      //url: "https://us-central1-generativeai-coe.cloudfunctions.net/videofunctionmaruti",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/videofunctionmaruti-2",

      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt.trim(),
      },
    };
    try {
      // console.log("config2*******************",config2)
      let videoRes = await axios(config2);
      console.log(videoRes);
      setLoadingVideo(false);
      const respoArray = videoRes.data.data;
      console.log(Array.isArray(respoArray)); // true
      let isValid = Array.isArray(respoArray);
      if (isValid) {
        setVideoData(videoRes.data.data);
        setActiveKey(3);
      } else {
        setVideoData();
      }
    } catch (err) {
      console.log("Video Error", err);
      responseData.vid = undefined;
      setVideoData();
      setLoadingVideo(false);
    }
    return responseData;
  };
  useEffect(() => {
    if (
      inputVal != "" &&
      unstrucData != "null" &&
      strucData != "null" &&
      imgData != "null"
    ) {
      // console.log("hera");
      setGeneratedData([
        {
          question: inputVal,
          answer: unstrucData,
          result: strucData,
          img: imgData,
        },
        ...generatedData,
      ]);
      setCurrent({
        question: inputVal,
        answer: unstrucData,
        result: strucData,
        img: imgData,
      });
      setLoading(false);
    }
  }, [unstrucData, strucData, imgData]);
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          {/* <CommonSidebar
            // generatedData={urlGeneratedData}
            // setGeneratedData={setUrlGeneratedData}
            // sidebarVis={visible}
            // setSidebarVis={setVisible}
            // title={"Unstructured Data Q&A"}
            // CustomComponent={<PdfUpload />}
            // CComponentTitle={"PDF/URLs Upload"}
            // askQuestion={true}
            // ArcDia={arcDiag}
          /> */}
          <FinanceSidebar
            generatedData={generatedData}
            setGeneratedData={setGeneratedData}
            visible={visible}
            setVisible={setVisible}
            setCurrent={setCurrent}
            setStrucData={setStrucData}
            setUnStrucData={setUnStrucData}
            setImgData={setImgData}
            setInputVal={setInputVal}
          />
        </CCol>
        <CCol sm={9} className="ps-1  position-relative">
          <CForm
            className="pt-3 new-input-form-search"
            onSubmit={(e) => handlePrompt(e)}
          >
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "70%",
              }}
              className="p-2  m-auto d-flex flex-row align-items-center"
            >
              {/* <div
                className="rolling-chat "
                onClick={() => {
                  setGeneratedData([]);
                  setCurrent({});
                  setStrucData("null");
                  setUnStrucData("null");
                  setImgData("null");
                  setInputVal("");
                }}
              >
                <IoIosChatbubbles size={"2rem"} />
                <span className="font-lg" style={{ fontWeight: 600 }}>
                  New Chat
                </span>
              </div> */}
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control position-relative font-md ms-2"
                  style={{
                    backgroundColor: "white",
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    // color: "#0B57D0",
                    height: "30px",
                    borderRadius: "20px",
                    paddingRight: "26px",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder="Ask a question"
                  disabled={loading || generatedData.length >= 20}
                />
                {inputVal.length > 0 ? (
                  <CIcon
                    icon={cilX}
                    size="md"
                    className="position-absolute text-medium-emphasis hover"
                    style={{ right: "60px" }}
                    disabled={loading}
                    onClick={() => {
                      setInputVal("");
                    }}
                  />
                ) : null}
                <CButton
                  type="submit"
                  disabled={
                    inputVal == "" || loading || generatedData.length >= 20
                  }
                  // variant="outline"
                  size="sm"
                  style={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "32px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    background:
                      "linear-gradient(135deg, rgb(255 142 53) 0%, rgb(237 98 0) 41%)",
                    border: "none",
                  }}
                  className="hover d-flex align-items-center justify-content-center p-0 mx-2"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "white" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSearch}
                      className="hover text-light p-0"
                      size="md"
                      // style={{ color: "#086EB5" }}
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
            {generatedData.length >= 20 ? (
              <CAlert
                color={"warning"}
                style={{ width: "max-content" }}
                className="p-2 mb-2 font-md d-flex align-items-center gap-1 m-auto"
              >
                <CIcon icon={cilWarning} size="sm" height={15} width={15} />
                <div>
                  You have reached the chat limit. Please clear your chat to
                  continue asking more questions.
                </div>
              </CAlert>
            ) : null}
          </CForm>
          <div ref={messageContainerRef} className="chat-container-search ">
            {generatedData.length == 0 &&
            !(loadingText || loadingStruct || loadingImage) ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingTop: "9%",
                  }}
                  className="d-flex align-items-center justify-content-center "
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "65%",
                  }}
                >
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcSearch size={"1.5rem"} /> Ask Questions{" "}
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask Questions to get the insights from the Documents in
                      Unstructured, Structured and Image formats.
                    </div>
                  </CCard>
                </div>
              </>
            ) : (
              <>
                <div style={{ paddingRight: "20px" }} className="mb-4">
                  {(unstrucData == "Answer Not Found." ||
                    unstrucData?.summary ==
                      "I am unable to provide a response using the information provided" ||
                    unstrucData?.summary == "Empty Response" ||
                    unstrucData?.summary ==
                      "More context information needed") &&
                  !strucData &&
                  !imgData ? (
                    <div name={0}>
                      <QnAMessage
                        key={0}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="bg-white rounded"
                      style={{
                        marginLeft: "20px",
                        padding: "10px",
                      }}
                    >
                      <CNav
                        variant="underline"
                        className="mb-2 font-lg bg-white p-2 rounded"
                        style={{ position: "sticky", top: 0, zIndex: 10 }}
                      >
                        <CNavItem className="hover">
                          <CNavLink
                            className="nav-link mx-1"
                            onClick={() => {
                              setActiveKey(0);
                            }}
                            active={activeKey == 0}
                            role="tab"
                            disabled={
                              unstrucData == "Answer Not Found." ||
                              unstrucData?.summary ==
                                "I am unable to provide a response using the information provided" ||
                              unstrucData?.summary == "Empty Response" ||
                              unstrucData?.summary ==
                                "More context information needed" ||
                              loadingText
                            }
                          >
                            {loadingText ? (
                              <CSpinner size="sm" className="text-success" />
                            ) : (
                              <BsBook />
                            )}{" "}
                            Text
                          </CNavLink>
                        </CNavItem>
                        <CNavItem className="hover">
                          <CNavLink
                            className="nav-link mx-1"
                            onClick={() => {
                              setActiveKey(1);
                            }}
                            active={activeKey == 1}
                            role="tab"
                            disabled={strucData == undefined || loadingStruct}
                          >
                            {loadingStruct ? (
                              <CSpinner size="sm" className="text-success" />
                            ) : (
                              <BsGraphUp />
                            )}{" "}
                            Structured
                          </CNavLink>
                        </CNavItem>
                        <CNavItem className="hover">
                          <CNavLink
                            className="nav-link mx-1"
                            onClick={() => {
                              setActiveKey(2);
                            }}
                            active={activeKey == 2}
                            role="tab"
                            disabled={imgData == undefined || loadingImage}
                          >
                            {loadingImage ? (
                              <CSpinner size="sm" className="text-success" />
                            ) : (
                              <BsImages />
                            )}{" "}
                            Images
                          </CNavLink>
                        </CNavItem>
                      </CNav>
                      <CTabContent>
                        <CTabPane role="tabpanel" visible={activeKey == 0}>
                          {loadingText ? (
                            <CCol
                              className={` m-0 mt-1 align-items-center `}
                              style={{
                                maxWidth: "91%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                padding: "5px 10px",
                                marginBottom: "5px",
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                              }}
                            >
                              <SkeletonTheme
                                baseColor="#d4e8ffb8"
                                highlightColor="#73a9ff"
                              >
                                <h4>
                                  <Skeleton count={1} width={"50%"} />
                                </h4>
                                <p>
                                  <Skeleton
                                    height={12}
                                    width={"75%"}
                                    count={2}
                                  />
                                </p>
                              </SkeletonTheme>
                            </CCol>
                          ) : (
                            <>
                              {" "}
                              {unstrucData == "Answer Not Found." ||
                              unstrucData?.summary ==
                                "I am unable to provide a response using the information provided" ||
                              unstrucData?.summary == "Empty Response" ||
                              unstrucData?.summary ==
                                "More context information needed" ||
                              unstrucData?.summary ==
                                "Answer Not Found." ? null : (
                                <>
                                  <div
                                    style={{ overflow: "scroll" }}
                                    className="d-flex justify-content-start position-relative gap-1 align-items-start"
                                  >
                                    <div
                                      className={`chat-message other mt-1 align-items-center font-lg d-grid`}
                                      style={{
                                        width: "100%",
                                        maxWidth: "95%",
                                      }}
                                    >
                                      {unstrucData != "null" &&
                                        unstrucData?.summary
                                          .split("\n")
                                          .map((str) => {
                                            return (
                                              <p
                                                className="p-0 m-0"
                                                style={{
                                                  fontWeight: 500,
                                                  color: "#681da8",
                                                }}
                                              >
                                                {str}
                                              </p>
                                            );
                                          })}
                                      <br />
                                      <b>Results:</b>
                                      <Citations
                                        el={
                                          unstrucData != "null"
                                            ? unstrucData
                                            : undefined
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </CTabPane>
                        <CTabPane role="tabpanel" visible={activeKey == 1}>
                          {loadingStruct ? (
                            <CCol
                              className={` m-0 mt-1 align-items-center `}
                              style={{
                                maxWidth: "91%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                padding: "5px 10px",
                                marginBottom: "5px",
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                              }}
                            >
                              <SkeletonTheme
                                baseColor="#d4e8ffb8"
                                highlightColor="#73a9ff"
                              >
                                <h4>
                                  <Skeleton count={1} width={"50%"} />
                                </h4>
                                <p>
                                  <Skeleton
                                    height={12}
                                    width={"75%"}
                                    count={2}
                                  />
                                </p>
                              </SkeletonTheme>
                            </CCol>
                          ) : (
                            <>
                              {strucData != undefined &&
                              strucData != "null" &&
                              strucData?.result_dataset.length > 0 ? (
                                <>
                                  <div className="d-flex justify-content-start position-relative gap-1 align-items-start ">
                                    <div
                                      className={`align-items-center `}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <ChartTypesFinance
                                        data={strucData?.result_dataset}
                                        question={current.question}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </CTabPane>
                        <CTabPane role="tabpanel" visible={activeKey == 2}>
                          {loadingImage ? (
                            <CCol
                              className={` m-0 mt-1 align-items-center `}
                              style={{
                                maxWidth: "91%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                padding: "5px 10px",
                                marginBottom: "5px",
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                              }}
                            >
                              <SkeletonTheme
                                baseColor="#d4e8ffb8"
                                highlightColor="#73a9ff"
                              >
                                <h4>
                                  <Skeleton count={1} width={"50%"} />
                                </h4>
                                <p>
                                  <Skeleton
                                    height={12}
                                    width={"75%"}
                                    count={2}
                                  />
                                </p>
                              </SkeletonTheme>
                            </CCol>
                          ) : (
                            <ImagePage
                              data={imgData != "null" ? imgData : undefined}
                            />
                          )}
                        </CTabPane>
                      </CTabContent>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </CCol>
      </CRow>

      {/* {!visible ? (
        <CButton
          color="info"
          className="p-0 position-fixed"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "60px",
            zIndex: 12,
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      ) : null} */}
    </div>
  );
};

export default FinancialPrompt;
