import { CCol, CLink } from "@coreui/react";
import React from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
const PlainPDFCard = (props) => {
  //   const text = props.title.replace(/[^\u000A\u0020-\u007E]/g, " ");
  return (
    <CCol
      style={{
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
      className="border gap-2 p-2 rounded bg-white m-1 font-md pdf-card"
    >
      <div className="d-flex align-items-center gap-1">
        <BsFillFileEarmarkPdfFill
          style={{ color: "#EA4436", minWidth: "20px" }}
        />
        {props.disabled ? (
          <CLink
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textDecoration: "none",
            }}
            className="font-md hover"
          >
            {props.name.split("/")[props.name.split("/").length - 1]}
          </CLink>
        ) : (
          <CLink
            href={props.url + `#page=${props.pg}`}
            target="_blank"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textDecoration: "none",
            }}
            className="font-md hover"
          >
            {props.name.split("/")[props.name.split("/").length - 1]}
          </CLink>
        )}
      </div>
      <div
        className="text-medium-emphasis mt-1 resizei"
        style={{ fontSize: "0.65rem" }}
      >
        {props.title}
      </div>
      <div className="font-md top-shadow pt-1">Page-{props.pg}</div>
    </CCol>
  );
};
export default PlainPDFCard;
