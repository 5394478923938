import { cilHamburgerMenu, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCol, CButton, CAlert, CSidebar } from "@coreui/react";
import React from "react";
import { BsFillChatRightFill } from "react-icons/bs";
import { SiYourtraveldottv } from "react-icons/si";

const ItineraryInsightSidebar = (props) => {
  return (
    <CSidebar
      visible={props.visible}
      position="fixed"
      placement={"start"}
      className="guide-sidebar"
    >
      <div style={{ height: "40px" }}></div>
      <div
        className="d-flex justify-content-between align-items-center gap-3 px-2 mb-2"
        style={{ top: "10px" }}
      >
        <div
          style={{
            textOverflow: "ellipsis",
            color: "#086eb5",
            fontSize: "18px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: "10px",
          }}
        >
          <SiYourtraveldottv size={"1.5em"} /> Itinerary Insight
        </div>
        <CButton
          color="info"
          className="p-0 "
          style={{
            width: "35px",
          }}
          variant="outline"
          onClick={() => {
            props.setVisible(!props.visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      </div>
      <CCol style={{ height: "65vh" }}>
        {props.promptList?.map((el, i) => {
          return (
            <CCol
              key={i}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#086eb5",
              }}
              className="py-1 mt-1 hover text-nowrap font-md"
            >
              <BsFillChatRightFill
                className="hover"
                style={{ color: "#086EB5" }}
              />{" "}
              {el.question}
            </CCol>
          );
        })}
      </CCol>
    </CSidebar>
  );
};

export default ItineraryInsightSidebar;
