import { cilCloudUpload, cilFile } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CAlert, CCardText, CImage } from "@coreui/react";
import React, { useState } from "react";
import { useRef } from "react";
import csvUpload from "../assets/images/csv_icon.png";

const UploadCsv = ({ onFileSelect, indexed, data }) => {
  const fileInputRef = useRef(null);
  const [vis, setVis] = useState(false);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // event.preventDefault();
    setVis(false);
    const selectedFile = event.target.files;
    if (event.target.files[0]?.size < 5242880) {
      onFileSelect(selectedFile);
    } else {
      event.target.value = "";
      setVis(true);
    }
  };
  return (
    <div disabled={data?.length > 0} style={{ width: "90%" }}>
      <CAlert
        color="danger"
        className="p-1 d-flex justify-content-center"
        visible={vis}
      >
        File size too large!
      </CAlert>
      <input
        type="file"
        multiple={false}
        name="csvFile"
        accept=".csv"
        ref={fileInputRef}
        size={5242880}
        style={{ display: "none" }}
        onChange={handleFileChange}
        disabled={data?.length > 0}
      />
      <div
        onClick={handleFileSelect}
        style={{ color: "#086EB5", border: "1px solid #086EB5" }}
        className="hover d-flex gap-2 align-items-center rounded p-1 justify-content-center"
      >
        Choose File to Upload{" "}
        <CImage
          src={csvUpload}
          style={{ cursor: "pointer", width: 20, height: 20 }}
        ></CImage>
      </div>
      <CCardText
        className="text-medium-emphasis font-md text-secondary"
        style={{ fontSize: "12px" }}
      >
        Max file size 5MB
      </CCardText>
    </div>
  );
};

export default UploadCsv;
