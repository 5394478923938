import { CAvatar } from "@coreui/react";
import {
  AiOutlineExclamationCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import aiImage from "../assets/images/OIG.png";
import userImage from "../assets/images/user.png";

export const ChatMessage = ({ text, isUser, isError, subChat }) => {
  let name = JSON.parse(
    sessionStorage.getItem("authentication")
  )[0].toUpperCase();
  return (
    <>
      {isUser ? (
        <div className="d-flex justify-content-end position-relative gap-2 align-items-start mb-2">
          <div
            className={`chat-message-jsw user mt-2 align-items-center d-flex gap-2 justify-content-end font-md`}
            style={{
              maxWidth: "85%",
            }}
          >
            {text}
          </div>
          <CAvatar
            style={{
              width: "35px",
              height: "35px",
            }}
            className="font-md mt-2 border avatar"
            size="sm"
            src={userImage}
          >
            {name}
          </CAvatar>
        </div>
      ) : isError ? (
        <div
          style={{ marginLeft: "10px" }}
          className="d-flex justify-content-start position-relative gap-2 align-items-start"
        >
          <CAvatar
            style={{
              width: "35px",
              height: "35px",
            }}
            className="font-md mt-2 border avatar"
            size="sm"
            src={aiImage}
          >
            {name}
          </CAvatar>
          <div
            className={`chat-message other mt-2 align-items-center d-flex gap-2 font-md`}
            style={{ marginLeft: "0px", maxWidth: "85%" }}
          >
            <AiOutlineExclamationCircle size={"1em"} />
            {text}
          </div>
        </div>
      ) : (
        <div
          style={{ marginLeft: "10px" }}
          className="d-flex justify-content-start position-relative gap-2 align-items-start mb-2"
        >
          {subChat ? (
            <CAvatar
              style={{
                display: "block",
                boxShadow: "none",
                marginRight: "10px",
              }}
              size="sm"
            ></CAvatar>
          ) : (
            <CAvatar
              style={{
                width: "35px",
                height: "35px",
              }}
              className="avatar font-md mt-2 border"
              size="sm"
              src={aiImage}
            >
              {name}
            </CAvatar>
          )}
          <div
            className={`chat-message other mt-2 align-items-center d-flex gap-2 font-md`}
            style={{
              marginLeft: "0px",
              maxWidth: "85%",
              backdropFilter: "blur(10.5px)",
              background: "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
            }}
          >
            <div>{text}</div>
          </div>
        </div>
      )}
    </>
  );
};
