// import React, { useState, useRef, useEffect } from "react";
// import {
//   CForm,
//   CFormInput,
//   CCard,
//   CCol,
//   CButton,
//   CSpinner,
//   CAlert,
//   CAvatar,
// } from "@coreui/react";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import axios from "axios";
// import CIcon from "@coreui/icons-react";
// import { cilWarning, cilHamburgerMenu, cilSearch, cilX } from "@coreui/icons";
// import { Navbar } from "../../components/Navbar";
// import { ChatMessage } from "../../components/ChatMessage";
// import PlainSidebar from "../../components/PlainSidebar";
// import { FcDocument, FcSearch } from "react-icons/fc";
// import aiImage from "../../assets/images/OIG.png";
// import CarouselCitations from "../../components/CarouselCitations";
// import OutputTable from "../../components/OutputTable";
// const MaricoSales = () => {
//   const [inputVal, setInputVal] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [visible, setVisible] = useState(true);
//   const messageContainerRef = useRef(null);
//   const [generatedData, setGeneratedData] = useState([]);

//   const scrollToBottom = () => {
//     const container = messageContainerRef.current;
//     if (container) {
//       messageContainerRef.current.scrollIntoView({
//         behavior: "smooth",
//         block: "start",
//       });
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [generatedData]);

//   const handlePrompt = async (e) => {
//     e.preventDefault();
//     let inputPrompt = e.target[0].value;
//     if (inputPrompt.trim() == "") {
//       return;
//     }
//     let query = inputPrompt.toLowerCase().trim();
//     if (
//       query == "hi" ||
//       query == "hello" ||
//       query == "what can you do for me" ||
//       query == "helo" ||
//       query == "hii" ||
//       query == "hey" ||
//       query == "how are you"
//     ) {
//       setGeneratedData([
//         ...generatedData,
//         { question: inputPrompt, summary: "hello" },
//       ]);
//       setInputVal("");
//     } else {
//       setGeneratedData([
//         ...generatedData,
//         { question: inputPrompt, summary: "" },
//       ]);
//       setInputVal("");
//       setLoading(true);

//       Promise.all([getUnstructured(inputPrompt), getStructured(inputPrompt)])
//         .then((results) => {
//           console.log("All promises resolved:", results);
//           setLoading(false);
//           setGeneratedData([
//             ...generatedData,
//             {
//               question: inputPrompt,
//               summary: results[0].data.summary,
//               img_search_data: results[0].data.img_search_data,
//               tuning_data: results[0].data.input_text,
//               results: results[0].data.results,
//               dataset: results[1].data.result_dataset,
//             },
//           ]);
//         })
//         .catch((error) => {
//           console.error("One of the promises rejected:", error);
//           setLoading(false);
//           setGeneratedData([
//             ...generatedData,
//             {
//               question: inputPrompt,
//               summary: "Answer Not Found",
//               img_search_data: [],
//               tuning_data: "",
//               results: "",
//               dataset: [],
//             },
//           ]);
//         });
//     }
//   };

//   const getUnstructured = async (inputPrompt) => {
//     let config = {
//       method: "post",
//       url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-questions-marico-sales",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       data: {
//         question: inputPrompt,
//       },
//     };
//     try {
//       let response = await axios(config);
//       if (response != undefined) {
//         return response;
//       }
//     } catch (error) {
//       console.log("data error", error);
//       return {
//         data: {
//           summary: "Answer Not Found",
//           img_search_data: [],
//           tuning_data: "",
//           results: "",
//         },
//       };
//     }
//   };
//   const getStructured = async (inputPrompt) => {
//     let config = {
//       method: "post",
//       url: "https://us-central1-generativeai-coe.cloudfunctions.net/ask-questions-marico-struct",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       data: {
//         question: inputPrompt,
//       },
//     };
//     try {
//       let response = await axios(config);
//       if (response != undefined) {
//         return response;
//       }
//     } catch (error) {
//       console.log("data error", error);
//       return { data: { result_dataset: [] } };
//     }
//   };
//   return (
//     <div
//       style={{
//         transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
//         background:
//           "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
//       }}
//     >
//       <Navbar />
//       <PlainSidebar
//         generatedData={generatedData}
//         setGeneratedData={setGeneratedData}
//         sidebarVis={visible}
//         setSidebarVis={setVisible}
//       />
//       {!visible ? (
//         <CButton
//           color="info"
//           className="p-0 position-fixed"
//           style={{
//             marginLeft: "10px",
//             width: "35px",
//             top: "60px",
//             zIndex: 12,
//           }}
//           onClick={() => {
//             setVisible(!visible);
//           }}
//         >
//           <CIcon icon={cilHamburgerMenu} className="text-dark" />
//         </CButton>
//       ) : null}
//       <div
//         style={{
//           backgroundColor: "#fffff",
//           paddingLeft: visible ? "250px" : "100px",
//           paddingRight: visible ? "0px" : "100px",
//           marginTop: "10px",
//           overflowY: "auto",
//           height: "90vh",
//           textAlign: "left",
//           paddingBottom: "80px",
//           transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
//         }}
//       >
//         {generatedData.length == 0 ? (
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "repeat(1,1fr)",
//               gap: 25,
//               margin: "auto",
//               width: "80%",
//             }}
//             className="mt-3"
//           >
//             <CCard
//               style={{
//                 boxShadow:
//                   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
//                 zIndex: 1,
//                 animationDuration: "1300ms",
//               }}
//               className="p-2 px-3 right-to-left"
//             >
//               <b className="d-flex gap-2 align-items-center">
//                 <FcSearch size={"1.5rem"} /> Ask a Question
//               </b>
//               <div className="mt-1 " style={{ color: "grey" }}>
//                 Ask a question in natural language on Sales Documents.
//               </div>
//             </CCard>
//             <CCard
//               style={{
//                 boxShadow:
//                   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
//                 zIndex: 1,
//                 animationDuration: "1300ms",
//               }}
//               className="p-2 px-3 left-to-right "
//             >
//               {" "}
//               <b className="d-flex gap-2 align-items-center">
//                 <FcDocument size={"1.5rem"} /> Get Unstructured Data
//               </b>
//               <div className="mt-1 " style={{ color: "grey" }}>
//                 Get natural language summary from PDF Documents
//               </div>
//             </CCard>{" "}
//           </div>
//         ) : (
//           <>
//             {generatedData?.map((el, i) => {
//               return (
//                 <div
//                   ref={messageContainerRef}
//                   style={{ paddingRight: "20px" }}
//                   className="mb-4"
//                 >
//                   <ChatMessage key={i + 1} text={el.question} isUser={true} />
//                   {el.summary == "" ? (
//                     <div
//                       style={{ marginLeft: "10px" }}
//                       className="d-flex justify-content-start position-relative gap-2 align-items-start"
//                     >
//                       <CAvatar
//                         style={{
//                           width: "35px",
//                           height: "35px",
//                         }}
//                         className="font-md mt-2 border avatar"
//                         size="sm"
//                         src={aiImage}
//                       >
//                         {}
//                       </CAvatar>
//                       <div
//                         className={`chat-message other mt-2 align-items-center `}
//                         style={{ width: "75%" }}
//                       >
//                         <SkeletonTheme
//                           baseColor="#d9edf9"
//                           highlightColor="#2F8FC7"
//                         >
//                           <h4 style={{ width: "100%" }}>
//                             <Skeleton count={1} width={"50%"} />
//                           </h4>
//                           <p style={{ width: "100%" }}>
//                             <Skeleton height={12} width={"75%"} count={2} />
//                           </p>
//                         </SkeletonTheme>
//                       </div>
//                     </div>
//                   ) : el.summary
//                       ?.toLowerCase()
//                       .includes(
//                         "sorry couldn't find any information regarding that"
//                       ) ||
//                     (el.summary?.toLowerCase().includes("answer not found") &&
//                       el.dataset.length == 0) ? (
//                     <div name={i}>
//                       <ChatMessage
//                         key={i}
//                         text={
//                           "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
//                         }
//                         isUser={false}
//                         isError={true}
//                       />
//                     </div>
//                   ) : el.summary == "hello" ? (
//                     <div
//                       style={{ marginLeft: "10px" }}
//                       className="d-flex justify-content-start position-relative gap-2 align-items-start"
//                     >
//                       <CAvatar
//                         style={{
//                           width: "35px",
//                           height: "35px",
//                         }}
//                         className="font-md mt-2 border avatar"
//                         size="sm"
//                         src={aiImage}
//                       >
//                         {}
//                       </CAvatar>
//                       <div className={`chat-message other mt-2`}>
//                         {el.question
//                           ?.trim()
//                           .toLowerCase()
//                           .includes("what can you do for me") ? (
//                           <div>
//                             I can assist you with queries on Policy Documents.{" "}
//                             <p className="m-0 mt-1" style={{ fontWeight: 500 }}>
//                               Here are some example questions to get you
//                               started:
//                             </p>
//                             Why revenue of operations is more in Q2 than in Q3
//                             in year 2024?
//                             <br />
//                             Why domestic volume growth % stood 3% in Q2FY2024?
//                           </div>
//                         ) : (
//                           <div>
//                             Hello! How can I assist you today? <br />
//                             <p className="m-0 mt-1" style={{ fontWeight: 500 }}>
//                               Here are some example questions to get you
//                               started:
//                             </p>
//                             Can you identify any patterns or trends in the
//                             financial performance across the different quarters?
//                             <br />
//                             Has the EBITDA Margin increased or decreased from
//                             H1FY24 to Q3FY24, and by how much?
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   ) : (
//                     <>
//                       {!el.summary
//                         .toLowerCase()
//                         .includes("answer not found") ? (
//                         <>
//                           <div
//                             style={{ marginLeft: "10px" }}
//                             className="d-flex justify-content-start position-relative gap-2 align-items-start"
//                           >
//                             <CAvatar
//                               style={{
//                                 width: "35px",
//                                 height: "35px",
//                               }}
//                               className="font-md mt-2 border avatar"
//                               size="sm"
//                               src={aiImage}
//                             >
//                               {}
//                             </CAvatar>
//                             <div
//                               className={`chat-message other mt-2 align-items-center font-md`}
//                               style={{ maxWidth: "85%" }}
//                             >
//                               {el.summary.split("\n").map((str) => {
//                                 return <p className="p-0 m-0">{str}</p>;
//                               })}
//                             </div>
//                           </div>
//                           <div
//                             style={{ marginLeft: "10px" }}
//                             className="d-flex justify-content-start position-relative gap-2 align-items-start"
//                           >
//                             <CAvatar
//                               style={{
//                                 width: "35px",
//                                 height: "35px",
//                                 border: "none",
//                                 boxShadow: "none",
//                               }}
//                               size="sm"
//                             ></CAvatar>
//                             <div
//                               className={`chat-message other mt-2 align-items-center font-md`}
//                               style={{ width: "85%" }}
//                             >
//                               <CarouselCitations el={el} disabled={true} />
//                             </div>
//                           </div>
//                         </>
//                       ) : (
//                         ""
//                       )}
//                       {el.dataset.length > 0 ? (
//                         <>
//                           <div
//                             style={{ marginLeft: "10px" }}
//                             className="d-flex justify-content-start position-relative gap-2 align-items-start"
//                           >
//                             {el.summary
//                               .toLowerCase()
//                               .includes("answer not found") ? (
//                               <CAvatar
//                                 style={{
//                                   width: "35px",
//                                   height: "35px",
//                                 }}
//                                 className="font-md mt-2 border avatar"
//                                 size="sm"
//                                 src={aiImage}
//                               >
//                                 {}
//                               </CAvatar>
//                             ) : (
//                               <CAvatar
//                                 style={{
//                                   width: "35px",
//                                   height: "35px",
//                                   border: "none",
//                                   boxShadow: "none",
//                                 }}
//                                 size="sm"
//                               ></CAvatar>
//                             )}

//                             <div
//                               className={`chat-message other mt-2 align-items-center font-md`}
//                               style={{ width: "85%" }}
//                             >
//                               <OutputTable data={el.dataset} />
//                             </div>
//                           </div>
//                         </>
//                       ) : (
//                         ""
//                       )}
//                     </>
//                   )}
//                 </div>
//               );
//             })}
//           </>
//         )}
//       </div>
//       {generatedData.length >= 20 ? (
//         <CAlert
//           style={{
//             bottom: 100,
//             position: "fixed",
//             marginLeft: visible ? 450 : 300,
//             zIndex: 11,
//           }}
//           color={"warning"}
//           className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
//         >
//           <CIcon icon={cilWarning} size="sm" height={15} width={15} />
//           <div>
//             You have reached the chat limit of 20 questions. Please create New
//             Chat chat to continue asking more questions.
//           </div>
//         </CAlert>
//       ) : null}
//       <CForm
//         className="pt-3 "
//         style={{
//           bottom: 0,
//           position: "fixed",
//           paddingLeft: visible ? 250 : 0,
//           width: "100%",
//           background: "transparent",
//           zIndex: 11,
//           backdropFilter: "blur(5.7px)",
//           background:
//             "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
//           paddingBottom: "20px",
//         }}
//         onSubmit={(e) => handlePrompt(e)}
//       >
//         <CCard
//           style={{
//             backgroundColor: "transparent",
//             border: "none",
//             width: "70%",
//           }}
//           className="p-2  m-auto"
//         >
//           <CCol
//             className="d-flex align-items-center w-100 inputfocus m-auto "
//             style={{
//               height: "45px",
//               backgroundColor: "transparent",
//               border: "none",
//             }}
//           >
//             <CFormInput
//               className="border-0 inputfocus form-control position-relative font-md"
//               style={{
//                 backgroundColor: "white",
//                 boxShadow:
//                   "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
//                 // color: "#0B57D0",
//                 height: "30px",
//                 borderRadius: "20px",
//                 paddingRight: "40px",
//               }}
//               onChange={(e) => {
//                 e.preventDefault();
//                 setInputVal(e.target.value);
//               }}
//               value={inputVal}
//               placeholder="Ask a question"
//               disabled={loading || generatedData.length >= 20}
//             />
//             {inputVal.length > 0 ? (
//               <CIcon
//                 icon={cilX}
//                 size="md"
//                 className="position-absolute text-medium-emphasis hover"
//                 style={{ right: "75px" }}
//                 disabled={loading}
//                 onClick={() => {
//                   setInputVal("");
//                 }}
//               />
//             ) : null}
//             <CButton
//               type="submit"
//               disabled={inputVal == "" || loading || generatedData.length >= 20}
//               // variant="outline"
//               size="sm"
//               style={{
//                 borderRadius: "50%",
//                 height: "30px",
//                 width: "32px",
//                 boxShadow:
//                   "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
//                 background:
//                   "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
//                 border: "none",
//               }}
//               className="hover d-flex align-items-center justify-content-center p-0 mx-2"
//             >
//               {loading ? (
//                 <CSpinner
//                   size="sm"
//                   className="m-2 "
//                   style={{ color: "white" }}
//                 />
//               ) : (
//                 <CIcon
//                   icon={cilSearch}
//                   className="hover text-light p-0"
//                   size="md"
//                   // style={{ color: "#086EB5" }}
//                 />
//               )}
//             </CButton>
//           </CCol>
//         </CCard>
//       </CForm>
//     </div>
//   );
// };

// export default MaricoSales;

import { cilCommentSquare, cilHamburgerMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCol, CButton, CTabContent, CTabPane, CSidebar } from "@coreui/react";
import React, { useRef, useState } from "react";
// import MaricoPrompt from "./MaricoPrompt";
// import { Navbar } from "./Navbar";
import { PiChatsLight } from "react-icons/pi";
import { Navbar } from "../../components/Navbar";
import MaricoQuestion from "./MaricoQuestion";

const MaricoSales = () => {
  const [promptList, setPromptList] = useState([]);
  const [reuse, setReuse] = useState("");
  const [visible, setVisible] = useState(true);
  return (
    <div className="chat-background">
      <Navbar />
      <CSidebar
        visible={visible}
        position="fixed"
        placement={"start"}
        className="guide-sidebar"
      >
        <div style={{ height: "50px" }}></div>
        <div
          className="d-flex justify-content-between "
          style={{ top: "10px" }}
        >
          <CButton
            className="sticky new-chat mb-2 font-md"
            shape="rounded-pill"
            style={{ width: "80%" }}
            disabled={promptList.length < 1}
            onClick={() => {
              setPromptList([]);
            }}
          >
            <PiChatsLight size={"1.5em"} /> Clear Chat {promptList.length}/20
          </CButton>
          <CButton
            color="info"
            className="p-0 mb-2"
            style={{
              width: "35px",
            }}
            variant="outline"
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <CIcon icon={cilHamburgerMenu} className="text-dark" />
          </CButton>
        </div>
        <CCol style={{ height: "60vh", overflow: "scroll" }}>
          {promptList?.map((el, i) => {
            return (
              <CCol
                key={i}
                style={{ textOverflow: "ellipsis", color: "#086eb5" }}
                className="py-1 hover"
                onClick={() => {
                  if (i < promptList.length - 1) {
                    setReuse(el);
                  }
                }}
              >
                <CIcon
                  icon={cilCommentSquare}
                  size="sm"
                  width={12}
                  style={{ marginRight: "10px" }}
                />
                {el.length < 20 ? el : el.slice(0, 20) + "..."}
              </CCol>
            );
          })}
        </CCol>
      </CSidebar>

      <CTabContent>
        <CTabPane
          className="p-0 m-0 "
          role="tabpanel"
          aria-labelledby="home-tab"
          visible={true}
        >
          <MaricoQuestion
            setPromptList={setPromptList}
            promptList={promptList}
            reuse={reuse}
            visible={visible}
            setVisible={setVisible}
          />
        </CTabPane>
      </CTabContent>
    </div>
  );
};

export default MaricoSales;




