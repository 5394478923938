import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import {
  MdPause,
  MdPlayArrow,
  MdFastRewind,
  MdFastForward,
  MdOutlineAccessTime,
  MdVolumeDown,
  MdVolumeOff,
  MdVolumeUp,
} from "react-icons/md";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import {
  CCard,
  CRow,
  CCol,
  CCardBody,
  CCardText,
  CButton,
} from "@coreui/react";

const VideoRecogPlayer = ({ vid }) => {
  const playerRef = useRef();
  const [duration, setDuaration] = useState(0);
  const [position, setPosition] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [selectedStamp, setSelectedStamp] = useState(0);
  const [sortedFrames, setSortedFrames] = useState([]);
  const [marks, setMarks] = useState([]);
  const [volume, setVolume] = useState(30);
  const [prevVol, setPrevVol] = useState(0);
  const theme = useTheme();
  const onReady = useCallback(() => {
    if (!isReady) {
      let val = vid.sort((a, b) => {
        return a.frame_offset - b.frame_offset;
      });
      playerRef.current.seekTo(val[0].frame_offset, "seconds");
      setIsReady(true);
    }
  }, [isReady]);
  const handleSeekMouseUp = (e) => {
    playerRef.current.seekTo(parseFloat(e.target.value));
  };
  const convertSecToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minuteString = minutes < 10 ? "0" + minutes : minutes.toString();
    const secondString =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
    return minuteString + ":" + Number.parseFloat(secondString).toFixed(2);
  };
  const TinyText = styled(Typography)({
    fontSize: "11px",
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
  });
  useEffect(() => {
    let temp = [];
    let val = vid.sort((a, b) => {
      return a.frame_offset - b.frame_offset;
    });
    let marking = val.map((f) => {
      temp.push(parseFloat(f.frame_offset));
      return {
        value: parseFloat(f.frame_offset),
        // label: `${convertSecToMinutes(f.frame_offset)}`,
      };
    });
    setMarks(marking);
    setSortedFrames(temp);
  }, []);

  return (
    <>
      <div
        style={{
          padding: 10,
          borderRadius: 10,
          // width: 343,
          width: "100%",
          margin: "auto",
          zIndex: 1,
          backgroundColor: "rgba(255,255,255,0.6)",
          backdropFilter: "blur(10px)",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          // paddingTop: 10,
          animationDuration: "1000ms",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        }}
        className="fadein"
      >
        <div>
          {/* VIDEO player */}

          <div className="">
            <div
              style={{
                font: "14px",
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "270px",
              }}
              className="m-0 mb-2"
            >
              {vid[0].gcs_uri.split("/")[vid[0].gcs_uri.split("/").length - 1]}
            </div>
            <ReactPlayer
              ref={playerRef}
              url={vid[0].gcs_signed_uri}
              // controls
              style={{
                boxShadow: "rgb(137 127 127) 0px 5px 10px",
              }}
              height="160px"
              width={"280px"}
              onDuration={(e) => {
                setDuaration(e);
              }}
              playing={playing}
              onReady={onReady}
              volume={`${
                volume == 100 ? "1" : `0.${volume < 10 ? "0" + volume : volume}`
              }`}
            />
            <Slider
              sx={{
                color: "rgba(0,0,0,0.87)",
                height: 4,
                "& .MuiSlider-thumb": {
                  width: 8,
                  height: 8,
                  transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                  "&:before": {
                    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
                  },
                  "&.Mui-active": {
                    width: 20,
                    height: 20,
                  },
                },
                "& .MuiSlider-rail": {
                  opacity: 0.28,
                },
                "& .MuiSlider-mark": {
                  backgroundColor: "red",
                },
                width: "280px",
              }}
              aria-label="time-indicator"
              defaultValue={() => {
                let val = vid.sort((a, b) => {
                  return a.frame_offset - b.frame_offset;
                });
                setPosition(val[0].frame_offset);
                return val[0].frame_offset;
              }}
              // getAriaValueText={valuetext}
              step={null}
              size="small"
              valueLabelDisplay="off"
              min={0}
              value={position}
              max={duration}
              marks={marks}
              onChange={(e, val) => {
                setPosition(val);
                handleSeekMouseUp(e);
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: -2,
                width: "280px",
              }}
            >
              <TinyText>{convertSecToMinutes(position)}</TinyText>
              <TinyText>-{convertSecToMinutes(duration - position)}</TinyText>
            </Box>
            <div
              className="d-flex justify-content-center position-relative"
              style={{ width: "280px" }}
            >
              <MdFastRewind
                size={"1.5rem"}
                className="hover"
                onClick={() => {
                  let pos = sortedFrames.indexOf(position);
                  if (pos > 0) {
                    playerRef.current.seekTo(parseFloat(sortedFrames[pos - 1]));
                    setPosition(sortedFrames[pos - 1]);
                    setSelectedStamp(pos);
                  }
                }}
              />
              {playing ? (
                <MdPause
                  onClick={() => {
                    setPlaying(false);
                  }}
                  className="hover"
                  size={"1.5rem"}
                />
              ) : (
                <MdPlayArrow
                  onClick={() => {
                    setPlaying(true);
                  }}
                  className="hover"
                  size={"1.5rem"}
                />
              )}
              <MdFastForward
                className="hover"
                size={"1.5rem"}
                onClick={() => {
                  let pos = sortedFrames.indexOf(position);
                  if (pos < sortedFrames.length - 1) {
                    playerRef.current.seekTo(parseFloat(sortedFrames[pos + 1]));
                    setPosition(sortedFrames[pos + 1]);
                    setSelectedStamp(pos);
                  }
                }}
                // aria-disabled={position == sortedFrames[sortedFrames.length - 1]}
              />
              <div
                className="d-flex position-absolute start-0 align-items-center"
                style={{ gap: 18 }}
              >
                {volume == 0 ? (
                  <MdVolumeOff
                    className="hover"
                    size={"1rem"}
                    onClick={() => {
                      //   setPrevVol(volume);
                      setVolume(prevVol);
                    }}
                  />
                ) : volume < 50 ? (
                  <MdVolumeDown
                    className="hover"
                    onClick={() => {
                      setPrevVol(volume);
                      setVolume(0);
                    }}
                  />
                ) : (
                  <MdVolumeUp
                    className="hover"
                    onClick={() => {
                      setPrevVol(volume);
                      setVolume(0);
                    }}
                  />
                )}

                <Slider
                  onChange={(e, val) => {
                    setVolume(parseFloat(e.target.value));
                  }}
                  min={0}
                  max={100}
                  aria-label="Volume"
                  value={volume}
                  valueLabelDisplay="auto"
                  defaultValue={30}
                  sx={{
                    color: "rgba(0,0,0,0.87)",
                    "& .MuiSlider-track": {
                      border: "none",
                    },
                    "& .MuiSlider-thumb": {
                      width: 14,
                      height: 14,
                      backgroundColor: "#fff",
                      "&:before": {
                        boxShadow: "0 3px 4px rgba(0,0,0,0.4)",
                      },
                      "&:hover, &.Mui-focusVisible, &.Mui-active": {
                        boxShadow: "none",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      lineHeight: 1.2,
                      fontSize: 10,
                      background: "unset",
                      color: "black",
                      padding: 0,
                      width: 22,
                      height: 22,
                      borderRadius: "50% 50% 50% 0",
                      backgroundColor: "#fff",
                      transformOrigin: "bottom left",
                      transform:
                        "translate(50%, -100%) rotate(-45deg) scale(0)",
                      "&:before": { display: "none" },
                      "&.MuiSlider-valueLabelOpen": {
                        transform:
                          "translate(50%, -100%) rotate(-45deg) scale(1)",
                      },
                      "& > *": {
                        transform: "rotate(45deg)",
                      },
                    },
                    width: "50px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pt-2">
          <h6>
            {vid[0].gcs_uri.split("/")[vid[0].gcs_uri.split("/").length - 1]}
          </h6>
          <small className="text-medium-emphasis">
            Total Matched Timestamps: {sortedFrames.length}
          </small>
          <div className="d-flex font-md w-100" style={{ flexWrap: "wrap" }}>
            {sortedFrames.map((time, index) => {
              return (
                <CButton
                  variant="outline"
                  color={time == position ? "success" : "primary"}
                  size="sm"
                  //   shape="rounded-pill"
                  className={`m-1 p-1 hover font-sm d-flex gap-1 align-items-center`}
                  onClick={() => {
                    setPosition(time);
                    setSelectedStamp(index);
                    playerRef.current.seekTo(parseFloat(time));
                  }}
                >
                  <MdOutlineAccessTime />
                  {convertSecToMinutes(time)}
                </CButton>
              );
            })}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default VideoRecogPlayer;
