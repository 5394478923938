import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
} from "@coreui/react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilSend, cilWarning, cilHamburgerMenu } from "@coreui/icons";
import CellTable from "./CellTable";
import { FaFilePdf } from "react-icons/fa";
import RPGSidebar from "./RPGSidebar";
import { FcDataSheet } from "react-icons/fc";
const PdfToCsv = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);
  const [visible, setVisible] = useState(true);
  const messageContainerRef = useRef(null);
  const [audioFile, setAudioFile] = useState(null);
  const [base64PDFFile, setBase64PDFFile] = useState("");

  const fileInputRef = useRef(null);
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handleFileSelect = (files) => {
    if (files.length > 0) {
      for (const fl of files) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Str = reader.result;
          const splitBase64Strings = base64Str.split(";");
          const splitBase64Str = splitBase64Strings[1].split(",");
          setBase64PDFFile(splitBase64Str[1]);
        };
        reader.readAsDataURL(fl);
      }
    }
  };

  const handlePrompt = async (e) => {
    e.preventDefault();
    let responseData = {};
    setAudioFile(null);
    setGeneratedData([
      ...generatedData,
      {
        question: inputVal,
        answer: "",
      },
    ]);
    setInputVal("");
    setLoading(true);

    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/rpg_extraction",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data: {
        file_name: inputVal,

        pdf_encoded: base64PDFFile,
      },
    };
    try {
      let response = await axios(config);
      if (response.data != undefined) {
        console.log("response", response.data);
        setGeneratedData([
          ...generatedData,
          {
            question: inputVal,
            answer: [response.data.Output],
          },
        ]);
        setLoading(false);
      }
    } catch (err) {
      console.log("Response Error", err);
      setGeneratedData([
        ...generatedData,
        {
          question: inputVal,
          answer: "answer not found",
        },
      ]);
      setLoading(false);
    }

    // Clear the selected file name
    fileInputRef.current.value = "";
  };
  function capitalizeFirstLetter(string) {
    const firstChar = string.charAt(0).toUpperCase();
    const remainingChars = string.slice(1);
    return `${firstChar}${remainingChars}`;
  }

  return (
    <div
      className="font-md"
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `URL(https://static.vecteezy.com/system/resources/previews/014/526/827/original/aesthetic-pastel-gradient-purple-blue-and-pink-gradient-wallpaper-illustration-perfect-for-backdrop-wallpaper-background-banner-vector.jpg)`,
        backgroundPosition: "center",
      }}
    >
      <Navbar />
      <RPGSidebar
        generatedData={generatedData}
        setGeneratedData={setGeneratedData}
        visible={visible}
        setVisible={setVisible}
        setInputVal={setInputVal}
      />
      {!visible ? (
        <CButton
          className="p-0 position-absolute"
          style={{
            marginLeft: "10px",
            width: "35px",
            top: "70px",
            background: "coral",
            border: "1px solid coral",
            color: "white",
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-white" />
        </CButton>
      ) : null}
      <div
        ref={messageContainerRef}
        style={{
          backgroundColor: "#fffff",
          marginLeft: visible ? "250px" : "100px",
          marginRight: visible ? "auto" : "100px",
          // marginTop: "10px",
          overflowY: "auto",
          height: "90vh",
          textAlign: "left",
          paddingBottom: "80px",
          transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        }}
      >
        {generatedData.length == 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1,1fr)",
              gap: 25,
              margin: "auto",
              width: "80%",
              marginTop: "40px",
            }}
          >
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1000ms",
              }}
              className="p-2 px-3 left-to-right"
            >
              <b className="d-flex gap-2 align-items-center">
                <FaFilePdf style={{ color: "#ff2c21" }} size={"1.2rem"} />{" "}
                Upload PDF Document.
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Upload a document from which you want to extract table.
              </div>
            </CCard>
            <CCard
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                zIndex: 1,
                animationDuration: "1300ms",
              }}
              className="p-2 px-3 right-to-left"
            >
              {" "}
              <b className="d-flex gap-2 align-items-center">
                <FcDataSheet size={"1.5rem"} /> Get Structured Data
              </b>
              <div className="mt-1 " style={{ color: "grey" }}>
                Returns results from Documents in structured format.
              </div>
            </CCard>
          </div>
        ) : (
          <>
            {generatedData?.map((el, i) => {
              return (
                <div
                  style={{ paddingRight: "20px", marginTop: 10 }}
                  className="mb-2"
                >
                  <div
                    className={`chat-message user mt-2 align-items-center d-flex gap-2 justify-content-start `}
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      marginLeft: "20px",
                      width: "max-content",
                      maxWidth: "100%",
                    }}
                  >
                    <FaFilePdf size={"1.2rem"} />
                    {el.question}
                  </div>
                  {el.answer == "" ? (
                    <div
                      style={{
                        marginLeft: "20px",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      }}
                      className={`transcript-message  other align-items-center `}
                    >
                      <SkeletonTheme
                        baseColor="#f4d9ff"
                        highlightColor="#3EB7EF"
                      >
                        <h4 style={{ width: "100%" }}>
                          <Skeleton count={1} width={"40%"} />
                        </h4>
                        <p style={{ width: "100%" }}>
                          <Skeleton height={12} width={"90%"} count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ) : el.answer == "answer not found" ? (
                    <div name={i}>
                      <div
                        className={`chat-message error align-items-center d-flex gap-2`}
                        style={{ marginLeft: "20px" }}
                      >
                        <AiOutlineExclamationCircle size={"1em"} />
                        We couldn't find any data matching your request. Please
                        try again later.
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`chat-message other align-items-center `}
                      style={{
                        marginLeft: "20px",
                        background:
                          "linear-gradient(135deg, rgba(238,244,248,1) 0%,rgba(242,242,249,1) 100%)",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      }}
                    >
                      <b className="mb-2">Extracted Table:</b>
                      <CCard
                        style={{
                          width: "100%",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          border: "none",
                          maxHeight: 270,
                          overflow: "scroll",
                          background: "#ffffff",
                          padding: "1px 3px 1px 3px",
                        }}
                      >
                        {/* <OutputTable data={el.answer} /> */}

                        <table>
                          <thead
                            style={{
                              position: "sticky",
                              top: -2,
                              fontSize: "12px",
                              backgroundColor: "#def4fc",
                              maxHeight: "15px",
                            }}
                          >
                            <tr style={{ backgroundColor: "#def4fc" }}>
                              {Object.keys(el.answer[0]).map((cl) => {
                                return (
                                  <td
                                    style={{
                                      width: "max-content",
                                      color: "#7a7676",
                                      backgroundColor: "#e7f8ff",
                                      whiteSpace: "nowrap",
                                    }}
                                    className="border px-2"
                                    scope="col"
                                  >
                                    {cl}
                                  </td>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "11px" }}>
                            <tr>
                              {Object.values(el.answer[0]).map((value, i) => {
                                return (
                                  <td
                                    style={{
                                      minWidth:
                                        Object.keys(el.answer[0])[i] ==
                                        "Document_schedule"
                                          ? ""
                                          : (value.length * 3) / 2,
                                      backgroundColor:
                                        Object.keys(el.answer[0])[i] ==
                                        "Document_schedule"
                                          ? "#FFFEC4"
                                          : "white",
                                    }}
                                    className="border pt-1 px-1"
                                  >
                                    {Object.keys(el.answer[0])[i] ==
                                    "Document_schedule" ? (
                                      <CellTable data={value} />
                                    ) : (
                                      value
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </CCard>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
      {generatedData.length >= 20 ? (
        <CAlert
          style={{
            bottom: 100,
            position: "fixed",
            marginLeft: visible ? 450 : 300,
            zIndex: 11,
          }}
          color={"warning"}
          className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
        >
          <CIcon icon={cilWarning} size="sm" height={15} width={15} />
          <div>
            You have reached the chat limit of 20 questions. Please create New
            Chat chat to continue asking more questions.
          </div>
        </CAlert>
      ) : null}
      <CForm
        className="pt-3 "
        style={{
          bottom: 0,
          position: "fixed",
          left: visible ? 125 : 0,
          width: "100%",
          background: "transparent",
          paddingBottom: "20px",
          zIndex: 11,
          backdropFilter: "blur(5.7px)",
          transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        }}
        onSubmit={(e) => handlePrompt(e)}
      >
        <CCard
          style={{
            backgroundColor: "transparent",
            border: "none",
            width: "70%",
          }}
          className="p-2  m-auto"
        >
          <CCol
            className="d-flex align-items-center w-100 inputfocus m-auto "
            style={{
              height: "45px",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CFormInput
              className="border-0 inputfocus form-control"
              style={{
                backgroundColor: "#f0feff",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                // color: "#0B57D0",
                height: "35px",
                borderRadius: "5px",
                fontSize: 14,
              }}
              // onChange={(e) => {
              //   e.preventDefault();
              //   setInputVal(e.target.value);
              // }}
              type="file"
              ref={fileInputRef}
              accept="application/pdf"
              onChange={(e) => {
                e.preventDefault();
                if (e.target.files.length > 0) {
                  setInputVal(e.target.files[0]?.name);
                  setAudioFile(URL.createObjectURL(e.target.files[0]));
                  handleFileSelect(e.target.files);
                }
              }}
              // value={inputVal}
              placeholder="Upload PDF"
              disabled={loading || generatedData.length >= 20}
            />
            <button
              type="submit"
              disabled={inputVal == "" || loading || generatedData.length >= 20}
              className="button-33 mx-2"
              role="button"
            >
              {loading ? (
                <CSpinner size="sm" />
              ) : (
                <CIcon icon={cilSend} className="hover p-0" size="md" />
              )}
            </button>
          </CCol>
        </CCard>
      </CForm>
    </div>
  );
};

export default PdfToCsv;

/* CSS */
