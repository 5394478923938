import { CCollapse } from "@coreui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const Categorybox = (props) => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="font-lg">
      <div
        onClick={() => {
          setVisible(!visible);
        }}
        className="category-button d-flex align-items-center gap-1"
        style={{ color: visible ? "coral" : "" }}
      >
        {props.icon} {props.title}
      </div>
      <CCollapse visible={visible} className="ps-4 text-medium-emphasis">
        {props.subcategory.map((el) => {
          return (
            <div
              className="d-flex align-items-center gap-1 hover"
              onClick={() => {
                props.setSelectedCategory({
                  category: props.title,
                  subcateg: el.name,
                });
              }}
            >
              {el.icon}
              {el.name}
            </div>
          );
        })}
      </CCollapse>
    </div>
  );
};

export default Categorybox;
