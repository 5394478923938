import {
  CSidebar,
  CButton,
  CTooltip,
  CCol,
  CCardTitle,
  CAccordionItem,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import React from "react";
import { PiChatsLight } from "react-icons/pi";
import { useNavigate } from "react-router";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilAccountLogout,
  cilHamburgerMenu,
} from "@coreui/icons";
import { CiCircleQuestion } from "react-icons/ci";
import { IoIosChatbubbles } from "react-icons/io";
import { HiXCircle } from "react-icons/hi";
const FinanceSidebar = ({
  generatedData,
  setGeneratedData,
  visible,
  setVisible,
  setCurrent,
  setImgData,
  setUnStrucData,
  setStrucData,
  setInputVal,
}) => {
  const navigate = useNavigate();

  return (
    <CSidebar className="new-sidebar">
      <div
        className="d-flex align-items-center justify-content-between p-2 mb-1"
        style={{
          boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
          clipPath: "inset(0px 0px -15px 0px)",
        }}
      >
        <div className="d-flex justify-content-center gap-3">
          <CCardTitle className="title-sidebar">{"Search"}</CCardTitle>
        </div>
        <div className="d-flex justify-content-center gap-3">
          <div
            className="rolling-chat-new "
            onClick={() => {
              setGeneratedData([]);
              setCurrent({});
              setStrucData("null");
              setUnStrucData("null");
              setImgData("null");
              setInputVal("");
            }}
          >
            <HiXCircle size={"1.6rem"} />
            <span className="font-lg ms-1 mt-2" style={{ fontWeight: 600 }}>
              Clear Search
            </span>
          </div>
        </div>
      </div>

      <div style={{ overflow: "scroll" }} className="px-0">
        <CAccordion alwaysOpen activeItemKey={1} flush>
          {/* <CAccordionItem className="p-0" itemKey={2}>
            <CAccordionHeader
              style={{ color: "#0b2e50" }}
              className="font-md p-0"
            >
              Architecture Diagram
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column align-items-end">
              <BsZoomIn
                onClick={() => {
                  setVisible(true);
                }}
                className="hover logout-icon"
                size={"1rem"}
              />
              <CImage
                onDoubleClick={() => {
                  setVisible(true);
                }}
                className="w-100 hover"
                src={props.ArcDia}
              />
              <CModal
                visible={visible}
                size="xl"
                onClose={() => {
                  setVisible(false);
                }}
              >
                <CModalHeader>Architecture Diagram</CModalHeader>
                <CModalBody>
                  <CImage className="w-100" src={props.ArcDia} />
                </CModalBody>
              </CModal>
            </CAccordionBody>
          </CAccordionItem> */}

          <CAccordionItem itemKey={3}>
            <CAccordionHeader>Asked Questions</CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-2 p-2">
              <CCol style={{ height: "70vh", overflow: "scroll" }}>
                {generatedData?.map((el, i) => {
                  return (
                    <CTooltip className="font-sm" content={el.question}>
                      <CCol
                        key={i}
                        style={{
                          color: "#0B57D0",
                        }}
                        // className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 zoom hover"
                      >
                        <div
                        // style={{
                        //   whiteSpace: "nowrap",
                        //   overflow: "hidden",
                        //   textOverflow: "ellipsis",
                        // }}
                        >
                          {/* <CIcon
                            icon={cilCommentSquare}
                            size="sm"
                            width={12}
                            style={{ marginRight: "10px" }}
                          /> */}
                          {/* {el.question} */}
                          <div className="d-flex gap-2 mb-1 ">
                            <CiCircleQuestion
                              size={"2rem"}
                              style={{ color: "#64be64" }}
                            />{" "}
                            <div
                              style={{ width: "90%" }}
                              className="text-medium-emphasis d-flex align-items-center"
                            >
                              {el.question}
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CTooltip>
                  );
                })}
              </CCol>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
      {/* <div className="d-flex justify-content-between " style={{ top: "10px" }}>
        <CButton
          className="sticky new-chat mb-2 font-md"
          shape="rounded-pill"
          style={{ width: "80%" }}
          disabled={generatedData.length < 1}
          onClick={() => {
            setGeneratedData([]);
            setCurrent({});
            setStrucData("null");
            setUnStrucData("null");
            setImgData("null");
            setInputVal("");
          }}
        >
          <PiChatsLight size={"1.5em"} /> Clear Chat {generatedData.length}/20
        </CButton>
        <CButton
          color="info"
          className="p-0 mb-2"
          style={{
            width: "35px",
          }}
          variant="outline"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <CIcon icon={cilHamburgerMenu} className="text-dark" />
        </CButton>
      </div> */}
      {/* <CCol style={{ height: "60vh", overflow: "scroll" }}>
        {generatedData?.map((el, i) => {
          return (
            <CTooltip className="font-sm" content={el.question}>
              <CCol
                onClick={() => {
                  // console.log(el);
                  // setCurrent(el);
                  // setImgData(el.img);
                  // setStrucData(el.answer);
                  // setUnStrucData(el.result);
                  // setInputVal(el.question);
                }}
                key={i}
                style={{
                  color: "#0B57D0",
                }}
                className="p-1 d-flex justify-content-between align-items-center font-md border rounded-2 mt-1 zoom hover"
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <CIcon
                    icon={cilCommentSquare}
                    size="sm"
                    width={12}
                    style={{ marginRight: "10px" }}
                  />
                  {el.question}
                </div>
              </CCol>
            </CTooltip>
          );
        })}
      </CCol> */}
    </CSidebar>
  );
};

export default FinanceSidebar;
