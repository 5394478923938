import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import DownloadComponent from "./DownloadComponent";
import { FcInfo } from "react-icons/fc";
import OutputTable from "./OutputTable";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  CButton,
  CCard,
  CCol,
  CTooltip,
  CAlert,
  CCardText,
  CCollapse,
  CCardBody,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilChart, cilChartLine } from "@coreui/icons";
import axios from "axios";
const ChartTypesFinance = (props) => {
  const [chartVis, setChartVis] = useState(true);
  const [chartType, setChartType] = useState("bar");
  const [disableChart, setDisableChart] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [loading, setLoading] = useState(false);
  const [explanations, setExplanations] = useState();
  useEffect(() => {
    getExplanation(props.question, props.data);
  }, []);
  const getExplanation = async (question, data) => {
    setLoading(true);
    let config1 = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/maruti-get-explain",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: question,
        dataset: data,
      },
    };
    try {
      let response = await axios(config1);
      setLoading(false);
      setExplanations(response.data.summary);
    } catch (err) {
      console.log("Explanation Error:", err);
      setExplanations("No Explanation found.");
      setLoading(false);
    }
  };
  return (
    <div>
      <div
        style={{
          position: "relative",
          background: "white",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
        className="m-0 border rounded-2 p-1"
      >
        <div className="d-flex justify-content-between p-0">
          <CCard
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: "none",
              height: "100%",
              background: "white",
            }}
            className="p-1 d-flex justify-content-left"
          ></CCard>
          <CCard
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: "none",
              height: "100%",
            }}
            className="p-1"
          >
            <DownloadComponent data={props.data} />
          </CCard>
        </div>

        <CCard
          style={{
            width: "100%",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            border: "none",
            maxHeight: 270,
            overflow: "scroll",
            background: "#ffffff",
            padding: "1px 3px 1px 3px",
          }}
        >
          <OutputTable data={props.data} />
        </CCard>

        <CAlert
          style={{
            backgroundColor: "#e5ffef",
            border: "1px solid #e5ffef",
          }}
          className="p-0 m-0"
        >
          <CCardBody className="p-2 d-flex align-items-center gap-2">
            <FcInfo size={"1em"} />{" "}
            {loading ? <CSpinner color="success" size="sm" /> : explanations}
          </CCardBody>
        </CAlert>
      </div>
      {chartVis ? (
        <div style={{ background: "white" }} className="rounded">
          <BarChart
            chartType={chartType}
            data={props.data}
            setDisableChart={setDisableChart}
            disableChart={disableChart}
            setChartVis={setChartVis}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChartTypesFinance;
