import React from "react";
import { MdFlightTakeoff, MdFlightLand } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { CCol } from "@coreui/react";
const FlightCard = (props) => {
  return (
    <div
      className=" rounded"
      style={{
        minWidth: "300px",
        backgroundColor: "transparent",
        backgroundImage:
          "URL(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMoT4IuZUQworlmxixwpIvyBgWO3cuCrbvms75bOVj8pVP_SQckqHH_JZlO9UA9wAxCT0&usqp=CAU)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        border: "none",
        // transform: "scaleX(-1)",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "transparent",
          backdropFilter: "blur(6px)",
          color: "black",
          fontWeight: 500,
        }}
        className="p-2 rounded "
      >
        {/* destination and arrival data */}
        <div className="d-flex justify-content-between mb-2">
          <p className="font-md m-0 p-0 text-decoration-underline">
            Departure ({props.date})
          </p>
          <p className="font-md m-0 p-0 text-decoration-underline">Arrival</p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <CCol className="d-flex align-items-center gap-1">
              <MdFlightTakeoff /> {props.data.departure_city} (
              {props.data.departure_country})
            </CCol>
            <CCol className="font-sm d-flex justify-content-center">
              ({props.data.departure_airport_iata})
            </CCol>
          </div>
          <BsArrowRight size={"1.5em"} />
          <div>
            <CCol className="d-flex align-items-center gap-1">
              <MdFlightLand /> {props.data.arrival_City} (
              {props.data.arrival_country})
            </CCol>
            <CCol className="font-sm d-flex justify-content-center">
              ({props.data.arrival_airport_iata})
            </CCol>
          </div>
        </div>
        <div
          className="m-0 my-2 p-0 "
          style={{ border: "0.5px solid grey" }}
        ></div>
        {/* Airline and planes data */}
        <div className="mt-1 d-flex justify-content-between">
          <div className="w-50">
            <p className="font-md m-0 p-0 text-decoration-underline">
              Available Flights
            </p>
            <div className="d-flex m-0 p-0">
              {props.data.planes.map((el, i) => {
                return (
                  <p className="">
                    {el}
                    {i == props.data.planes.length - 1 ? "" : ", "}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="w-50">
            <p className="font-md m-0 p-0 text-decoration-underline">Airline</p>
            <div className="d-flex">
              {props.data.airline_iata == "AI"
                ? "Air India"
                : props.data.airline_iata}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
