import React, { useState, useRef, useEffect } from "react";
import {
  CForm,
  CFormInput,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CAvatar,
  CImage,
  CRow,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilSearch, cilSend, cilX } from "@coreui/icons";
import { Navbar } from "../../components/Navbar";
import { ChatMessage } from "../../components/ChatMessage";
// import aiImage from "../../assets/images/OIG.png";
import VideoDescriptionSidebar from "./VideoDescriptionSidebar";
import "react-loading-skeleton/dist/skeleton.css";
import VideoRecogCard from "./VideoRecogCard";
import { FcAssistant, FcVideoCall } from "react-icons/fc";
import CommonSidebar from "../../components/CommonSidebar";
import aiImage from "../../assets/images/ai_image.png";
// import ImageDescriptionSidebar from "../components/ImageDescriptionSidebar";
import { IoIosChatbubbles } from "react-icons/io";
import aiAvatarImage from "../../assets/images/OIG.png";
import ArcDia from "../../assets/images/video-arch-dia.svg";

const VideoDescription = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));

  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const convertToBold = (text) => {
    const parts = text.split("**");
    return parts.map((part, index) => {
      return index % 2 === 0 ? (
        <span key={index}>{part}</span>
      ) : (
        <strong key={index}>{part}</strong>
      );
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setLoading(true);
      getVideoMetadata(inputPrompt);
    }
  };

  const getVideoMetadata = async (inputPrompt) => {
    console.log(1);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/CF1-ask_questions1"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/INT-CF1-ask_questions1",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: inputPrompt,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("video data", response);
        if (response.data.video_search_data.length > 0) {
          setGeneratedData([
            ...generatedData,
            {
              question: inputPrompt,
              video: response.data.video_search_data,
            },
          ]);
        } else {
          setGeneratedData([
            ...generatedData,
            {
              question: inputPrompt,
              summary: "Answer Not Found",
            },
          ]);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "Answer Not Found",
        },
      ]);
    }
  };

  function getGeneratedContentValues(arrayOfObjects) {
    return arrayOfObjects.map(
      (obj) => obj.data.generated_ads[0].generated_content
    );
  }

  const handleGeneratePrompt = async (
    user_prompt,
    question,
    videoArr,
    selectedCards
  ) => {
    console.log(user_prompt, question, videoArr, selectedCards);
    setGeneratedData([
      ...generatedData,
      { question: user_prompt, summary: "" },
    ]);
    setLoading(true);
    const promises = videoArr.flatMap((el, i) => {
      if (selectedCards.includes(i)) {
        // Use flatMap to flatten the array of promises
        return el.map((frame) => {
          return getGeneratedContent(
            frame.frame_offset,
            frame.frame_details,
            frame.gcs_uri,
            user_prompt,
            question
          );
        });
      } else {
        // If the card is not selected, return a resolved promise with null or some default value
        return [Promise.resolve(null)];
      }
    });
    console.log("promises", promises);
    try {
      let response = await Promise.all(promises);
      console.log(response);
      let filtered = response.filter((el) => {
        return el != null;
      });
      const generatedContentValues = getGeneratedContentValues(filtered);
      console.log(user_prompt, generatedContentValues);
      getFinalContent(user_prompt, generatedContentValues);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getGeneratedContent = async (
    frame_offset,
    frame_details,
    uri,
    user_prompt,
    question
  ) => {
    console.log(2);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? " https://us-central1-generativeai-coe.cloudfunctions.net/CF2-generate_content_parallel"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/INT-CF2-generate_content_parallel",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        question: question,
        user_prompt: user_prompt,
        gcs_uri: uri,
        frame_offset: frame_offset,
        frame_details: frame_details,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("response 2", response);
        return response;
      }
    } catch (error) {
      console.log("data error", error);
      return null;
    }
  };
  const getFinalContent = async (user_prompt, content) => {
    console.log(3);
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/CF3-generate_final_content"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/INT-CF3-generate_final_content",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
      data: { generated_content_list: content, user_prompt: user_prompt },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("Final content", response);
        setLoading(false);
        setGeneratedData([
          ...generatedData,
          {
            question: user_prompt,
            summary: response.data.Final_Content,
          },
        ]);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: user_prompt,
          summary: "Answer Not Found",
        },
      ]);
    }
  };

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />

      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={generatedData}
            setGeneratedData={setGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"Generate Content From Video"}
            ArcDia={ArcDia}
            askQuestion={true}
          />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container" ref={messageContainerRef}>
            {generatedData.length == 0 ? (
              <>
                <div
                  style={{
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                       style={{
                        zIndex: 1,
                      }}
                      className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcVideoCall size={"1.5rem"} /> Input a prompt to get
                      Videos with relevant timestamps
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask a prompt in natural language to get video frames with
                      relevant scene.
                    </div>
                  </CCard>
                  <CCard
                     style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcAssistant size={"1.5rem"} />
                      Generate content based on those frames
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Get natural language generated content from the Google
                      Gemini Pro model on selected frames.
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {generatedData?.map((el, i) => {
              return (
                <div
                  ref={messageContainerRef}
                  style={{ paddingRight: "20px" }}
                  className="mb-4"
                >
                  <ChatMessage key={i + 1} text={el.question} isUser={true} />
                  {el.summary == "" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        S
                      </CAvatar>
                      <div
                        className={`chat-message other mt-2 align-items-center`}
                        style={{ width: "75%" }}
                      >
                        <SkeletonTheme
                          baseColor="#d9edf9"
                          highlightColor="#2F8FC7"
                          width={"300px"}
                        >
                          <h4 style={{ width: "100%" }}>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p style={{ width: "100%" }}>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </div>
                    </div>
                  ) : el.summary
                      ?.toLowerCase()
                      .includes(
                        "sorry couldn't find any information regarding that"
                      ) ||
                    el.summary?.toLowerCase().includes("answer not found") ? (
                    <div name={i}>
                      <ChatMessage
                        key={i}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : el.summary == "hello" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        {}
                      </CAvatar>
                      <div className={`chat-message other mt-2`}>
                        Hello! How can I assist you today?
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiAvatarImage}
                        >
                          {}
                        </CAvatar>
                        {el.video ? (
                          <VideoRecogCard
                            video={el.video}
                            img="Error"
                            handleGeneratePrompt={handleGeneratePrompt}
                            question={el.question}
                            loading={loading}
                          />
                        ) : (
                          <div
                            className={`chat-message other mt-2 align-items-center font-md`}
                            style={{ maxWidth: "85%" }}
                          >
                            {el.summary.split("\n").map((str) => {
                              return <p className="p-0 m-0">{str}</p>;
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            {/* {generatedData?.map((el, i) => {
              return (
                <div
                  ref={messageContainerRef}
                  style={{ paddingRight: "20px" }}
                  className="mb-4"
                >
                  <ChatMessage key={i + 1} text={el.question} isUser={true} />
                  {el.summary == "" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >

                      </CAvatar>
                      <div
                        className={`chat-message other mt-2 align-items-center`}
                        style={{ width: "75%" }}
                      >
                        <SkeletonTheme
                          baseColor="#d9edf9"
                          highlightColor="#2F8FC7"
                        >
                          <h4 style={{ width: "100%" }}>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p style={{ width: "100%" }}>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </div>
                    </div>
                  ) : el.summary
                    ?.toLowerCase()
                    .includes(
                      "sorry couldn't find any information regarding that"
                    ) ||
                    el.summary?.toLowerCase().includes("answer not found") ? (
                    <div name={i}>
                      <ChatMessage
                        key={i}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : el.summary == "hello" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiImage}
                      >
                        { }
                      </CAvatar>
                      <div className={`chat-message other mt-2`}>
                        Hello! How can I assist you today?
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiImage}
                        >
                          { }
                        </CAvatar>
                        <div
                          className={`chat-message other mt-2 align-items-center font-md`}
                          style={{ maxWidth: "85%" }}
                        >
                          {el.summary.split("\n").map((str) => {
                            return <p className="p-0 m-0">{str}</p>;
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })} */}
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => handlePrompt(e)}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setGeneratedData([]);
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder={"Ask a question"}
                  disabled={loading}
                />
                <CButton
                  type="submit"
                  disabled={inputVal == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </CCol>
        {/* <CForm
          className="pt-3 "
          style={{
            bottom: 0,
            position: "fixed",
            paddingLeft: visible ? 300 : 0,
            width: "100%",
            background: "transparent",
            zIndex: 11,
            backdropFilter: "blur(5.7px)",
            background:
              "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
          }}
          onSubmit={(e) => handlePrompt(e)}
        >
          <div
            className="rolling-chat "
            onClick={() => {
              setGeneratedData([]);
              // setBase64ImageFile("");
            }}
          >
            <IoIosChatbubbles size={"2rem"} />
            <span className="font-lg">New Chat</span>
          </div>
          <CCard
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "70%",
            }}
            className="p-2 "
          >
            <CCol
              className="d-flex align-items-center w-100 inputfocus m-auto "
              style={{
                height: "45px",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <CFormInput
                className="border-0 inputfocus form-control position-relative font-md"
                style={{
                  backgroundColor: "white",
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  // color: "#0B57D0",
                  height: "30px",
                  borderRadius: "20px",
                  paddingRight: "40px",
                }}
                onChange={(e) => {
                  e.preventDefault();
                  setInputVal(e.target.value);
                }}
                value={inputVal}
                placeholder="Ask a question"
                disabled={loading }
              />
              {inputVal.length > 0 ? (
                <CIcon
                  icon={cilX}
                  size="md"
                  className="position-absolute text-medium-emphasis hover"
                  style={{ right: "75px" }}
                  disabled={loading}
                  onClick={() => {
                    setInputVal("");
                  }}
                />
              ) : null}
              <CButton
                type="submit"
                disabled={inputVal == "" || loading || generatedData.length >= 20}
                // variant="outline"
                size="sm"
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "32px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  background:
                    "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
                  border: "none",
                }}
                className="hover d-flex align-items-center justify-content-center p-0 mx-2"
              >
                {loading ? (
                  <CSpinner
                    size="sm"
                    className="m-2 "
                    style={{ color: "white" }}
                  />
                ) : (
                  <CIcon
                    icon={cilSearch}
                    className="hover text-light p-0"
                    size="md"
                  />
                )}
              </CButton>
            </CCol>
          </CCard>
        </CForm> */}
      </CRow>
    </div>

    // <div
    //   style={{
    //     transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
    //     background:
    //       "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
    //   }}
    // >
    //   <Navbar />
    //   <VideoDescriptionSidebar
    //     generatedData={generatedData}
    //     setGeneratedData={setGeneratedData}
    //     visible={visible}
    //     setVisible={setVisible}
    //   />
    //   {!visible ? (
    //     <CButton
    //       color="info"
    //       className="p-0 position-fixed"
    //       style={{
    //         marginLeft: "10px",
    //         width: "35px",
    //         top: "60px",
    //         zIndex: 12,
    //       }}
    //       onClick={() => {
    //         setVisible(!visible);
    //       }}
    //     >
    //       <CIcon icon={cilHamburgerMenu} className="text-dark" />
    //     </CButton>
    //   ) : null}
    //   <div
    //     style={{
    //       backgroundColor: "#fffff",
    //       paddingLeft: visible ? "270px" : "100px",
    //       paddingRight: visible ? "0px" : "100px",
    //       marginTop: "10px",
    //       overflowY: "auto",
    //       height: "90vh",
    //       textAlign: "left",
    //       paddingBottom: "80px",
    //       transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
    //     }}
    //   >
    //     {generatedData.length == 0 ? (
    //       <div
    //         style={{
    //           display: "grid",
    //           gridTemplateColumns: "repeat(1,1fr)",
    //           gap: 25,
    //           margin: "auto",
    //           width: "80%",
    //         }}
    //         className="mt-3"
    //       >
    //         <CCard
    //           style={{
    //             boxShadow:
    //               "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    //             zIndex: 1,
    //             animationDuration: "1300ms",
    //           }}
    //           className="p-2 px-3 right-to-left"
    //         >
    //           <b className="d-flex gap-2 align-items-center">
    //             <FcVideoCall size={"1.5rem"} />
    //             Input a prompt to get Videos with relevant timestamps
    //           </b>
    //           <div className="mt-1 " style={{ color: "grey" }}>
    //             Ask a prompt in natural language to get video frames with
    //             relevant scene.
    //           </div>
    //         </CCard>
    //         <CCard
    //           style={{
    //             boxShadow:
    //               "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    //             zIndex: 1,
    //             animationDuration: "1300ms",
    //           }}
    //           className="p-2 px-3 left-to-right "
    //         >
    //           {" "}
    //           <b className="d-flex gap-2 align-items-center">
    //             <FcAssistant size={"1.5rem"} /> Generate content based on those
    //             frames.
    //           </b>
    //           <div className="mt-1 " style={{ color: "grey" }}>
    //             Get natural language generated content from the Google Gemini
    //             Pro model on selected frames.
    //           </div>
    //         </CCard>
    //       </div>
    //     ) : (
    //       <>
    //         {generatedData?.map((el, i) => {
    //           return (
    //             <div
    //               ref={messageContainerRef}
    //               style={{ paddingRight: "20px" }}
    //               className="mb-4"
    //             >
    //               <ChatMessage key={i + 1} text={el.question} isUser={true} />
    //               {el.summary == "" ? (
    //                 <div
    //                   style={{ marginLeft: "10px" }}
    //                   className="d-flex justify-content-start position-relative gap-2 align-items-start"
    //                 >
    //                   <CAvatar
    //                     style={{
    //                       width: "35px",
    //                       height: "35px",
    //                     }}
    //                     className="font-md mt-2 border avatar"
    //                     size="sm"
    //                     src={aiImage}
    //                   >
    //                     S
    //                   </CAvatar>
    //                   <div
    //                     className={`chat-message other mt-2 align-items-center`}
    //                     style={{ width: "75%" }}
    //                   >
    //                     <SkeletonTheme
    //                       baseColor="#d9edf9"
    //                       highlightColor="#2F8FC7"
    //                       width={"300px"}
    //                     >
    //                       <h4 style={{ width: "100%" }}>
    //                         <Skeleton count={1} width={"50%"} />
    //                       </h4>
    //                       <p style={{ width: "100%" }}>
    //                         <Skeleton height={12} width={"75%"} count={2} />
    //                       </p>
    //                     </SkeletonTheme>
    //                   </div>
    //                 </div>
    //               ) : el.summary
    //                   ?.toLowerCase()
    //                   .includes(
    //                     "sorry couldn't find any information regarding that"
    //                   ) ||
    //                 el.summary?.toLowerCase().includes("answer not found") ? (
    //                 <div name={i}>
    //                   <ChatMessage
    //                     key={i}
    //                     text={
    //                       "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
    //                     }
    //                     isUser={false}
    //                     isError={true}
    //                   />
    //                 </div>
    //               ) : el.summary == "hello" ? (
    //                 <div
    //                   style={{ marginLeft: "10px" }}
    //                   className="d-flex justify-content-start position-relative gap-2 align-items-start"
    //                 >
    //                   <CAvatar
    //                     style={{
    //                       width: "35px",
    //                       height: "35px",
    //                     }}
    //                     className="font-md mt-2 border avatar"
    //                     size="sm"
    //                     src={aiImage}
    //                   >
    //                     {}
    //                   </CAvatar>
    //                   <div className={`chat-message other mt-2`}>
    //                     Hello! How can I assist you today?
    //                   </div>
    //                 </div>
    //               ) : (
    //                 <>
    //                   <div
    //                     style={{ marginLeft: "10px" }}
    //                     className="d-flex justify-content-start position-relative gap-2 align-items-start"
    //                   >
    //                     <CAvatar
    //                       style={{
    //                         width: "35px",
    //                         height: "35px",
    //                       }}
    //                       className="font-md mt-2 border avatar"
    //                       size="sm"
    //                       src={aiImage}
    //                     >
    //                       {}
    //                     </CAvatar>
    //                     {el.video ? (
    //                       <VideoRecogCard
    //                         video={el.video}
    //                         img="Error"
    //                         handleGeneratePrompt={handleGeneratePrompt}
    //                         question={el.question}
    //                         loading={loading}
    //                       />
    //                     ) : (
    //                       <div
    //                         className={`chat-message other mt-2 align-items-center font-md`}
    //                         style={{ maxWidth: "85%" }}
    //                       >
    //                         {el.summary.split("\n").map((str) => {
    //                           return <p className="p-0 m-0">{str}</p>;
    //                         })}
    //                       </div>
    //                     )}
    //                   </div>
    //                 </>
    //               )}
    //             </div>
    //           );
    //         })}
    //       </>
    //     )}
    //   </div>
    //   <CForm
    //     className="pt-3 "
    //     style={{
    //       bottom: 0,
    //       position: "fixed",
    //       paddingLeft: visible ? 250 : 0,
    //       width: "100%",
    //       background: "transparent",
    //       zIndex: 11,
    //       backdropFilter: "blur(5.7px)",
    //       background:
    //         "linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%)",
    //       paddingBottom: "20px",
    //       display: "flex",
    //       justifyContent: "center",
    //       gap: "10px",
    //       alignItems: "center",
    //     }}
    //     onSubmit={(e) => handlePrompt(e)}
    //   >
    //     <CCard
    //       style={{
    //         backgroundColor: "transparent",
    //         border: "none",
    //         width: "75%",
    //       }}
    //       className="p-2 "
    //     >
    //       <CCol
    //         className="d-flex align-items-center w-100 inputfocus m-auto "
    //         style={{
    //           height: "45px",
    //           backgroundColor: "transparent",
    //           border: "none",
    //         }}
    //       >
    //         <CFormInput
    //           className="border-0 inputfocus form-control position-relative font-md"
    //           style={{
    //             backgroundColor: "white",
    //             boxShadow:
    //               "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    //             // color: "#0B57D0",
    //             height: "30px",
    //             borderRadius: "20px",
    //             paddingRight: "40px",
    //           }}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setInputVal(e.target.value);
    //           }}
    //           value={inputVal}
    //           placeholder="Ask a question"
    //           disabled={loading}
    //         />
    //         {inputVal.length > 0 ? (
    //           <CIcon
    //             icon={cilX}
    //             size="md"
    //             className="position-absolute text-medium-emphasis hover"
    //             style={{ right: "75px" }}
    //             disabled={loading}
    //             onClick={() => {
    //               setInputVal("");
    //             }}
    //           />
    //         ) : null}
    //         <CButton
    //           type="submit"
    //           disabled={inputVal == "" || loading || generatedData.length >= 20}
    //           // variant="outline"
    //           size="sm"
    //           style={{
    //             borderRadius: "50%",
    //             height: "30px",
    //             width: "32px",
    //             boxShadow:
    //               "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    //             background:
    //               "linear-gradient(135deg, rgba(162,224,114,1) 0%,rgba(98,226,0,1) 41%)",
    //             border: "none",
    //           }}
    //           className="hover d-flex align-items-center justify-content-center p-0 mx-2"
    //         >
    //           {loading ? (
    //             <CSpinner
    //               size="sm"
    //               className="m-2 "
    //               style={{ color: "white" }}
    //             />
    //           ) : (
    //             <CIcon
    //               icon={cilSearch}
    //               className="hover text-light p-0"
    //               size="md"
    //               // style={{ color: "#086EB5" }}
    //             />
    //           )}
    //         </CButton>
    //       </CCol>
    //     </CCard>
    //   </CForm>
    // </div>
  );
};

export default VideoDescription;
