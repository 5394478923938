export const dummyData = [
  {
    cod: "200",
    message: 0,
    cnt: 40,
    list: [
      {
        dt: 1703062800,
        main: {
          temp: 19.55,
          feels_like: 18.45,
          temp_min: 19.55,
          temp_max: 22.55,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 991,
          humidity: 34,
          temp_kf: -3,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 36,
        },
        wind: {
          speed: 2.9,
          deg: 298,
          gust: 4.26,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 09:00:00",
      },
      {
        dt: 1703073600,
        main: {
          temp: 19.93,
          feels_like: 18.63,
          temp_min: 19.93,
          temp_max: 20.87,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 990,
          humidity: 25,
          temp_kf: -0.94,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 53,
        },
        wind: {
          speed: 1.77,
          deg: 286,
          gust: 1.9,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 12:00:00",
      },
      {
        dt: 1703084400,
        main: {
          temp: 18.8,
          feels_like: 17.23,
          temp_min: 18.8,
          temp_max: 18.8,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 19,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.7,
          deg: 278,
          gust: 2.55,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 15:00:00",
      },
      {
        dt: 1703095200,
        main: {
          temp: 17.54,
          feels_like: 15.87,
          temp_min: 17.54,
          temp_max: 17.54,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.73,
          deg: 299,
          gust: 2.52,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 18:00:00",
      },
      {
        dt: 1703106000,
        main: {
          temp: 16.19,
          feels_like: 14.41,
          temp_min: 16.19,
          temp_max: 16.19,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 991,
          humidity: 21,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.66,
          deg: 305,
          gust: 2.79,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 21:00:00",
      },
      {
        dt: 1703116800,
        main: {
          temp: 14.87,
          feels_like: 12.99,
          temp_min: 14.87,
          temp_max: 14.87,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 990,
          humidity: 22,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 78,
        },
        wind: {
          speed: 1.75,
          deg: 304,
          gust: 2.72,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 00:00:00",
      },
      {
        dt: 1703127600,
        main: {
          temp: 15.39,
          feels_like: 13.53,
          temp_min: 15.39,
          temp_max: 15.39,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 21,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 60,
        },
        wind: {
          speed: 1.67,
          deg: 296,
          gust: 2.73,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 03:00:00",
      },
      {
        dt: 1703138400,
        main: {
          temp: 19.89,
          feels_like: 18.33,
          temp_min: 19.89,
          temp_max: 19.89,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 15,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 79,
        },
        wind: {
          speed: 2.09,
          deg: 313,
          gust: 2.93,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 06:00:00",
      },
      {
        dt: 1703149200,
        main: {
          temp: 22.95,
          feels_like: 21.64,
          temp_min: 22.95,
          temp_max: 22.95,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 990,
          humidity: 13,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 89,
        },
        wind: {
          speed: 2.38,
          deg: 312,
          gust: 3.61,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 09:00:00",
      },
      {
        dt: 1703160000,
        main: {
          temp: 21.11,
          feels_like: 19.67,
          temp_min: 21.11,
          temp_max: 21.11,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 989,
          humidity: 15,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 86,
        },
        wind: {
          speed: 1.78,
          deg: 288,
          gust: 2.25,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 12:00:00",
      },
      {
        dt: 1703170800,
        main: {
          temp: 19.05,
          feels_like: 17.48,
          temp_min: 19.05,
          temp_max: 19.05,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 991,
          humidity: 18,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.32,
          deg: 319,
          gust: 1.46,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 15:00:00",
      },
      {
        dt: 1703181600,
        main: {
          temp: 17.99,
          feels_like: 16.34,
          temp_min: 17.99,
          temp_max: 17.99,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 991,
          humidity: 19,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.26,
          deg: 303,
          gust: 1.31,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 18:00:00",
      },
      {
        dt: 1703192400,
        main: {
          temp: 16.61,
          feels_like: 14.85,
          temp_min: 16.61,
          temp_max: 16.61,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 990,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.2,
          deg: 321,
          gust: 1.36,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 21:00:00",
      },
      {
        dt: 1703203200,
        main: {
          temp: 15.47,
          feels_like: 13.62,
          temp_min: 15.47,
          temp_max: 15.47,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 990,
          humidity: 21,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 91,
        },
        wind: {
          speed: 0.97,
          deg: 315,
          gust: 0.98,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 00:00:00",
      },
      {
        dt: 1703214000,
        main: {
          temp: 16.08,
          feels_like: 14.27,
          temp_min: 16.08,
          temp_max: 16.08,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 32,
        },
        wind: {
          speed: 0.86,
          deg: 7,
          gust: 1.1,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 03:00:00",
      },
      {
        dt: 1703224800,
        main: {
          temp: 21.3,
          feels_like: 19.88,
          temp_min: 21.3,
          temp_max: 21.3,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 993,
          humidity: 15,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 27,
        },
        wind: {
          speed: 0.67,
          deg: 352,
          gust: 0.81,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 06:00:00",
      },
      {
        dt: 1703235600,
        main: {
          temp: 23.73,
          feels_like: 22.47,
          temp_min: 23.73,
          temp_max: 23.73,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 990,
          humidity: 12,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 88,
        },
        wind: {
          speed: 0.57,
          deg: 268,
          gust: 0.89,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 09:00:00",
      },
      {
        dt: 1703246400,
        main: {
          temp: 21.81,
          feels_like: 20.39,
          temp_min: 21.81,
          temp_max: 21.81,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 990,
          humidity: 13,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 93,
        },
        wind: {
          speed: 0.89,
          deg: 217,
          gust: 0.96,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 12:00:00",
      },
      {
        dt: 1703257200,
        main: {
          temp: 20.08,
          feels_like: 18.54,
          temp_min: 20.08,
          temp_max: 20.08,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 991,
          humidity: 15,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 97,
        },
        wind: {
          speed: 0.36,
          deg: 61,
          gust: 0.61,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 15:00:00",
      },
      {
        dt: 1703268000,
        main: {
          temp: 18.24,
          feels_like: 16.59,
          temp_min: 18.24,
          temp_max: 18.24,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 18,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 58,
        },
        wind: {
          speed: 1.34,
          deg: 4,
          gust: 1.37,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 18:00:00",
      },
      {
        dt: 1703278800,
        main: {
          temp: 16.77,
          feels_like: 15.02,
          temp_min: 16.77,
          temp_max: 16.77,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 3,
        },
        wind: {
          speed: 1.45,
          deg: 19,
          gust: 1.55,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 21:00:00",
      },
      {
        dt: 1703289600,
        main: {
          temp: 15.56,
          feels_like: 13.69,
          temp_min: 15.56,
          temp_max: 15.56,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 991,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 11,
        },
        wind: {
          speed: 1.84,
          deg: 82,
          gust: 2.72,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 00:00:00",
      },
      {
        dt: 1703300400,
        main: {
          temp: 16.72,
          feels_like: 14.97,
          temp_min: 16.72,
          temp_max: 16.72,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 99,
        },
        wind: {
          speed: 1.68,
          deg: 134,
          gust: 3.05,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 03:00:00",
      },
      {
        dt: 1703311200,
        main: {
          temp: 21.77,
          feels_like: 20.42,
          temp_min: 21.77,
          temp_max: 21.77,
          pressure: 1020,
          sea_level: 1020,
          grnd_level: 994,
          humidity: 16,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 76,
        },
        wind: {
          speed: 1.75,
          deg: 127,
          gust: 2.07,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 06:00:00",
      },
      {
        dt: 1703322000,
        main: {
          temp: 24.3,
          feels_like: 23.12,
          temp_min: 24.3,
          temp_max: 24.3,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 13,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 24,
        },
        wind: {
          speed: 0.93,
          deg: 312,
          gust: 1.29,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 09:00:00",
      },
      {
        dt: 1703332800,
        main: {
          temp: 22.45,
          feels_like: 21.12,
          temp_min: 22.45,
          temp_max: 22.45,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 992,
          humidity: 14,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 47,
        },
        wind: {
          speed: 0.16,
          deg: 235,
          gust: 0.83,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 12:00:00",
      },
      {
        dt: 1703343600,
        main: {
          temp: 20.78,
          feels_like: 19.31,
          temp_min: 20.78,
          temp_max: 20.78,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 15,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 47,
        },
        wind: {
          speed: 1.19,
          deg: 324,
          gust: 1.34,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 15:00:00",
      },
      {
        dt: 1703354400,
        main: {
          temp: 19.27,
          feels_like: 17.72,
          temp_min: 19.27,
          temp_max: 19.27,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 18,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 26,
        },
        wind: {
          speed: 1.23,
          deg: 10,
          gust: 1.29,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 18:00:00",
      },
      {
        dt: 1703365200,
        main: {
          temp: 17.84,
          feels_like: 16.2,
          temp_min: 17.84,
          temp_max: 17.84,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 20,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 1.56,
          deg: 8,
          gust: 1.72,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 21:00:00",
      },
      {
        dt: 1703376000,
        main: {
          temp: 16.64,
          feels_like: 14.93,
          temp_min: 16.64,
          temp_max: 16.64,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 22,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 1.37,
          deg: 10,
          gust: 1.53,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 00:00:00",
      },
      {
        dt: 1703386800,
        main: {
          temp: 17.43,
          feels_like: 15.83,
          temp_min: 17.43,
          temp_max: 17.43,
          pressure: 1021,
          sea_level: 1021,
          grnd_level: 995,
          humidity: 23,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 0.72,
          deg: 327,
          gust: 0.88,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 03:00:00",
      },
      {
        dt: 1703397600,
        main: {
          temp: 22.64,
          feels_like: 21.38,
          temp_min: 22.64,
          temp_max: 22.64,
          pressure: 1021,
          sea_level: 1021,
          grnd_level: 996,
          humidity: 16,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 0.95,
          deg: 311,
          gust: 1.18,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 06:00:00",
      },
      {
        dt: 1703408400,
        main: {
          temp: 25.11,
          feels_like: 24.04,
          temp_min: 25.11,
          temp_max: 25.11,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 993,
          humidity: 14,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 36,
        },
        wind: {
          speed: 1.82,
          deg: 285,
          gust: 2.38,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 09:00:00",
      },
      {
        dt: 1703419200,
        main: {
          temp: 23.15,
          feels_like: 21.99,
          temp_min: 23.15,
          temp_max: 23.15,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 18,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 53,
        },
        wind: {
          speed: 1.83,
          deg: 263,
          gust: 1.94,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 12:00:00",
      },
      {
        dt: 1703430000,
        main: {
          temp: 21.01,
          feels_like: 19.77,
          temp_min: 21.01,
          temp_max: 21.01,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 994,
          humidity: 23,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 47,
        },
        wind: {
          speed: 1.96,
          deg: 282,
          gust: 2.57,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 15:00:00",
      },
      {
        dt: 1703440800,
        main: {
          temp: 19.59,
          feels_like: 18.28,
          temp_min: 19.59,
          temp_max: 19.59,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 26,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 2,
          deg: 289,
          gust: 3.05,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 18:00:00",
      },
      {
        dt: 1703451600,
        main: {
          temp: 18.16,
          feels_like: 16.87,
          temp_min: 18.16,
          temp_max: 18.16,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 32,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 38,
        },
        wind: {
          speed: 2.02,
          deg: 306,
          gust: 3.31,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 21:00:00",
      },
      {
        dt: 1703462400,
        main: {
          temp: 17.05,
          feels_like: 15.72,
          temp_min: 17.05,
          temp_max: 17.05,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 992,
          humidity: 35,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 38,
        },
        wind: {
          speed: 1.97,
          deg: 298,
          gust: 3.67,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 00:00:00",
      },
      {
        dt: 1703473200,
        main: {
          temp: 17.52,
          feels_like: 16.22,
          temp_min: 17.52,
          temp_max: 17.52,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 993,
          humidity: 34,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 9,
        },
        wind: {
          speed: 2.21,
          deg: 295,
          gust: 4.11,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 03:00:00",
      },
      {
        dt: 1703484000,
        main: {
          temp: 22.6,
          feels_like: 21.59,
          temp_min: 22.6,
          temp_max: 22.6,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 994,
          humidity: 26,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 4,
        },
        wind: {
          speed: 2.52,
          deg: 293,
          gust: 3.75,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 06:00:00",
      },
    ],
    city: {
      id: 1273294,
      name: "Delhi",
      coord: {
        lat: 28.6667,
        lon: 77.2167,
      },
      country: "IN",
      population: 10927986,
      timezone: 19800,
      sunrise: 1703036338,
      sunset: 1703073471,
    },
  },
  {
    cod: "200",
    message: 0,
    cnt: 40,
    list: [
      {
        dt: 1703062800,
        main: {
          temp: 27.95,
          feels_like: 27.43,
          temp_min: 27.86,
          temp_max: 27.95,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1011,
          humidity: 37,
          temp_kf: 0.09,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 82,
        },
        wind: {
          speed: 3.85,
          deg: 313,
          gust: 3.99,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 09:00:00",
      },
      {
        dt: 1703073600,
        main: {
          temp: 27.25,
          feels_like: 27.46,
          temp_min: 26.88,
          temp_max: 27.25,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1011,
          humidity: 47,
          temp_kf: 0.37,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 89,
        },
        wind: {
          speed: 5.45,
          deg: 324,
          gust: 5.75,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 12:00:00",
      },
      {
        dt: 1703084400,
        main: {
          temp: 26.63,
          feels_like: 26.63,
          temp_min: 26.63,
          temp_max: 26.63,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 60,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 93,
        },
        wind: {
          speed: 4.4,
          deg: 339,
          gust: 5,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 15:00:00",
      },
      {
        dt: 1703095200,
        main: {
          temp: 26.54,
          feels_like: 26.54,
          temp_min: 26.54,
          temp_max: 26.54,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 59,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 95,
        },
        wind: {
          speed: 1.49,
          deg: 40,
          gust: 2.23,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 18:00:00",
      },
      {
        dt: 1703106000,
        main: {
          temp: 26.1,
          feels_like: 26.1,
          temp_min: 26.1,
          temp_max: 26.1,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 93,
        },
        wind: {
          speed: 2.73,
          deg: 66,
          gust: 2.99,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 21:00:00",
      },
      {
        dt: 1703116800,
        main: {
          temp: 25.32,
          feels_like: 25.13,
          temp_min: 25.32,
          temp_max: 25.32,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 47,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 96,
        },
        wind: {
          speed: 3.49,
          deg: 76,
          gust: 3.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 00:00:00",
      },
      {
        dt: 1703127600,
        main: {
          temp: 25.47,
          feels_like: 25.2,
          temp_min: 25.47,
          temp_max: 25.47,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 43,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 3.44,
          deg: 80,
          gust: 3.58,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 03:00:00",
      },
      {
        dt: 1703138400,
        main: {
          temp: 27.97,
          feels_like: 27.64,
          temp_min: 27.97,
          temp_max: 27.97,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 40,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.03,
          deg: 90,
          gust: 2.69,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 06:00:00",
      },
      {
        dt: 1703149200,
        main: {
          temp: 28.87,
          feels_like: 28.23,
          temp_min: 28.87,
          temp_max: 28.87,
          pressure: 1011,
          sea_level: 1011,
          grnd_level: 1010,
          humidity: 37,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 99,
        },
        wind: {
          speed: 2.79,
          deg: 280,
          gust: 2.12,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 09:00:00",
      },
      {
        dt: 1703160000,
        main: {
          temp: 27.61,
          feels_like: 28.03,
          temp_min: 27.61,
          temp_max: 27.61,
          pressure: 1011,
          sea_level: 1011,
          grnd_level: 1010,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 99,
        },
        wind: {
          speed: 3.98,
          deg: 306,
          gust: 4.58,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 12:00:00",
      },
      {
        dt: 1703170800,
        main: {
          temp: 26.54,
          feels_like: 26.54,
          temp_min: 26.54,
          temp_max: 26.54,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 60,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 4.01,
          deg: 332,
          gust: 4.61,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 15:00:00",
      },
      {
        dt: 1703181600,
        main: {
          temp: 26.64,
          feels_like: 26.64,
          temp_min: 26.64,
          temp_max: 26.64,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.8,
          deg: 21,
          gust: 3.36,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 18:00:00",
      },
      {
        dt: 1703192400,
        main: {
          temp: 26.57,
          feels_like: 26.57,
          temp_min: 26.57,
          temp_max: 26.57,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1010,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.74,
          deg: 37,
          gust: 2.08,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 21:00:00",
      },
      {
        dt: 1703203200,
        main: {
          temp: 26.18,
          feels_like: 26.18,
          temp_min: 26.18,
          temp_max: 26.18,
          pressure: 1011,
          sea_level: 1011,
          grnd_level: 1010,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.83,
          deg: 42,
          gust: 1.91,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 00:00:00",
      },
      {
        dt: 1703214000,
        main: {
          temp: 26.41,
          feels_like: 26.41,
          temp_min: 26.41,
          temp_max: 26.41,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 49,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.41,
          deg: 101,
          gust: 2.65,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 03:00:00",
      },
      {
        dt: 1703224800,
        main: {
          temp: 27.86,
          feels_like: 27.82,
          temp_min: 27.86,
          temp_max: 27.86,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1012,
          humidity: 44,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.81,
          deg: 97,
          gust: 2.15,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 06:00:00",
      },
      {
        dt: 1703235600,
        main: {
          temp: 28.72,
          feels_like: 28.25,
          temp_min: 28.72,
          temp_max: 28.72,
          pressure: 1011,
          sea_level: 1011,
          grnd_level: 1010,
          humidity: 39,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 98,
        },
        wind: {
          speed: 2.5,
          deg: 276,
          gust: 1.91,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 09:00:00",
      },
      {
        dt: 1703246400,
        main: {
          temp: 27.41,
          feels_like: 28.12,
          temp_min: 27.41,
          temp_max: 27.41,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 99,
        },
        wind: {
          speed: 2.53,
          deg: 310,
          gust: 2.85,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 12:00:00",
      },
      {
        dt: 1703257200,
        main: {
          temp: 26.79,
          feels_like: 27.57,
          temp_min: 26.79,
          temp_max: 26.79,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.63,
          deg: 288,
          gust: 2,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 15:00:00",
      },
      {
        dt: 1703268000,
        main: {
          temp: 26.26,
          feels_like: 26.26,
          temp_min: 26.26,
          temp_max: 26.26,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 60,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 98,
        },
        wind: {
          speed: 2.53,
          deg: 344,
          gust: 2.7,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 18:00:00",
      },
      {
        dt: 1703278800,
        main: {
          temp: 25.81,
          feels_like: 25.91,
          temp_min: 25.81,
          temp_max: 25.81,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 94,
        },
        wind: {
          speed: 1.96,
          deg: 38,
          gust: 2.33,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 21:00:00",
      },
      {
        dt: 1703289600,
        main: {
          temp: 25.18,
          feels_like: 25.11,
          temp_min: 25.18,
          temp_max: 25.18,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1011,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 96,
        },
        wind: {
          speed: 2.39,
          deg: 63,
          gust: 2.47,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 00:00:00",
      },
      {
        dt: 1703300400,
        main: {
          temp: 25.32,
          feels_like: 25.16,
          temp_min: 25.32,
          temp_max: 25.32,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 48,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.39,
          deg: 77,
          gust: 2.41,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 03:00:00",
      },
      {
        dt: 1703311200,
        main: {
          temp: 28.16,
          feels_like: 27.74,
          temp_min: 28.16,
          temp_max: 28.16,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 39,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 80,
        },
        wind: {
          speed: 0.95,
          deg: 109,
          gust: 1.57,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 06:00:00",
      },
      {
        dt: 1703322000,
        main: {
          temp: 29.16,
          feels_like: 28.86,
          temp_min: 29.16,
          temp_max: 29.16,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 41,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 29,
        },
        wind: {
          speed: 4.12,
          deg: 299,
          gust: 3.83,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 09:00:00",
      },
      {
        dt: 1703332800,
        main: {
          temp: 27.36,
          feels_like: 28.22,
          temp_min: 27.36,
          temp_max: 27.36,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 29,
        },
        wind: {
          speed: 6.13,
          deg: 319,
          gust: 7.36,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 12:00:00",
      },
      {
        dt: 1703343600,
        main: {
          temp: 26.43,
          feels_like: 26.43,
          temp_min: 26.43,
          temp_max: 26.43,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 61,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 57,
        },
        wind: {
          speed: 3.46,
          deg: 358,
          gust: 4.44,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 15:00:00",
      },
      {
        dt: 1703354400,
        main: {
          temp: 26.18,
          feels_like: 26.18,
          temp_min: 26.18,
          temp_max: 26.18,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 58,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 76,
        },
        wind: {
          speed: 2.77,
          deg: 6,
          gust: 3.44,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 18:00:00",
      },
      {
        dt: 1703365200,
        main: {
          temp: 25.65,
          feels_like: 25.68,
          temp_min: 25.65,
          temp_max: 25.65,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 76,
        },
        wind: {
          speed: 1.78,
          deg: 93,
          gust: 1.95,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 21:00:00",
      },
      {
        dt: 1703376000,
        main: {
          temp: 25.15,
          feels_like: 24.92,
          temp_min: 25.15,
          temp_max: 25.15,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1012,
          humidity: 46,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 77,
        },
        wind: {
          speed: 2.79,
          deg: 84,
          gust: 2.92,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 00:00:00",
      },
      {
        dt: 1703386800,
        main: {
          temp: 25.42,
          feels_like: 25.04,
          temp_min: 25.42,
          temp_max: 25.42,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 39,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 91,
        },
        wind: {
          speed: 3.12,
          deg: 84,
          gust: 3.44,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 03:00:00",
      },
      {
        dt: 1703397600,
        main: {
          temp: 28.26,
          feels_like: 27.69,
          temp_min: 28.26,
          temp_max: 28.26,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 37,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 58,
        },
        wind: {
          speed: 1.8,
          deg: 114,
          gust: 2.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 06:00:00",
      },
      {
        dt: 1703408400,
        main: {
          temp: 29.46,
          feels_like: 28.8,
          temp_min: 29.46,
          temp_max: 29.46,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 37,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 57,
        },
        wind: {
          speed: 2.6,
          deg: 289,
          gust: 2.86,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 09:00:00",
      },
      {
        dt: 1703419200,
        main: {
          temp: 27.94,
          feels_like: 28.38,
          temp_min: 27.94,
          temp_max: 27.94,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 59,
        },
        wind: {
          speed: 4.83,
          deg: 312,
          gust: 5.83,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 12:00:00",
      },
      {
        dt: 1703430000,
        main: {
          temp: 26.8,
          feels_like: 27.71,
          temp_min: 26.8,
          temp_max: 26.8,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 58,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 93,
        },
        wind: {
          speed: 4.08,
          deg: 333,
          gust: 5.08,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 15:00:00",
      },
      {
        dt: 1703440800,
        main: {
          temp: 26.52,
          feels_like: 26.52,
          temp_min: 26.52,
          temp_max: 26.52,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 52,
        },
        wind: {
          speed: 2.87,
          deg: 23,
          gust: 3.63,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 18:00:00",
      },
      {
        dt: 1703451600,
        main: {
          temp: 25.99,
          feels_like: 25.99,
          temp_min: 25.99,
          temp_max: 25.99,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 5,
        },
        wind: {
          speed: 2.7,
          deg: 91,
          gust: 2.8,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 21:00:00",
      },
      {
        dt: 1703462400,
        main: {
          temp: 25.43,
          feels_like: 25.26,
          temp_min: 25.43,
          temp_max: 25.43,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 47,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 10,
        },
        wind: {
          speed: 3.35,
          deg: 79,
          gust: 3.76,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 00:00:00",
      },
      {
        dt: 1703473200,
        main: {
          temp: 25.77,
          feels_like: 25.58,
          temp_min: 25.77,
          temp_max: 25.77,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 45,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 59,
        },
        wind: {
          speed: 3.61,
          deg: 89,
          gust: 4.13,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 03:00:00",
      },
      {
        dt: 1703484000,
        main: {
          temp: 28.61,
          feels_like: 28.57,
          temp_min: 28.61,
          temp_max: 28.61,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 44,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 76,
        },
        wind: {
          speed: 2.19,
          deg: 75,
          gust: 2.8,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 06:00:00",
      },
    ],
    city: {
      id: 1275339,
      name: "Mumbai",
      coord: {
        lat: 19.0144,
        lon: 72.8479,
      },
      country: "IN",
      population: 1000000,
      timezone: 19800,
      sunrise: 1703036182,
      sunset: 1703075725,
    },
  },
  {
    cod: "200",
    message: 0,
    cnt: 40,
    list: [
      {
        dt: 1703062800,
        main: {
          temp: 24.23,
          feels_like: 23.99,
          temp_min: 23.62,
          temp_max: 24.23,
          pressure: 1020,
          sea_level: 1020,
          grnd_level: 1018,
          humidity: 49,
          temp_kf: 0.61,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 1,
        },
        wind: {
          speed: 2.37,
          deg: 350,
          gust: 1.94,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 09:00:00",
      },
      {
        dt: 1703073600,
        main: {
          temp: 24.05,
          feels_like: 23.84,
          temp_min: 23.81,
          temp_max: 24.05,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1017,
          humidity: 51,
          temp_kf: 0.24,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 1,
        },
        wind: {
          speed: 4.28,
          deg: 341,
          gust: 3.78,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 12:00:00",
      },
      {
        dt: 1703084400,
        main: {
          temp: 23.63,
          feels_like: 23.41,
          temp_min: 23.63,
          temp_max: 23.63,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 3.85,
          deg: 344,
          gust: 3.88,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 15:00:00",
      },
      {
        dt: 1703095200,
        main: {
          temp: 23.01,
          feels_like: 22.7,
          temp_min: 23.01,
          temp_max: 23.01,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 51,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 2.28,
          deg: 348,
          gust: 2.42,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 18:00:00",
      },
      {
        dt: 1703106000,
        main: {
          temp: 22.41,
          feels_like: 22.04,
          temp_min: 22.41,
          temp_max: 22.41,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1017,
          humidity: 51,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 1.07,
          deg: 356,
          gust: 1.4,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 21:00:00",
      },
      {
        dt: 1703116800,
        main: {
          temp: 21.92,
          feels_like: 21.53,
          temp_min: 21.92,
          temp_max: 21.92,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 0.19,
          deg: 11,
          gust: 0.96,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 00:00:00",
      },
      {
        dt: 1703127600,
        main: {
          temp: 21.56,
          feels_like: 21.21,
          temp_min: 21.56,
          temp_max: 21.56,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 0,
        },
        wind: {
          speed: 1.52,
          deg: 187,
          gust: 1.54,
        },
        visibility: 10000,
        pop: 0.08,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 03:00:00",
      },
      {
        dt: 1703138400,
        main: {
          temp: 22.77,
          feels_like: 22.51,
          temp_min: 22.77,
          temp_max: 22.77,
          pressure: 1020,
          sea_level: 1020,
          grnd_level: 1019,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 500,
            main: "Rain",
            description: "light rain",
            icon: "10d",
          },
        ],
        clouds: {
          all: 10,
        },
        wind: {
          speed: 2.11,
          deg: 229,
          gust: 2.63,
        },
        visibility: 10000,
        pop: 0.36,
        rain: {
          "3h": 0.12,
        },
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 06:00:00",
      },
      {
        dt: 1703149200,
        main: {
          temp: 23.5,
          feels_like: 23.29,
          temp_min: 23.5,
          temp_max: 23.5,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 53,
          temp_kf: 0,
        },
        weather: [
          {
            id: 500,
            main: "Rain",
            description: "light rain",
            icon: "10d",
          },
        ],
        clouds: {
          all: 25,
        },
        wind: {
          speed: 4.26,
          deg: 291,
          gust: 4.21,
        },
        visibility: 10000,
        pop: 0.28,
        rain: {
          "3h": 0.24,
        },
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 09:00:00",
      },
      {
        dt: 1703160000,
        main: {
          temp: 23.46,
          feels_like: 23.19,
          temp_min: 23.46,
          temp_max: 23.46,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 51,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 19,
        },
        wind: {
          speed: 6.06,
          deg: 288,
          gust: 5.84,
        },
        visibility: 10000,
        pop: 0.04,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 12:00:00",
      },
      {
        dt: 1703170800,
        main: {
          temp: 23.03,
          feels_like: 22.72,
          temp_min: 23.03,
          temp_max: 23.03,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 51,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 4,
        },
        wind: {
          speed: 5.24,
          deg: 284,
          gust: 5.74,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 15:00:00",
      },
      {
        dt: 1703181600,
        main: {
          temp: 22.69,
          feels_like: 22.37,
          temp_min: 22.69,
          temp_max: 22.69,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 2,
        },
        wind: {
          speed: 5.19,
          deg: 281,
          gust: 5.52,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 18:00:00",
      },
      {
        dt: 1703192400,
        main: {
          temp: 22.4,
          feels_like: 22.05,
          temp_min: 22.4,
          temp_max: 22.4,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 36,
        },
        wind: {
          speed: 4.92,
          deg: 286,
          gust: 5.3,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 21:00:00",
      },
      {
        dt: 1703203200,
        main: {
          temp: 22.21,
          feels_like: 21.77,
          temp_min: 22.21,
          temp_max: 22.21,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 49,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 31,
        },
        wind: {
          speed: 3.75,
          deg: 290,
          gust: 4.16,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 00:00:00",
      },
      {
        dt: 1703214000,
        main: {
          temp: 22.2,
          feels_like: 21.78,
          temp_min: 22.2,
          temp_max: 22.2,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 72,
        },
        wind: {
          speed: 3.01,
          deg: 284,
          gust: 3.52,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 03:00:00",
      },
      {
        dt: 1703224800,
        main: {
          temp: 23.23,
          feels_like: 22.91,
          temp_min: 23.23,
          temp_max: 23.23,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 86,
        },
        wind: {
          speed: 3.65,
          deg: 282,
          gust: 3.73,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 06:00:00",
      },
      {
        dt: 1703235600,
        main: {
          temp: 23.64,
          feels_like: 23.34,
          temp_min: 23.64,
          temp_max: 23.64,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 49,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 4.38,
          deg: 291,
          gust: 4.29,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 09:00:00",
      },
      {
        dt: 1703246400,
        main: {
          temp: 23.54,
          feels_like: 23.23,
          temp_min: 23.54,
          temp_max: 23.54,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 49,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 52,
        },
        wind: {
          speed: 4.23,
          deg: 288,
          gust: 4.27,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 12:00:00",
      },
      {
        dt: 1703257200,
        main: {
          temp: 23.12,
          feels_like: 22.79,
          temp_min: 23.12,
          temp_max: 23.12,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 58,
        },
        wind: {
          speed: 3.09,
          deg: 266,
          gust: 3.36,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 15:00:00",
      },
      {
        dt: 1703268000,
        main: {
          temp: 22.68,
          feels_like: 22.36,
          temp_min: 22.68,
          temp_max: 22.68,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 34,
        },
        wind: {
          speed: 3.27,
          deg: 271,
          gust: 3.53,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 18:00:00",
      },
      {
        dt: 1703278800,
        main: {
          temp: 22.31,
          feels_like: 21.98,
          temp_min: 22.31,
          temp_max: 22.31,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 53,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 6,
        },
        wind: {
          speed: 3.2,
          deg: 288,
          gust: 3.51,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 21:00:00",
      },
      {
        dt: 1703289600,
        main: {
          temp: 21.93,
          feels_like: 21.61,
          temp_min: 21.93,
          temp_max: 21.93,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 18,
        },
        wind: {
          speed: 2.34,
          deg: 275,
          gust: 2.87,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 00:00:00",
      },
      {
        dt: 1703300400,
        main: {
          temp: 21.49,
          feels_like: 21.16,
          temp_min: 21.49,
          temp_max: 21.49,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 13,
        },
        wind: {
          speed: 1.86,
          deg: 229,
          gust: 2.55,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 03:00:00",
      },
      {
        dt: 1703311200,
        main: {
          temp: 22.41,
          feels_like: 22.14,
          temp_min: 22.41,
          temp_max: 22.41,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 18,
        },
        wind: {
          speed: 2.93,
          deg: 197,
          gust: 2.63,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 06:00:00",
      },
      {
        dt: 1703322000,
        main: {
          temp: 23.21,
          feels_like: 23,
          temp_min: 23.21,
          temp_max: 23.21,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 24,
        },
        wind: {
          speed: 3.13,
          deg: 264,
          gust: 2.69,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 09:00:00",
      },
      {
        dt: 1703332800,
        main: {
          temp: 23.12,
          feels_like: 22.92,
          temp_min: 23.12,
          temp_max: 23.12,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 21,
        },
        wind: {
          speed: 2.93,
          deg: 296,
          gust: 2.7,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 12:00:00",
      },
      {
        dt: 1703343600,
        main: {
          temp: 22.8,
          feels_like: 22.6,
          temp_min: 22.8,
          temp_max: 22.8,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 56,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 62,
        },
        wind: {
          speed: 1.61,
          deg: 331,
          gust: 1.84,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 15:00:00",
      },
      {
        dt: 1703354400,
        main: {
          temp: 22.34,
          feels_like: 22.14,
          temp_min: 22.34,
          temp_max: 22.34,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 58,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 48,
        },
        wind: {
          speed: 0.8,
          deg: 24,
          gust: 0.9,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 18:00:00",
      },
      {
        dt: 1703365200,
        main: {
          temp: 21.81,
          feels_like: 21.64,
          temp_min: 21.81,
          temp_max: 21.81,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 61,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 78,
        },
        wind: {
          speed: 2.46,
          deg: 111,
          gust: 2.31,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 21:00:00",
      },
      {
        dt: 1703376000,
        main: {
          temp: 21.02,
          feels_like: 20.77,
          temp_min: 21.02,
          temp_max: 21.02,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 61,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 86,
        },
        wind: {
          speed: 2.2,
          deg: 111,
          gust: 2.13,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 00:00:00",
      },
      {
        dt: 1703386800,
        main: {
          temp: 20.85,
          feels_like: 20.51,
          temp_min: 20.85,
          temp_max: 20.85,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 58,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 77,
        },
        wind: {
          speed: 1.08,
          deg: 129,
          gust: 0.88,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 03:00:00",
      },
      {
        dt: 1703397600,
        main: {
          temp: 22.55,
          feels_like: 22.17,
          temp_min: 22.55,
          temp_max: 22.55,
          pressure: 1020,
          sea_level: 1020,
          grnd_level: 1019,
          humidity: 50,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 88,
        },
        wind: {
          speed: 1.15,
          deg: 117,
          gust: 1.11,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 06:00:00",
      },
      {
        dt: 1703408400,
        main: {
          temp: 23.97,
          feels_like: 23.55,
          temp_min: 23.97,
          temp_max: 23.97,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 43,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 90,
        },
        wind: {
          speed: 1.53,
          deg: 327,
          gust: 0.9,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 09:00:00",
      },
      {
        dt: 1703419200,
        main: {
          temp: 24.3,
          feels_like: 23.88,
          temp_min: 24.3,
          temp_max: 24.3,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 42,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 80,
        },
        wind: {
          speed: 2.69,
          deg: 344,
          gust: 2.21,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 12:00:00",
      },
      {
        dt: 1703430000,
        main: {
          temp: 23.94,
          feels_like: 23.62,
          temp_min: 23.94,
          temp_max: 23.94,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1017,
          humidity: 47,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.73,
          deg: 13,
          gust: 2.87,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 15:00:00",
      },
      {
        dt: 1703440800,
        main: {
          temp: 23.68,
          feels_like: 23.51,
          temp_min: 23.68,
          temp_max: 23.68,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 3.24,
          deg: 18,
          gust: 3.41,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 18:00:00",
      },
      {
        dt: 1703451600,
        main: {
          temp: 23.19,
          feels_like: 23.05,
          temp_min: 23.19,
          temp_max: 23.19,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 57,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.94,
          deg: 45,
          gust: 3.23,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 21:00:00",
      },
      {
        dt: 1703462400,
        main: {
          temp: 22.8,
          feels_like: 22.62,
          temp_min: 22.8,
          temp_max: 22.8,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 57,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.5,
          deg: 55,
          gust: 2.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 00:00:00",
      },
      {
        dt: 1703473200,
        main: {
          temp: 22.4,
          feels_like: 22.18,
          temp_min: 22.4,
          temp_max: 22.4,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 1018,
          humidity: 57,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.14,
          deg: 65,
          gust: 2.33,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 03:00:00",
      },
      {
        dt: 1703484000,
        main: {
          temp: 24,
          feels_like: 23.81,
          temp_min: 24,
          temp_max: 24,
          pressure: 1021,
          sea_level: 1021,
          grnd_level: 1020,
          humidity: 52,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 1.78,
          deg: 24,
          gust: 2.33,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 06:00:00",
      },
    ],
    city: {
      id: 292223,
      name: "Dubai",
      coord: {
        lat: 25.2582,
        lon: 55.3047,
      },
      country: "AE",
      population: 1137347,
      timezone: 14400,
      sunrise: 1703041151,
      sunset: 1703079180,
    },
  },
  {
    cod: "200",
    message: 0,
    cnt: 40,
    list: [
      {
        dt: 1703062800,
        main: {
          temp: 23.7,
          feels_like: 23.95,
          temp_min: 23.7,
          temp_max: 25.49,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 913,
          humidity: 70,
          temp_kf: -1.79,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 70,
        },
        wind: {
          speed: 5.13,
          deg: 97,
          gust: 5.46,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 09:00:00",
      },
      {
        dt: 1703073600,
        main: {
          temp: 22.8,
          feels_like: 22.96,
          temp_min: 22.8,
          temp_max: 22.8,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 70,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 64,
        },
        wind: {
          speed: 4.67,
          deg: 121,
          gust: 6.41,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 12:00:00",
      },
      {
        dt: 1703084400,
        main: {
          temp: 19.76,
          feels_like: 19.88,
          temp_min: 19.76,
          temp_max: 19.76,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 914,
          humidity: 80,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 19,
        },
        wind: {
          speed: 3.69,
          deg: 114,
          gust: 6.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 15:00:00",
      },
      {
        dt: 1703095200,
        main: {
          temp: 17.98,
          feels_like: 18.03,
          temp_min: 17.98,
          temp_max: 17.98,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 84,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 29,
        },
        wind: {
          speed: 3.19,
          deg: 86,
          gust: 5.57,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 18:00:00",
      },
      {
        dt: 1703106000,
        main: {
          temp: 16.86,
          feels_like: 16.87,
          temp_min: 16.86,
          temp_max: 16.86,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 912,
          humidity: 87,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 86,
        },
        wind: {
          speed: 3.14,
          deg: 85,
          gust: 5.92,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 21:00:00",
      },
      {
        dt: 1703116800,
        main: {
          temp: 16.16,
          feels_like: 16.16,
          temp_min: 16.16,
          temp_max: 16.16,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 912,
          humidity: 89,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 54,
        },
        wind: {
          speed: 3.12,
          deg: 84,
          gust: 5.58,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 00:00:00",
      },
      {
        dt: 1703127600,
        main: {
          temp: 19.51,
          feels_like: 19.4,
          temp_min: 19.51,
          temp_max: 19.51,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 915,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 50,
        },
        wind: {
          speed: 3.25,
          deg: 92,
          gust: 4.42,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 03:00:00",
      },
      {
        dt: 1703138400,
        main: {
          temp: 25.07,
          feels_like: 25.04,
          temp_min: 25.07,
          temp_max: 25.07,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 915,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 41,
        },
        wind: {
          speed: 3.23,
          deg: 108,
          gust: 3.35,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 06:00:00",
      },
      {
        dt: 1703149200,
        main: {
          temp: 27.23,
          feels_like: 27.2,
          temp_min: 27.23,
          temp_max: 27.23,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 913,
          humidity: 43,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 84,
        },
        wind: {
          speed: 3.67,
          deg: 111,
          gust: 3.56,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 09:00:00",
      },
      {
        dt: 1703160000,
        main: {
          temp: 24.43,
          feels_like: 24.36,
          temp_min: 24.43,
          temp_max: 24.43,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 912,
          humidity: 55,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 79,
        },
        wind: {
          speed: 3.54,
          deg: 110,
          gust: 4.94,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 12:00:00",
      },
      {
        dt: 1703170800,
        main: {
          temp: 20.56,
          feels_like: 20.47,
          temp_min: 20.56,
          temp_max: 20.56,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 914,
          humidity: 69,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 46,
        },
        wind: {
          speed: 3.85,
          deg: 106,
          gust: 6.83,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 15:00:00",
      },
      {
        dt: 1703181600,
        main: {
          temp: 18.38,
          feels_like: 18.44,
          temp_min: 18.38,
          temp_max: 18.38,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 83,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 64,
        },
        wind: {
          speed: 3.64,
          deg: 101,
          gust: 6.33,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 18:00:00",
      },
      {
        dt: 1703192400,
        main: {
          temp: 17.17,
          feels_like: 17.29,
          temp_min: 17.17,
          temp_max: 17.17,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 912,
          humidity: 90,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 84,
        },
        wind: {
          speed: 2.86,
          deg: 93,
          gust: 4.42,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 21:00:00",
      },
      {
        dt: 1703203200,
        main: {
          temp: 16.45,
          feels_like: 16.5,
          temp_min: 16.45,
          temp_max: 16.45,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 912,
          humidity: 90,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 71,
        },
        wind: {
          speed: 2.55,
          deg: 94,
          gust: 3.4,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 00:00:00",
      },
      {
        dt: 1703214000,
        main: {
          temp: 19.51,
          feels_like: 19.45,
          temp_min: 19.51,
          temp_max: 19.51,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 914,
          humidity: 74,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.64,
          deg: 104,
          gust: 3.49,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 03:00:00",
      },
      {
        dt: 1703224800,
        main: {
          temp: 24.9,
          feels_like: 24.67,
          temp_min: 24.9,
          temp_max: 24.9,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 915,
          humidity: 47,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 98,
        },
        wind: {
          speed: 2.55,
          deg: 103,
          gust: 2.41,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 06:00:00",
      },
      {
        dt: 1703235600,
        main: {
          temp: 27.42,
          feels_like: 26.97,
          temp_min: 27.42,
          temp_max: 27.42,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 912,
          humidity: 36,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 2.24,
          deg: 83,
          gust: 2.04,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 09:00:00",
      },
      {
        dt: 1703246400,
        main: {
          temp: 24.99,
          feels_like: 24.75,
          temp_min: 24.99,
          temp_max: 24.99,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 912,
          humidity: 46,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 2.25,
          deg: 90,
          gust: 3.4,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 12:00:00",
      },
      {
        dt: 1703257200,
        main: {
          temp: 20.93,
          feels_like: 20.72,
          temp_min: 20.93,
          temp_max: 20.93,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 914,
          humidity: 63,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 92,
        },
        wind: {
          speed: 3.21,
          deg: 93,
          gust: 5.61,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 15:00:00",
      },
      {
        dt: 1703268000,
        main: {
          temp: 18.95,
          feels_like: 18.78,
          temp_min: 18.95,
          temp_max: 18.95,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 914,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 96,
        },
        wind: {
          speed: 3.51,
          deg: 87,
          gust: 5.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 18:00:00",
      },
      {
        dt: 1703278800,
        main: {
          temp: 18.07,
          feels_like: 18,
          temp_min: 18.07,
          temp_max: 18.07,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 913,
          humidity: 79,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 3.02,
          deg: 96,
          gust: 5.08,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 21:00:00",
      },
      {
        dt: 1703289600,
        main: {
          temp: 17.14,
          feels_like: 17.08,
          temp_min: 17.14,
          temp_max: 17.14,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 83,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 2.88,
          deg: 96,
          gust: 4.56,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 00:00:00",
      },
      {
        dt: 1703300400,
        main: {
          temp: 19.48,
          feels_like: 19.36,
          temp_min: 19.48,
          temp_max: 19.48,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 915,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 70,
        },
        wind: {
          speed: 3.43,
          deg: 92,
          gust: 4.4,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 03:00:00",
      },
      {
        dt: 1703311200,
        main: {
          temp: 25.21,
          feels_like: 24.75,
          temp_min: 25.21,
          temp_max: 25.21,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 915,
          humidity: 37,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 65,
        },
        wind: {
          speed: 3.6,
          deg: 88,
          gust: 4.08,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 06:00:00",
      },
      {
        dt: 1703322000,
        main: {
          temp: 27.15,
          feels_like: 26.4,
          temp_min: 27.15,
          temp_max: 27.15,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 913,
          humidity: 27,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 60,
        },
        wind: {
          speed: 4.05,
          deg: 89,
          gust: 4.18,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 09:00:00",
      },
      {
        dt: 1703332800,
        main: {
          temp: 23.39,
          feels_like: 22.75,
          temp_min: 23.39,
          temp_max: 23.39,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 913,
          humidity: 37,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 43,
        },
        wind: {
          speed: 3.98,
          deg: 93,
          gust: 5.82,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 12:00:00",
      },
      {
        dt: 1703343600,
        main: {
          temp: 19.18,
          feels_like: 18.56,
          temp_min: 19.18,
          temp_max: 19.18,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 915,
          humidity: 54,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 13,
        },
        wind: {
          speed: 4.16,
          deg: 94,
          gust: 7.87,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 15:00:00",
      },
      {
        dt: 1703354400,
        main: {
          temp: 16.99,
          feels_like: 16.6,
          temp_min: 16.99,
          temp_max: 16.99,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 914,
          humidity: 71,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 13,
        },
        wind: {
          speed: 4.16,
          deg: 102,
          gust: 7.87,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 18:00:00",
      },
      {
        dt: 1703365200,
        main: {
          temp: 15.33,
          feels_like: 15.06,
          temp_min: 15.33,
          temp_max: 15.33,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 82,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 3.17,
          deg: 89,
          gust: 5.52,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 21:00:00",
      },
      {
        dt: 1703376000,
        main: {
          temp: 14.31,
          feels_like: 14.09,
          temp_min: 14.31,
          temp_max: 14.31,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 913,
          humidity: 88,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 59,
        },
        wind: {
          speed: 2.99,
          deg: 95,
          gust: 4.57,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 00:00:00",
      },
      {
        dt: 1703386800,
        main: {
          temp: 17.99,
          feels_like: 17.44,
          temp_min: 17.99,
          temp_max: 17.99,
          pressure: 1019,
          sea_level: 1019,
          grnd_level: 916,
          humidity: 61,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 20,
        },
        wind: {
          speed: 3.27,
          deg: 88,
          gust: 5.01,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 03:00:00",
      },
      {
        dt: 1703397600,
        main: {
          temp: 24.61,
          feels_like: 23.86,
          temp_min: 24.61,
          temp_max: 24.61,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 917,
          humidity: 28,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 18,
        },
        wind: {
          speed: 4.59,
          deg: 85,
          gust: 5.48,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 06:00:00",
      },
      {
        dt: 1703408400,
        main: {
          temp: 26.47,
          feels_like: 26.47,
          temp_min: 26.47,
          temp_max: 26.47,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 914,
          humidity: 27,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 12,
        },
        wind: {
          speed: 4.92,
          deg: 89,
          gust: 5.27,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 09:00:00",
      },
      {
        dt: 1703419200,
        main: {
          temp: 22.9,
          feels_like: 22.39,
          temp_min: 22.9,
          temp_max: 22.9,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 914,
          humidity: 44,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02d",
          },
        ],
        clouds: {
          all: 24,
        },
        wind: {
          speed: 4.2,
          deg: 94,
          gust: 6.36,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 12:00:00",
      },
      {
        dt: 1703430000,
        main: {
          temp: 19.04,
          feels_like: 18.64,
          temp_min: 19.04,
          temp_max: 19.04,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 915,
          humidity: 63,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 7,
        },
        wind: {
          speed: 3.77,
          deg: 93,
          gust: 6.84,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 15:00:00",
      },
      {
        dt: 1703440800,
        main: {
          temp: 17.22,
          feels_like: 16.93,
          temp_min: 17.22,
          temp_max: 17.22,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 914,
          humidity: 74,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 6,
        },
        wind: {
          speed: 3.35,
          deg: 84,
          gust: 6.07,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 18:00:00",
      },
      {
        dt: 1703451600,
        main: {
          temp: 16.09,
          feels_like: 15.9,
          temp_min: 16.09,
          temp_max: 16.09,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 82,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 9,
        },
        wind: {
          speed: 3.15,
          deg: 86,
          gust: 5.93,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 21:00:00",
      },
      {
        dt: 1703462400,
        main: {
          temp: 15.37,
          feels_like: 15.1,
          temp_min: 15.37,
          temp_max: 15.37,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 913,
          humidity: 82,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01n",
          },
        ],
        clouds: {
          all: 10,
        },
        wind: {
          speed: 3.16,
          deg: 86,
          gust: 5.52,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 00:00:00",
      },
      {
        dt: 1703473200,
        main: {
          temp: 19.11,
          feels_like: 18.56,
          temp_min: 19.11,
          temp_max: 19.11,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 915,
          humidity: 57,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 7,
        },
        wind: {
          speed: 3.81,
          deg: 91,
          gust: 6.1,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 03:00:00",
      },
      {
        dt: 1703484000,
        main: {
          temp: 24.99,
          feels_like: 24.82,
          temp_min: 24.99,
          temp_max: 24.99,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 916,
          humidity: 49,
          temp_kf: 0,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        clouds: {
          all: 5,
        },
        wind: {
          speed: 4.43,
          deg: 94,
          gust: 5.16,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 06:00:00",
      },
    ],
    city: {
      id: 1277333,
      name: "Bengaluru",
      coord: {
        lat: 12.9762,
        lon: 77.6033,
      },
      country: "IN",
      population: 5104047,
      timezone: 19800,
      sunrise: 1703034363,
      sunset: 1703075261,
    },
  },
  {
    cod: "200",
    message: 0,
    cnt: 40,
    list: [
      {
        dt: 1703062800,
        main: {
          temp: 28.16,
          feels_like: 30.7,
          temp_min: 26.49,
          temp_max: 28.16,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1011,
          humidity: 68,
          temp_kf: 1.67,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 55,
        },
        wind: {
          speed: 7.24,
          deg: 26,
          gust: 7.84,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 09:00:00",
      },
      {
        dt: 1703073600,
        main: {
          temp: 26.78,
          feels_like: 28.64,
          temp_min: 25.68,
          temp_max: 26.78,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 72,
          temp_kf: 1.1,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 57,
        },
        wind: {
          speed: 6.55,
          deg: 30,
          gust: 8.11,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-20 12:00:00",
      },
      {
        dt: 1703084400,
        main: {
          temp: 25.14,
          feels_like: 25.62,
          temp_min: 25.14,
          temp_max: 25.14,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1013,
          humidity: 73,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 20,
        },
        wind: {
          speed: 5.93,
          deg: 35,
          gust: 7.77,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 15:00:00",
      },
      {
        dt: 1703095200,
        main: {
          temp: 24.75,
          feels_like: 25.24,
          temp_min: 24.75,
          temp_max: 24.75,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1013,
          humidity: 75,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 20,
        },
        wind: {
          speed: 5.6,
          deg: 37,
          gust: 7.11,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 18:00:00",
      },
      {
        dt: 1703106000,
        main: {
          temp: 24.79,
          feels_like: 25.23,
          temp_min: 24.79,
          temp_max: 24.79,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 73,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 87,
        },
        wind: {
          speed: 5.25,
          deg: 31,
          gust: 6.62,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-20 21:00:00",
      },
      {
        dt: 1703116800,
        main: {
          temp: 25.16,
          feels_like: 25.48,
          temp_min: 25.16,
          temp_max: 25.16,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 67,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 93,
        },
        wind: {
          speed: 5.07,
          deg: 30,
          gust: 6.23,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 00:00:00",
      },
      {
        dt: 1703127600,
        main: {
          temp: 25.35,
          feels_like: 25.77,
          temp_min: 25.35,
          temp_max: 25.35,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 70,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 85,
        },
        wind: {
          speed: 4.93,
          deg: 16,
          gust: 5.69,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 03:00:00",
      },
      {
        dt: 1703138400,
        main: {
          temp: 26.13,
          feels_like: 26.13,
          temp_min: 26.13,
          temp_max: 26.13,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 65,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 90,
        },
        wind: {
          speed: 5.27,
          deg: 31,
          gust: 5.73,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 06:00:00",
      },
      {
        dt: 1703149200,
        main: {
          temp: 26.64,
          feels_like: 26.64,
          temp_min: 26.64,
          temp_max: 26.64,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1011,
          humidity: 63,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 98,
        },
        wind: {
          speed: 5.02,
          deg: 43,
          gust: 5.11,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 09:00:00",
      },
      {
        dt: 1703160000,
        main: {
          temp: 25.29,
          feels_like: 25.65,
          temp_min: 25.29,
          temp_max: 25.29,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 68,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 99,
        },
        wind: {
          speed: 5.22,
          deg: 40,
          gust: 6.07,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-21 12:00:00",
      },
      {
        dt: 1703170800,
        main: {
          temp: 24.71,
          feels_like: 25.06,
          temp_min: 24.71,
          temp_max: 24.71,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 70,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 83,
        },
        wind: {
          speed: 4.77,
          deg: 39,
          gust: 5.95,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 15:00:00",
      },
      {
        dt: 1703181600,
        main: {
          temp: 24.37,
          feels_like: 24.72,
          temp_min: 24.37,
          temp_max: 24.37,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 71,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 81,
        },
        wind: {
          speed: 4.22,
          deg: 35,
          gust: 5.38,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 18:00:00",
      },
      {
        dt: 1703192400,
        main: {
          temp: 23.89,
          feels_like: 24.24,
          temp_min: 23.89,
          temp_max: 23.89,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1012,
          humidity: 73,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 62,
        },
        wind: {
          speed: 3.85,
          deg: 27,
          gust: 4.85,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-21 21:00:00",
      },
      {
        dt: 1703203200,
        main: {
          temp: 23.61,
          feels_like: 23.91,
          temp_min: 23.61,
          temp_max: 23.61,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 72,
        },
        wind: {
          speed: 2.92,
          deg: 23,
          gust: 3.92,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 00:00:00",
      },
      {
        dt: 1703214000,
        main: {
          temp: 24.84,
          feels_like: 25.21,
          temp_min: 24.84,
          temp_max: 24.84,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 70,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 35,
        },
        wind: {
          speed: 3.6,
          deg: 33,
          gust: 4.45,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 03:00:00",
      },
      {
        dt: 1703224800,
        main: {
          temp: 26.31,
          feels_like: 26.31,
          temp_min: 26.31,
          temp_max: 26.31,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 64,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 56,
        },
        wind: {
          speed: 4.64,
          deg: 39,
          gust: 4.44,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 06:00:00",
      },
      {
        dt: 1703235600,
        main: {
          temp: 26.19,
          feels_like: 26.19,
          temp_min: 26.19,
          temp_max: 26.19,
          pressure: 1012,
          sea_level: 1012,
          grnd_level: 1011,
          humidity: 63,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 5.02,
          deg: 39,
          gust: 4.71,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 09:00:00",
      },
      {
        dt: 1703246400,
        main: {
          temp: 24.8,
          feels_like: 25.11,
          temp_min: 24.8,
          temp_max: 24.8,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1012,
          humidity: 68,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 4.93,
          deg: 29,
          gust: 5.85,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-22 12:00:00",
      },
      {
        dt: 1703257200,
        main: {
          temp: 24.41,
          feels_like: 24.73,
          temp_min: 24.41,
          temp_max: 24.41,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 70,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 5.04,
          deg: 32,
          gust: 6.41,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 15:00:00",
      },
      {
        dt: 1703268000,
        main: {
          temp: 24.03,
          feels_like: 24.34,
          temp_min: 24.03,
          temp_max: 24.03,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 71,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 4.17,
          deg: 44,
          gust: 5.06,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 18:00:00",
      },
      {
        dt: 1703278800,
        main: {
          temp: 23.57,
          feels_like: 23.86,
          temp_min: 23.57,
          temp_max: 23.57,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 89,
        },
        wind: {
          speed: 4,
          deg: 27,
          gust: 5.15,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-22 21:00:00",
      },
      {
        dt: 1703289600,
        main: {
          temp: 23.21,
          feels_like: 23.49,
          temp_min: 23.21,
          temp_max: 23.21,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1013,
          humidity: 73,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 95,
        },
        wind: {
          speed: 3.74,
          deg: 16,
          gust: 5.05,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 00:00:00",
      },
      {
        dt: 1703300400,
        main: {
          temp: 24.74,
          feels_like: 25.05,
          temp_min: 24.74,
          temp_max: 24.74,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1015,
          humidity: 68,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 78,
        },
        wind: {
          speed: 3.61,
          deg: 31,
          gust: 4.75,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 03:00:00",
      },
      {
        dt: 1703311200,
        main: {
          temp: 26.54,
          feels_like: 26.54,
          temp_min: 26.54,
          temp_max: 26.54,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 60,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 80,
        },
        wind: {
          speed: 4.69,
          deg: 43,
          gust: 4.51,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 06:00:00",
      },
      {
        dt: 1703322000,
        main: {
          temp: 26.48,
          feels_like: 26.48,
          temp_min: 26.48,
          temp_max: 26.48,
          pressure: 1013,
          sea_level: 1013,
          grnd_level: 1011,
          humidity: 59,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 62,
        },
        wind: {
          speed: 5.73,
          deg: 41,
          gust: 5.23,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 09:00:00",
      },
      {
        dt: 1703332800,
        main: {
          temp: 25.03,
          feels_like: 25.34,
          temp_min: 25.03,
          temp_max: 25.03,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1012,
          humidity: 67,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 52,
        },
        wind: {
          speed: 5.51,
          deg: 27,
          gust: 6.51,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-23 12:00:00",
      },
      {
        dt: 1703343600,
        main: {
          temp: 24.29,
          feels_like: 24.58,
          temp_min: 24.29,
          temp_max: 24.29,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 69,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 47,
        },
        wind: {
          speed: 5.12,
          deg: 33,
          gust: 6.48,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 15:00:00",
      },
      {
        dt: 1703354400,
        main: {
          temp: 23.91,
          feels_like: 24.21,
          temp_min: 23.91,
          temp_max: 23.91,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 71,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03n",
          },
        ],
        clouds: {
          all: 47,
        },
        wind: {
          speed: 4.6,
          deg: 29,
          gust: 5.99,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 18:00:00",
      },
      {
        dt: 1703365200,
        main: {
          temp: 23.52,
          feels_like: 23.78,
          temp_min: 23.52,
          temp_max: 23.52,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 71,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 13,
        },
        wind: {
          speed: 4.16,
          deg: 31,
          gust: 5.37,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-23 21:00:00",
      },
      {
        dt: 1703376000,
        main: {
          temp: 23.03,
          feels_like: 23.32,
          temp_min: 23.03,
          temp_max: 23.03,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1014,
          humidity: 74,
          temp_kf: 0,
        },
        weather: [
          {
            id: 801,
            main: "Clouds",
            description: "few clouds",
            icon: "02n",
          },
        ],
        clouds: {
          all: 18,
        },
        wind: {
          speed: 3.63,
          deg: 25,
          gust: 4.83,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 00:00:00",
      },
      {
        dt: 1703386800,
        main: {
          temp: 24.57,
          feels_like: 24.88,
          temp_min: 24.57,
          temp_max: 24.57,
          pressure: 1018,
          sea_level: 1018,
          grnd_level: 1017,
          humidity: 69,
          temp_kf: 0,
        },
        weather: [
          {
            id: 802,
            main: "Clouds",
            description: "scattered clouds",
            icon: "03d",
          },
        ],
        clouds: {
          all: 36,
        },
        wind: {
          speed: 3.93,
          deg: 14,
          gust: 5.04,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 03:00:00",
      },
      {
        dt: 1703397600,
        main: {
          temp: 26.52,
          feels_like: 26.52,
          temp_min: 26.52,
          temp_max: 26.52,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1016,
          humidity: 62,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 61,
        },
        wind: {
          speed: 5.92,
          deg: 32,
          gust: 5.93,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 06:00:00",
      },
      {
        dt: 1703408400,
        main: {
          temp: 26.56,
          feels_like: 26.56,
          temp_min: 26.56,
          temp_max: 26.56,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 61,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 85,
        },
        wind: {
          speed: 6.28,
          deg: 28,
          gust: 6.12,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 09:00:00",
      },
      {
        dt: 1703419200,
        main: {
          temp: 25.1,
          feels_like: 25.47,
          temp_min: 25.1,
          temp_max: 25.1,
          pressure: 1015,
          sea_level: 1015,
          grnd_level: 1013,
          humidity: 69,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 90,
        },
        wind: {
          speed: 5.27,
          deg: 30,
          gust: 6.21,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-24 12:00:00",
      },
      {
        dt: 1703430000,
        main: {
          temp: 24.57,
          feels_like: 24.99,
          temp_min: 24.57,
          temp_max: 24.57,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1015,
          humidity: 73,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 92,
        },
        wind: {
          speed: 5.43,
          deg: 34,
          gust: 7,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 15:00:00",
      },
      {
        dt: 1703440800,
        main: {
          temp: 24.25,
          feels_like: 24.74,
          temp_min: 24.25,
          temp_max: 24.25,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1015,
          humidity: 77,
          temp_kf: 0,
        },
        weather: [
          {
            id: 803,
            main: "Clouds",
            description: "broken clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 68,
        },
        wind: {
          speed: 5,
          deg: 27,
          gust: 6.72,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 18:00:00",
      },
      {
        dt: 1703451600,
        main: {
          temp: 24.16,
          feels_like: 24.67,
          temp_min: 24.16,
          temp_max: 24.16,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 78,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 96,
        },
        wind: {
          speed: 4.58,
          deg: 13,
          gust: 6.26,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-24 21:00:00",
      },
      {
        dt: 1703462400,
        main: {
          temp: 23.66,
          feels_like: 24.2,
          temp_min: 23.66,
          temp_max: 23.66,
          pressure: 1014,
          sea_level: 1014,
          grnd_level: 1013,
          humidity: 81,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04n",
          },
        ],
        clouds: {
          all: 98,
        },
        wind: {
          speed: 5.23,
          deg: 358,
          gust: 6.92,
        },
        visibility: 10000,
        pop: 0,
        sys: {
          pod: "n",
        },
        dt_txt: "2023-12-25 00:00:00",
      },
      {
        dt: 1703473200,
        main: {
          temp: 23.79,
          feels_like: 24.34,
          temp_min: 23.79,
          temp_max: 23.79,
          pressure: 1017,
          sea_level: 1017,
          grnd_level: 1015,
          humidity: 81,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 100,
        },
        wind: {
          speed: 6.15,
          deg: 351,
          gust: 7.83,
        },
        visibility: 10000,
        pop: 0.03,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 03:00:00",
      },
      {
        dt: 1703484000,
        main: {
          temp: 26.35,
          feels_like: 26.35,
          temp_min: 26.35,
          temp_max: 26.35,
          pressure: 1016,
          sea_level: 1016,
          grnd_level: 1014,
          humidity: 72,
          temp_kf: 0,
        },
        weather: [
          {
            id: 804,
            main: "Clouds",
            description: "overcast clouds",
            icon: "04d",
          },
        ],
        clouds: {
          all: 89,
        },
        wind: {
          speed: 7.41,
          deg: 2,
          gust: 7.93,
        },
        visibility: 10000,
        pop: 0.03,
        sys: {
          pod: "d",
        },
        dt_txt: "2023-12-25 06:00:00",
      },
    ],
    city: {
      id: 1264527,
      name: "Chennai",
      coord: {
        lat: 13.0878,
        lon: 80.2785,
      },
      country: "IN",
      population: 4328063,
      timezone: 19800,
      sunrise: 1703033733,
      sunset: 1703074606,
    },
  },
];
