import React from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CListGroup,
  CListGroupItem,
  CAvatar,
} from "@coreui/react";
import "../styles/chat.css";

const ChatConversation = (props) => {
  // console.log(props.data);
  return (
    <CListGroup >
      {props.data.map((message, index) => (
        <div key={index}>
          {message.Agent && (
            <div className="d-flex justify-content-start position-relative gap-1 align-items-start mb-1">
              {" "}
              <div
                className={`chat-message other mt-1 align-items-center d-flex gap-1 justify-content-end font-md`}
                style={{
                  maxWidth: "85%",
                }}
              >
                {message.Agent}
              </div>
            </div>
          )}

          {message.Customer && (
            <div className="d-flex justify-content-end position-relative gap-1 align-items-start mb-1">
              <div
                className={`chat-message user mt-1 align-items-center d-flex gap-1 justify-content-end font-md`}
                style={{
                  maxWidth: "85%",
                }}
              >
                {message.Customer}
              </div>
            </div>
          )}
        </div>
      ))}
    </CListGroup>
  );
};

export default ChatConversation;
