import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CFormTextarea,
  CImage,
  CFormLabel,
  CRow,
  CAlert,
  CCard,
  CCardText,
  CHeaderDivider,
  CAvatar,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import {
  cilPlus,
  cilSend,
  cilCommentSquare,
  cilX,
  cilAccountLogout,
} from "@coreui/icons";
import { BsLink45Deg } from "react-icons/bs";
import aiAvatarImage from "../assets/images/OIG.png";

import { useNavigate } from "react-router-dom";
import UrlSidebar from "../components/UrlSidebar";
import { ChatMessage } from "../components/ChatMessage";
import axios from "axios";
import CommonSidebar from "../components/CommonSidebar";
import { IoIosChatbubbles, IoMdCloudDone } from "react-icons/io";
import { FcDataSheet, FcSearch, FcUpload } from "react-icons/fc";
import aiImage from "../assets/images/ai_image.png";
import UploadPDF from "./UploadPdf";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import pdfIcon from "../assets/images/pdf_icon.png";
import "react-loading-skeleton/dist/skeleton.css";
import arcDiag from "../assets/images/unstructure_arch_dia.png";
import CarouselCitations from "../components/CarouselCitations";
import Citations from "../components/Citations";

const UrlPrompt = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const [promptList, setPromptList] = useState([]);
  const [indexed, setIndexed] = useState(false);
  const messageContainerRef = useRef(null);
  const [urlGeneratedData, setUrlGeneratedData] = useState([]);
  const [blobName, setBlobName] = useState();
  const [visible, setVisible] = useState(true);

  const [urlList, setUrlList] = useState([]);
  const [urlVal, setUrlVal] = useState([]);
  const [urlError, setUrlError] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [pdfDocList, setPdfDocList] = useState([]);
  const [pdfDocNameList, setPdfDocNameList] = useState([]);
  // useState([{
  //   question:"Tell about website",
  //   answer:'The website is about web development. It provides tutorials, exercises, quizzes, and certifications for HTML, CSS, Bootstrap, Data Analytics, and more.'
  // }]);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };
  const handleDelete = async () => {
    let data = JSON.stringify({
      is_delete: true,
      project: "generativeai-coe",
      location: "us-central1",
      blob_name: blobName,
    });
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/get-answer",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
      }
    } catch (err) {
      console.log("Response Error", err);
    }
  };
  const handleSubmit = async (question) => {
    // console.log("question", question)
    if (question.trim() == "") {
      return;
    }
    setUrlGeneratedData([
      ...urlGeneratedData,
      {
        question: question,
        answer: "",
      },
    ]);
    setLoading(true);
    let data = JSON.stringify({
      question: question,
      // project: "generativeai-coe",
      // location: "us-central1",
      // blob_name: blobName,
    });
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/Ask-questions-itc",
      // url: "https://us-central1-generativeai-coe.cloudfunctions.net/get-answer",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      // console.log("Config", JSON.stringify(config));
      let response = await axios(config);
      // let response = tempResp;
      console.log("response", response);
      if (response != undefined) {
        // setGeneratedData([
        //   ...generatedData,
        //   response.data.result,
        // ]);
        let tempResults = [];
        if (response.data?.Results_britannia) {
          tempResults = [...tempResults, ...response.data.Results_britannia];
        }

        if (response.data?.Results_dabur) {
          tempResults = [...tempResults, ...response.data.Results_dabur];
        }
        if (response.data?.Results_itc) {
          tempResults = [...tempResults, ...response.data.Results_itc];
        }
        if (response.data?.Results_parle) {
          tempResults = [...tempResults, ...response.data.Results_parle];
        }

        setLoading(false);
        setUrlGeneratedData([
          ...urlGeneratedData,
          {
            question: question,
            answer: response.data.combined_summary,
            results: tempResults,
          },
        ]);
      }
    } catch (err) {
      console.log("Response Error", err);
      setLoading(false);
      setUrlGeneratedData([
        ...urlGeneratedData,
        {
          question: question,
          answer: "Answer not found",
        },
      ]);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [urlGeneratedData]);

  const handleUrlData = (name) => {
    setBlobName(name);
  };
  const PdfUpload = () => {
    const handleFileSelect = (files) => {
      // perform additional actions with the selected file, like uploading it to a server.
      if (files.length > 0) {
        for (const fl of files) {
          if (!pdfDocNameList.includes(fl.name)) {
            setPdfDocNameList([...pdfDocNameList, fl.name]);
            const reader = new FileReader();
            reader.onload = () => {
              const base64Str = reader.result;
              const splitBase64Strings = base64Str.split(";");
              const splitBase64Str = splitBase64Strings[1].split(",");
              setPdfDocList([...pdfDocList, splitBase64Str[1]]);
            };
            reader.readAsDataURL(fl);
          }
        }
      }
    };

    const handleSubmit = async () => {
      setLoading1(true);
      let data = JSON.stringify({
        url: urlList,
        project: "generativeai-coe",
        location: "us-central1",
        pdfs: pdfDocList,
      });
      let config = {
        method: "post",
        url: "https://us-central1-generativeai-coe.cloudfunctions.net/send-url",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        data,
      };
      try {
        // console.log("Config", JSON.stringify(config));
        let response = await axios(config);
        if (response != undefined) {
          if (response.data.message == "Embeddings Completed") {
            const blob_name = response.data.blob_name;
            setLoading1(false);
            setIndexed(true);
            handleUrlData(blob_name);
          }
        }
      } catch (err) {
        console.log("Response Error", err);
        setLoading1(false);
      }
    };
    return (
      <>
        <CRow className="d-flex align-items-center justify-content-center">
          <CCol sm={10} style={{ paddingRight: 0 }} className="ps-4 mt-1">
            <CForm
              className="d-flex align-items-center w-100 inputfocus m-auto"
              style={{
                borderRadius: "5px",
                height: "30px",
                backgroundColor: "white",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!urlList.includes(urlVal)) {
                  setUrlList([...urlList, urlVal]);
                  setUrlVal("");
                } else {
                  setUrlError(true);
                }
              }}
            >
              <CFormInput
                type="url"
                pattern="https://.*"
                className="border-0 inputfocus form-control position-relative"
                style={{
                  backgroundColor: "white",
                  boxShadow: "none",
                  color: "#086EB5",
                  height: "28px",
                  resize: "none",
                  fontSize: "12px",
                }}
                disabled={indexed}
                onChange={(e) => {
                  setUrlVal(e.target.value);
                  setUrlError(false);
                }}
                value={urlVal}
                placeholder="Enter URL here"
              />
              <CButton
                type="submit"
                disabled={urlVal == ""}
                // disabled={urlVal == "" || urlList.length >= 3}
                variant="ghost"
                className="p-0 border-0 hover"
              >
                {/* <BsLink45Deg
              className="hover m-2"
              style={{ color: "#086EB5", height: "20px", width: "20px" }}
            /> */}
                {/* <CImage src={pdfIcon} width={30} height={30}></CImage> */}
                <CIcon
                  icon={cilPlus}
                  className="hover m-2"
                  style={{ color: "green", height: "18px", width: "18px" }}
                ></CIcon>
              </CButton>
            </CForm>
          </CCol>
          <CCol className="p-0 m-0 ps-1">
            <UploadPDF
              onFileSelect={handleFileSelect}
              indexed={indexed}
            ></UploadPDF>
          </CCol>
        </CRow>
        <CCardText
          className="text-medium-emphasis font-md p-0 m-0 ms-2 ps-1"
          style={{ fontSize: "10px" }}
        >
          Max file size 5MB
        </CCardText>
        {urlError ? (
          <span
            className="m-0 position-absolute mb-2"
            style={{
              color: "red",
              fontSize: "10px",
            }}
          >
            URL already Exists
          </span>
        ) : null}
        <CCol>
          {urlList?.map((el, i) => {
            return (
              <CCol
                key={i}
                style={{ textOverflow: "ellipsis", color: "#086eb5" }}
                className="py-1 mt-1 hover"
              >
                <marquee scrollDelay={300}>
                  <BsLink45Deg className="hover" style={{ color: "#086EB5" }} />
                  {el}
                </marquee>
              </CCol>
            );
          })}
          {urlList.length > 0 ? (
            <CHeaderDivider className="border my-2" />
          ) : null}
          {pdfDocNameList?.map((el, i) => {
            return (
              <CRow>
                <CCol className="my-1" sm={1}>
                  <CImage src={pdfIcon} width={15} height={16}></CImage>
                </CCol>
                <CCol
                  sm={10}
                  key={i}
                  style={{ textOverflow: "ellipsis", color: "#086EB5" }}
                  className="py-1 hover"
                >
                  {el}
                </CCol>
              </CRow>
            );
          })}
        </CCol>
        <div className="mt-1">
          {urlList.length > 0 || pdfDocList.length > 0 ? (
            <CButton
              variant="outline"
              shape="rounded-pill"
              className="w-100 logout-button mb-2 d-flex align-items-center justify-content-center gap-2"
              style={{ fontSize: "12px" }}
              onClick={() => {
                setUrlList([]);
                setIndexed(false);
                setGeneratedData([]);
                setPromptList([]);
                setPdfDocList([]);
                setPdfDocNameList([]);
                handleDelete();
              }}
            >
              <CIcon icon={cilX} size={"sm"} /> Clear Data
            </CButton>
          ) : null}

          {(urlList.length !== 0 || pdfDocNameList.length !== 0) && (
            <CButton
              className="generate-button w-100 d-flex align-items-center justify-content-center gap-2"
              variant="outline"
              shape="rounded-pill"
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleSubmit();
              }}
              hidden={
                urlList.length == 0 && pdfDocNameList.length == 0
                  ? true
                  : indexed
                  ? true
                  : false
              }
            >
              {/* <CIcon icon={cilPlus} style={{ marginRight: "10px" }} /> */}
              Index Data
              {loading1 ? <CSpinner size="sm" className="mx-1" /> : null}
            </CButton>
          )}
          <CAlert
            visible={indexed}
            color="success"
            className="p-1 d-flex justify-content-center align-items-center gap-1 mb-0"
          >
            <IoMdCloudDone
              color="green"
              style={{ height: "20px", width: "20px" }}
            />
            Data Indexed
          </CAlert>
        </div>
      </>
    );
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      {/* Sidebar */}
      {/* <UrlSidebar
        setIndexed={setIndexed}
        indexed={indexed}
        setPromptList={setPromptList}
        setGeneratedData={setGeneratedData}
        handleUrlData={handleUrlData}
        handleDelete={handleDelete}
      /> */}

      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={urlGeneratedData}
            setGeneratedData={setUrlGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"Unstructured Data Q&A"}
            // CustomComponent={<PdfUpload />}
            // CComponentTitle={"PDF/URLs Upload"}
            askQuestion={true}
            // ArcDia={arcDiag}
            activetab={true}
          />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container" ref={messageContainerRef}>
            {urlGeneratedData.length == 0 ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  {/* <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcUpload size={"1.5rem"} /> Upload PDF/URLs
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Please provide the PDF Documents or website URL/URLs in
                      order to address the question adequately.
                    </div>
                  </CCard> */}
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcDataSheet size={"1.5rem"} /> Index Data
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Generate information from the PDF Documents.
                      {/* Generate information from the submitted PDF Documents or
                    URLs regarding the website. */}
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 left-to-right"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcSearch size={"1.5rem"} /> Ask Questions{" "}
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask questions to get the insights from the pdf data.
                      {/* Ask questions to get the insights from the website data. */}
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {urlGeneratedData?.map((data, index) => {
              return (
                <div>
                  {/* {data.question} */}

                  <ChatMessage
                    key={index}
                    text={data.question}
                    isUser={true}
                    isError={true}
                  />
                  {data.answer.length == 0 ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        {}
                      </CAvatar>
                      <CCol
                        className={` m-0 mt-1 align-items-center `}
                        style={{
                          maxWidth: "91%",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#d4e8ffb8"
                          highlightColor="#73a9ff"
                        >
                          <h4>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </CCol>
                    </div>
                  ) : (
                    // <ChatMessage
                    //   key={index}
                    //   text={data.answer}
                    //   isUser={false}
                    //   isError={false}
                    // />
                    <div>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                          className="font-md mt-2 border avatar"
                          size="sm"
                          src={aiAvatarImage}
                        >
                          {}
                        </CAvatar>
                        <div
                          className={`chat-message other mt-2 align-items-center font-md ms-0`}
                          style={{ maxWidth: "85%" }}
                        >
                          {data.answer.split("\n").map((str) => {
                            return <p className="p-0 m-0">{str}</p>;
                          })}
                        </div>
                      </div>
                      {/* <div
                        className={`chat-message other mt-2 align-items-center font-md `}
                        style={{ maxWidth: "85%", marginLeft: "53px" }}
                      >
                        <Citations el={data} />
                      </div>{" "} */}
                      <div
                        className={`chat-message other mt-2 align-items-center font-md `}
                        style={{ maxWidth: "85%", marginLeft: "53px" }}
                      >
                        <CarouselCitations el={data} />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => {
              e.preventDefault();

              setPromptList([...promptList, e.target[0].value]);
              setPrompt("");
              handleSubmit(e.target[0].value);
            }}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setUrlGeneratedData([]);
                setPromptList([]);
                setPrompt("");
                handleDelete();
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  disabled={loading}
                  // disabled={!indexed}
                  onChange={(e) => {
                    e.preventDefault();
                    setPrompt(e.target.value);
                  }}
                  value={prompt}
                  placeholder={"Ask a question"}
                />
                <CButton
                  type="submit"
                  disabled={prompt == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
        </CCol>
      </CRow>

      {/* <CForm
        className="pt-3"
        style={{
          bottom: 0,
          position: "fixed",
          width: "90%",
          background: "rgba(255,255,255,0.8)",
          paddingBottom: "20px",
          zIndex: 11,
          left: 180,
        }}
        onSubmit={(e) => {
          e.preventDefault();

          setPromptList([...promptList, e.target[0].value]);
          setPrompt("");
          handleSubmit(e.target[0].value);
        }}
      >
        <CCol
          className="d-flex align-items-center w-75 inputfocus m-auto p-2"
          style={{
            borderRadius: "5px",
            height: "35px",
            backgroundColor: "#EEF4F8",
            position: "relative",
          }}
        >
          <CFormInput
            className="border-0 inputfocus form-control"
            style={{
              backgroundColor: "#EEF4F8",
              boxShadow: "none",
              color: "#086EB5",
              height: "30px",
              resize: "none",
            }}
            disabled={!indexed}
            onChange={(e) => setPrompt(e.target.value)}
            value={prompt}
            placeholder="Ask me something"
          />
          <CButton
            type="submit"
            disabled={prompt == "" || loading}
            variant="ghost"
            className="p-0 border-0 hover"
          >
            {loading ? (
              <CSpinner
                size="sm"
                className="m-2"
                style={{ color: "#086EB5", height: "20px", width: "20px" }}
              />
            ) : (
              <CIcon
                icon={cilSend}
                className="m-2 hover"
                size="lg"
                style={{ color: "#086EB5" }}
              />
            )}
          </CButton>
          <div style={{ position: "absolute", right: -60 }}>
            <CButton
              size="sm"
              color="primary"
              onClick={() => {
                setUrlGeneratedData([]);
                setPromptList([]);
                setPrompt("");
                handleDelete();
              }}
            >
              Clear
            </CButton>
          </div>
        </CCol>
      </CForm> */}
    </div>
  );
};

export default UrlPrompt;
