import React, { useEffect, useState } from "react";
import "../styles/SliderComponent.css";
import { CCardFooter, CSpinner } from "@coreui/react";

const SliderComponent = (props) => {
  const [sliderValue, setSliderValue] = useState(50);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };
  useEffect(() => {
    console.log(props.type);
    if (props.type == "Neutral") {
      setSliderValue(50);
    } else if (props.type == "Negative") {
      setSliderValue(0);
    } else if (props.type == "Positive") {
      setSliderValue(100);
    }
  }, [props.data]);
  return (
    <div
    //  className="slider-container"
    >
      {/* <input
        type="range"
        className="slider"
        id="customRange1"
        value={sliderValue}
        // onChange={handleSliderChange}
        min="0"
        max="100"
        step="1"
      />
      <div className="slider-labels">
        <span>Negative</span>
        <span>Neutral</span>
        <span>Positive</span>
      </div> */}
      {/* <CCardFooter className="bg-transparent px-0 mt-2"> */}
      {/* <medium className="text-medium-emphasis">
          <b>Customor Sentiment:</b>
        </medium> */}
      <label
        htmlFor="customRange1"
        className={` ${
          props.type && props.type.toLowerCase() == "positive"
            ? "text-success"
            : props.type && props.type.toLowerCase() == "negative"
            ? "text-danger"
            : "text-dark"
        }`}
      >
        {props.type}{" "}
        {props.type && props.type.toLowerCase() == "positive" ? (
          "😁"
        ) : props.type && props.type.toLowerCase() == "negative" ? (
          "😡"
        ) : props.type && props.type.toLowerCase() == "neutral" ? (
          "🙂"
        ) : (
          <div>
            Identifying Sentiment <CSpinner size="sm" />
          </div>
        )}
      </label>
      {/* </CCardFooter> */}
    </div>
  );
};

export default SliderComponent;
