import React, { useState, useEffect } from "react";
import { CAvatar, CButton, CCol, CImage } from "@coreui/react";
import {
  FaThumbsUp,
  FaRegThumbsUp,
  FaThumbsDown,
  FaRegThumbsDown,
} from "react-icons/fa";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ChatMessage } from "../components/ChatMessage";
import PDTabs from "./PDTabs";
import OIG from "../assets/images/OIG.png";
const JSWChat = ({
  el,
  i,
  UUID,
  setFeedbackFlag,
  setConversationHistory,
  conversationHistory,
}) => {
  const [liked, setLiked] = useState(
    el.feedback != undefined ? (el.feedback == 1 ? true : false) : false
  );
  const [disLiked, setDisLiked] = useState(
    el.feedback != undefined ? (el.feedback == 1 ? false : true) : false
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (el.feedback != undefined) {
      if (el.feedback == 1) {
        setLiked(true);
        setDisLiked(false);
      } else {
        setLiked(false);
        setDisLiked(true);
      }
    }
  }, [el.feedback]);
  const sendFeedback = async (feedback, time) => {
    setFeedbackFlag(false);
    let tempTime = new Date(time);
    setLoading(true);
    if (feedback == 0) {
      setLiked(false);
    } else {
      setDisLiked(false);
    }
    let username = JSON.parse(sessionStorage.getItem("authentication"));
    // let config = {
    //   method: "post",
    //   url: "https://al-genai-api-v1-gateway-a8d67j5q.uc.gateway.dev/feedback",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-API-Key": "AIzaSyC9s3SJxJNLgWdRotkB52UTHzEsfuU3mWo",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   data: {
    //     username: username,
    //     question: el.question,
    //     summary: el.summary,
    //     feedback: feedback,
    //     UUID: UUID,
    //     img_search_data: el.img_search_data || "",
    //     results: el.results || "",
    //     tuning_data: el.tuning_data || "",
    //   },
    // };

    let data = sessionStorage.getItem("conversationHistory");
    if (data) {
      let tempData = JSON.parse(data);
      const indexof = tempData["conversion"].findIndex((item) => {
        return item.UUID === UUID && item.created_at == tempTime.toISOString();
      });

      let tempFeedbackdata = tempData["conversion"][indexof];
      tempFeedbackdata.feedback = feedback;
      let tempConversion = [...tempData["conversion"]];

      let conversionData = { conversion: tempConversion };
      sessionStorage.setItem(
        "conversationHistory",
        JSON.stringify(conversionData)
      );

      setLoading(false);
    } else {
      let conversionData = { conversion: [] };
      sessionStorage.setItem(
        "conversationHistory",
        JSON.stringify(conversionData)
      );
      setConversationHistory([]);
      setLoading(false);
    }
    // try {
    //   let response = await axios(config);

    //   if (response != undefined) {
    //     setLoading(false);
    //   }
    // } catch (error) {
    //   console.log("data error", error);
    // }
  };

  return (
    <div style={{ paddingRight: "20px" }} className="mb-4">
      <ChatMessage key={i + 1} text={el.question} isUser={true} />
      <CCol
        xs={{ cols: 2 }}
        className="d-flex align-items-top justify-content-start ms-3 gap-2"
      >
        <CCol style={{ maxWidth: "fit-content" }}>
          <CAvatar
            src={OIG}
            className="avatar-jsw"
            style={{ padding: "2px" }}
          ></CAvatar>
        </CCol>

        {el.summary == "" ? (
          <CCol
            className={`chat-message-jsw other m-0 mt-1 align-items-center `}
            style={{ maxWidth: "91%" }}
          >
            <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
              <h4>
                <Skeleton count={1} width={"50%"} />
              </h4>
              <p>
                <Skeleton height={12} width={"75%"} count={2} />
              </p>
            </SkeletonTheme>
          </CCol>
        ) : el.summary?.toLowerCase().includes("answer not found") &&
          (el.structured.toLowerCase().includes("answer not found") ||
            el.structured.includes(
              "Sorry, I'm Unable to explain the result."
            )) ? (
          <CCol name={i} style={{ maxWidth: "91%", position: "relative" }}>
            <div
              style={{ width: "max-content", maxWidth: "100%" }}
              className={`chat-message error m-0 mt-1 align-items-center`}
            >
              We couldn't find any data in documents matching your request.
              Please try asking in a different way or modify the question.
            </div>
            <div
              style={{
                position: "absolute",
                right: 10,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderRadius: "30px",
              }}
              className="like  d-flex align-items-center justify-content-center px-2"
            >
              <CButton
                className={`hover p-0 like  d-flex align-items-center justify-content-center`}
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  border: "none",
                }}
                color="light"
                variant="ghost"
                onClick={() => {
                  setLiked(true);
                  sendFeedback(1, el.created_at);
                }}
                // disabled={disLiked || liked}
              >
                {liked ? <FaThumbsUp /> : <FaRegThumbsUp />}
              </CButton>

              <CButton
                className={`hover p-0 like  d-flex align-items-center justify-content-center`}
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  border: "none",
                }}
                color="light"
                variant="ghost"
                onClick={() => {
                  setDisLiked(true);
                  sendFeedback(0, el.created_at);
                }}
                disabled={disLiked || liked}
              >
                {disLiked ? <FaThumbsDown /> : <FaRegThumbsDown />}
              </CButton>
            </div>
          </CCol>
        ) : (
          <CCol style={{ maxWidth: "91%" }}>
            <PDTabs
              el={el}
              i={i}
              sendFeedback={sendFeedback}
              setLiked={setLiked}
              setDisLiked={setDisLiked}
              liked={liked}
              disLiked={disLiked}
            />
          </CCol>
        )}
      </CCol>
    </div>
  );
};

export default JSWChat;
