import { CButton } from "@coreui/react";
// import "./styles.css";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

import { useRef } from "react";
import { map } from "bluebird";
import { cilClock, cilMediaPlay } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const VideoPlayer = ({
  keyIndex,
  videoUrl,
  videoTime,
  selectedVideo,
  handleTranscriptOnTime,
}) => {
  // console.log("videoUrl",videoUrl)
  const [selectedVideoTime, setSelectedVideoTime] = useState();
  const [currentPlayer, setCurrentPlayer] = React.useState(null);
  const [videoPlay, setVideoPlay] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef();

  const convertSecToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minuteString = minutes < 10 ? "0" + minutes : minutes.toString();
    const secondString =
      remainingSeconds < 10
        ? "0" + remainingSeconds
        : remainingSeconds.toString();
    return minuteString + ":" + secondString;
  };
  const seekToSeconds = (time, index) => {
    setPlaying(false);
    handleTranscriptOnTime(index);
    playerRef.current.seekTo(time, "seconds");
    setCurrentPlayer(playerRef);
    setActiveIndex(index);
    setSelectedVideoTime(time);
    setPlaying(true);
  };

  const onReady = React.useCallback(() => {
    playerRef.current.seekTo(videoTime, "seconds");
    // setPlaying(true)
  }, []);
  return (
    <div className="d-flex gap-1">
      <ReactPlayer
        //key={index}
        ref={playerRef}
        // url={`${videoUrl}`}
        url={`${videoUrl}#t=${videoTime}s`}
        //url={selectedVideoTime.length == 0 ? `${videoUrl}` : `${videoUrl}#t=${selectedVideoTime}s`}
        // playing={playing}
        controls
        style={{ boxShadow: "rgb(137 127 127) 0px 5px 10px" }}
        // width="40%"
        height="180px"
        // onReady={onReady}
        onSeek={videoTime}
        // onPlay={onPlay}
      />
      {/* <div
                className="d-flex flex-column gap-1 m-2"
                style={{ width: "max-content" }}
            >
               
                <CButton
                    color="success" //"#f9873be6"
                    variant="outline"
                    size="sm"
                    // active={index == activeIndex ? true : false}
                    style={{ cursor: "pointer", height: 25, fontSize: 11 }}
                    onClick={() => seekToSeconds(videoTime, 0)}>
                    <CIcon className="me-1" icon={cilClock} width={11} />
                    {convertSecToMinutes(videoTime)}
                </CButton>
]
            </div> */}
    </div>
  );
};

export default VideoPlayer;
